export class SelectionWeek {
  constructor(
    private _weeks: number[] = [],
    private _indexes: number[] = [],
  ) {}

  get length(): number {
    return this._weeks.length;
  }

  public add(week: number): SelectionWeek {
    this._weeks.push(week);

    const latestIndex = this._indexes[this._indexes.length - 1] ?? 0;
    this._indexes.push(latestIndex + 1);

    return this.clone();
  }

  public remove(week: number): SelectionWeek {
    const index = this._weeks.findIndex((v) => v === week);
    this._weeks = this._weeks.filter((v) => v !== week);
    this._indexes.splice(index, 1);
    this._indexes = this.elementsGreaterThanDecreased(this._indexes, index);

    return this.clone();
  }

  public clean(): SelectionWeek {
    this._weeks = [];
    this._indexes = [];
    return new SelectionWeek(this._weeks, this._indexes);
  }

  public isSelected(week: number): number | undefined {
    const selectedWeekIndex = this._weeks.findIndex((v) => v === week);
    if (selectedWeekIndex === -1) return undefined;
    return this._indexes.at(selectedWeekIndex);
  }

  public clone(): SelectionWeek {
    return new SelectionWeek(this._weeks, this._indexes);
  }

  private elementsGreaterThanDecreased(arr: number[], num: number) {
    return arr.map((element) => (element > num ? element - 1 : element));
  }

  get weeks() {
    return this._weeks;
  }
}
