import { useForm } from 'react-hook-form';
import {
  type ShiftFormValues,
  initialShiftValues,
  shiftValidationSchema,
} from '../forms/shiftForm';
import { type Dayjs } from '../../../utils/dayjs';
import { zodResolver } from '@hookform/resolvers/zod';
import { type IUpdateShift } from '../../../api/types';
import { ShiftService } from '../../../api/shifts';

export default function useShift() {
  const methods = useForm({
    defaultValues: initialShiftValues,
    resolver: zodResolver(shiftValidationSchema),
  });

  const onSubmit = async (shiftDateReference: Dayjs, data: ShiftFormValues) => {
    data.startHour = data.startHour
      .set('year', shiftDateReference.year())
      .set('month', shiftDateReference.month())
      .set('date', shiftDateReference.date());
    data.endHour = data.endHour
      .set('year', shiftDateReference.year())
      .set('month', shiftDateReference.month())
      .set('date', shiftDateReference.date());

    if (
      data.startHour.isAfter(data.endHour) ||
      data.startHour.isSame(data.endHour)
    ) {
      data.endHour = data.endHour.add(1, 'day');
    }

    const input: IUpdateShift = {
      shiftGuid: data.shiftId,
      editAll: data.editAllRelated,
      endDate: data.endHour.toISOString(),
      isActive: true,
      observation: data.observation,
      responsibleUserGuid: data.professional ?? null,
      startDate: data.startHour.toISOString(),
      value: data.value.toString(),
    };

    await ShiftService.update(input);
  };

  return { methods, onSubmit };
}
