/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import Form from '../../../components/form';
import { Autocomplete, Grid, TextField as MuiTextField } from '@mui/material';
import Button from '../../../components/button';
import { type IHospital } from '../../../models/IHospital';
import useAdmin from '../hooks/useAdmin';
import { FormProvider } from 'react-hook-form';
import { AdminFields, type AdminFormValues } from '../forms/adminsForm';
import TextField from '../../../components/TextField';

export interface addAdminData {
  guid?: string;
  adminName?: string;
  adminEmail?: string;
  hospitals?: any[];
  isSuperAdmin?: boolean;
}

interface AddAdminProps {
  data?: addAdminData;
  setIsVisible: (x: boolean) => void;
  onSuccess: () => void;
  isVisble: boolean;
  hospitals: IHospital[];
}

export const AddAdmin = ({
  isVisble,
  setIsVisible,
  onSuccess,
  data,
  hospitals,
}: AddAdminProps) => {
  const [hospitalsSelected, setHospitalsSelected] = useState<any[]>([]);
  const { methods, onSubmit } = useAdmin();

  const handleSubmit = async (input: AdminFormValues) => {
    try {
      await onSubmit(input, data?.guid);
      onSuccess();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    setIsVisible(false);
    methods.reset();
    setHospitalsSelected([]);
  };

  useEffect(() => {
    if (!isVisble) {
      onClose();
      return;
    }

    if (!data) return;

    methods.setValue(AdminFields.Name, data.adminName!);
    methods.setValue(AdminFields.Email, data.adminEmail!);
    methods.setValue(
      AdminFields.Hospitals,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      data.hospitals?.map((x) => x.guid) ?? [],
    );
    setHospitalsSelected(data.hospitals!);
  }, [isVisble]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <h2 style={{ marginBottom: '2.3rem' }}>Admin</h2>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={5}>
              <TextField
                name={AdminFields.Name}
                label="Nome do administrador"
                placeholder="Digite o nome do administrador"
                required
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                name={AdminFields.Email}
                type="email"
                label="Email do administrador"
                placeholder="Digite o email do administrador"
                required
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={5}
            visibility={data?.isSuperAdmin ? 'hidden' : 'visible'}
            style={{ marginTop: '1rem' }}
          >
            <Grid item xs={5}>
              <label htmlFor="hospitals">Hospitais administrados</label>
              <Autocomplete
                multiple
                value={hospitalsSelected ?? []}
                size="small"
                onChange={(event, newValue: Array<{ guid: string }>) => {
                  setHospitalsSelected(newValue);
                  methods.setValue(
                    AdminFields.Hospitals,
                    newValue.map((i: { guid: string }) => i.guid),
                  );
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={hospitals.filter((x) => x.isActive)}
                getOptionLabel={(option) => option.name}
                sx={{ width: '100%', marginTop: '0.7rem' }}
                renderInput={(params) => <MuiTextField {...params} />}
              />
            </Grid>
          </Grid>
          <div className="buttons-row">
            <Button onClick={onClose} color="destructive">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
