import { useEffect, useMemo, useState } from 'react';
import { MedicalGroupService } from '../../api/medicalGroup';
import Button from '../../components/button';
import Container from '../../components/container';
import Table from '../../components/table';
import { type IUser } from '../../models/IUser';
import AddProfessional, { type addFields } from './add';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import searchIcon from '../../assets/search.svg';
import addIcon from '../../assets/add.svg';
import './style.css';
import { Pagination, Stack } from '@mui/material';
import Modal from '../../components/modal';
import { UserService } from '../../api/user';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useProfessionals from './hooks/useProfessionals';

function Professionals() {
  const [professionals, setProfessionals] = useState<IUser[]>();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [editData, setEditData] = useState<addFields | undefined>();
  const [actualPage, setActualPage] = useState(1);
  const [search, setSearch] = useState<string | undefined>();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteAllVisible, setIsDeleteAllVisible] = useState(false);
  const [userToRemove, setUserToRemove] = useState<IUser | undefined>();
  const { selectedMedicalGroup } = useAuth();
  const [checkedUsers, setCheckedUsers] = useState<IUser[]>([]);
  const { selectedHospital } = useAuth();

  const fetchProfessionals = async () => {
    if (selectedMedicalGroup && selectedHospital) {
      const ret = await MedicalGroupService.getGroupProfessionals(
        selectedMedicalGroup.guid,
        selectedHospital.guid,
      );
      setProfessionals(ret);
    }
  };

  const removeUserFromGroup = async () => {
    if (userToRemove && selectedMedicalGroup) {
      await UserService.removeFromGroup(
        [userToRemove.guid],
        selectedMedicalGroup.guid,
      );
      onSuccess();
      toast.success('Profissional removido com sucesso.');
      fetchProfessionals();
    }
  };

  const removeCheckedUsersFromGroup = async () => {
    if (checkedUsers.length > 0 && selectedMedicalGroup) {
      try {
        await UserService.removeFromGroup(
          checkedUsers.map((user) => user.guid),
          selectedMedicalGroup.guid,
        );
        onSuccess();
        toast.success('Profissional removido com sucesso.');
      } catch (e) {
        toast.error('Houve um erro ao removes os profissionals selecionados.');
      } finally {
        setSearch('');
        setActualPage(1);
        setCheckedUsers([]);
      }
    }
  };

  const handleEditButton = (professional: IUser) => {
    setEditData({
      guid: professional.guid || undefined,
      name: professional.name || undefined,
      email: professional.email || undefined,
      phone1: professional.phone1 || undefined,
      phone2: professional.phone2 || undefined,
      profession: professional.profession || undefined,
      crm: professional.crm || undefined,
      ufCrm: professional.uf || undefined,
      // adress
      cep: professional.cep || undefined,
      street: professional.street || undefined,
      number: professional.number || undefined,
      district: professional.district || undefined,
      complement: professional.complement || undefined,
      uf: professional.uf || undefined,
      city: professional.city || undefined,
      // details
      admissionDate: professional.admissionDate
        ? dayjs(professional.admissionDate)
        : null,
      code: professional.code || undefined,
      details: professional.details || undefined,
      additional: professional.additional || undefined,
    });

    setIsAddVisible(true);
  };
  useEffect(() => {
    fetchProfessionals();
  }, []);

  useEffect(() => {
    fetchProfessionals();
  }, [selectedMedicalGroup]);

  const onSuccess = () => {
    fetchProfessionals();
    setIsAddVisible(false);
    setIsDeleteVisible(false);
    setIsDeleteAllVisible(false);
    setActualPage(1);
    setCheckedUsers([]);
  };

  const onCancel = () => {
    setIsDeleteVisible(false);
  };

  const filteredProfessionals = useMemo(() => {
    const selectedProfessionals = professionals
      ?.filter((p) =>
        search ? p.name.toLowerCase().search(search.toLowerCase()) >= 0 : true,
      )
      .slice((actualPage - 1) * 10, actualPage * 10);
    return selectedProfessionals ?? [];
  }, [search, actualPage, professionals]);

  return (
    <>
      {!isAddVisible && (
        <Container>
          {professionals && professionals.length > 0 ? (
            <>
              <div className="header">
                <div>
                  <span>Profissionais</span> <br />
                  <br />
                  <span className="sub-title">
                    {professionals?.length}{' '}
                    {professionals.length === 1
                      ? 'profissional'
                      : 'profissionais'}
                  </span>
                </div>
                {checkedUsers.length === 0 ? (
                  <div style={{ display: 'flex' }}>
                    <div
                      className="styled-input-container input-with-icon"
                      style={{ marginRight: '1rem' }}
                    >
                      <input
                        placeholder="Pesquisar profissional"
                        value={search}
                        onChange={(event) => {
                          setSearch(event.target.value);
                          setActualPage(1);
                        }}
                      />
                      <img className="input-icon" src={searchIcon} alt="" />
                    </div>
                    <div style={{ width: '14rem' }}>
                      <Button
                        onClick={() => {
                          setEditData(undefined);
                          setIsAddVisible(true);
                        }}
                        disabled={false}
                      >
                        <img
                          src={addIcon}
                          alt=""
                          style={{ marginRight: '1rem' }}
                        />
                        Adicionar profissional
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: '14rem' }}>
                    <Button
                      onClick={() => {
                        setIsDeleteAllVisible(true);
                      }}
                      disabled={false}
                      color="destructive"
                    >
                      Excluir profissionais
                    </Button>
                  </div>
                )}
              </div>

              <Table>
                <thead>
                  <tr>
                    <td className="select">
                      <input
                        className="styled-checkbox"
                        type="checkbox"
                        checked={
                          checkedUsers.length === filteredProfessionals?.length
                        }
                        onChange={() => {
                          if (
                            checkedUsers.length ===
                            filteredProfessionals?.length
                          ) {
                            setCheckedUsers([]);
                          } else {
                            setCheckedUsers(filteredProfessionals ?? []);
                          }
                        }}
                      />
                    </td>
                    <td className="photo">FOTO</td>
                    <td>NOME</td>
                    <td>GRUPO</td>
                    <td>E-MAIL</td>
                    <td>CONTATO</td>
                    <td>STATUS</td>
                    <td style={{ width: '7rem' }}></td>
                  </tr>
                </thead>
                <tbody>
                  {filteredProfessionals.map((professional) => {
                    return (
                      <tr>
                        <td className="select">
                          <input
                            className="styled-checkbox"
                            type="checkbox"
                            checked={
                              checkedUsers.findIndex(
                                (p) => p.guid === professional.guid,
                              ) >= 0
                            }
                            onChange={() => {
                              const position = checkedUsers.findIndex(
                                (p) => p.guid === professional.guid,
                              );
                              if (position >= 0) {
                                const newList = checkedUsers.filter(
                                  (v, i) => i !== position,
                                );
                                setCheckedUsers(newList);
                              } else {
                                setCheckedUsers([
                                  ...checkedUsers,
                                  professional,
                                ]);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <div className="photo">
                            <span>{professional.name.slice(0, 1)}</span>
                          </div>
                        </td>
                        <td>
                          <b>{professional.name}</b>
                          <br />
                          {professional.specialties?.map((specialty) => (
                            <p>{specialty.title}</p>
                          ))}
                        </td>
                        <td>{selectedMedicalGroup?.name}</td>
                        <td>{professional.email}</td>
                        <td>{professional.phone1}</td>
                        <td>{professional.isActive ? 'Ativo' : 'Inativo'}</td>
                        <td className="actions">
                          <button
                            className="edit-button"
                            onClick={() => {
                              handleEditButton(professional);
                            }}
                          >
                            <img src={editIcon} alt="" />
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => {
                              setUserToRemove(professional);
                              setIsDeleteVisible(true);
                            }}
                          >
                            <img src={trashIcon} alt="" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Stack alignItems="end">
                <Pagination
                  style={{ marginTop: '1rem' }}
                  count={Math.ceil(
                    (professionals?.filter((p) =>
                      search
                        ? p.name.toLowerCase().search(search.toLowerCase()) ===
                          0
                        : true,
                    ).length || 10) / 10,
                  )}
                  shape="rounded"
                  color="primary"
                  onChange={(e, p) => {
                    setActualPage(p);
                  }}
                />
              </Stack>
            </>
          ) : (
            <>
              <div className="header">
                <div>
                  <span>Profissionais</span> <br />
                  <br />
                  <span className="sub-title">
                    {professionals?.length || '0'} profissionais
                  </span>
                </div>
              </div>
              <div className="default-message">
                <h2>Adicione profissionais que irão trabalhar com você</h2>
                <div
                  style={{
                    width: '15rem',
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: '2rem',
                  }}
                >
                  <Button
                    disabled={!selectedMedicalGroup}
                    color="primary"
                    onClick={() => {
                      setIsAddVisible(true);
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: '1rem' }}
                    >
                      <path
                        d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
                        fill="white"
                      />
                    </svg>
                    Adicionar profissional
                  </Button>
                </div>
              </div>
            </>
          )}
        </Container>
      )}
      {isAddVisible && (
        <Container>
          <AddProfessional
            setIsVisible={setIsAddVisible}
            data={editData}
            setData={setEditData}
            onSuccess={onSuccess}
            professionals={professionals}
          />
        </Container>
      )}
      <Modal
        isVisible={isDeleteVisible}
        setIsVisible={setIsDeleteVisible}
        size="md"
      >
        <h2>Excluir Profissional</h2>
        <h3 className="sub-title">
          Tem certeza que deseja excluir este profissional? Ele não fará mais
          parte da sua lista de profissionais.
        </h3>
        <div className="buttons-row" style={{ paddingTop: '1rem' }}>
          <Button disabled={false} color="destructive" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            disabled={false}
            color="primary"
            onClick={() => {
              removeUserFromGroup();
            }}
          >
            Excluir
          </Button>
        </div>
      </Modal>

      <Modal
        isVisible={isDeleteAllVisible}
        setIsVisible={setIsDeleteAllVisible}
        size="md"
      >
        <h2>Excluir Profissionais</h2>
        <h3 className="sub-title">
          Tem certeza que deseja excluir todos os <b>{checkedUsers.length}</b>{' '}
          profissionais selecionados da sua lista?
        </h3>
        <div className="buttons-row" style={{ paddingTop: '1rem' }}>
          <Button
            disabled={false}
            color="destructive"
            onClick={() => {
              setIsDeleteAllVisible(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={false}
            color="primary"
            onClick={() => {
              removeCheckedUsersFromGroup();
            }}
          >
            Excluir
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Professionals;
