import React from 'react';

interface HospitalButtonProps {
  isActive: boolean;
  onClick: () => void;
}

export const ActivateInactivateButton: React.FC<HospitalButtonProps> = ({
  isActive,
  onClick,
}) => {
  return (
    <button
      className={isActive ? 'pause-button' : 'play-button'}
      onClick={onClick}
    >
      {isActive ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_744_5308)">
            <path
              d="M6.49999 0C5.57173 0 4.6815 0.368749 4.02512 1.02513C3.36874 1.6815 2.99999 2.57174 2.99999 3.5V20.5C2.99999 21.4283 3.36874 22.3185 4.02512 22.9749C4.6815 23.6313 5.57173 24 6.49999 24C7.42825 24 8.31849 23.6313 8.97487 22.9749C9.63124 22.3185 9.99999 21.4283 9.99999 20.5V3.5C9.99999 2.57174 9.63124 1.6815 8.97487 1.02513C8.31849 0.368749 7.42825 0 6.49999 0ZM7.99999 20.5C7.99999 20.8978 7.84196 21.2794 7.56065 21.5607C7.27935 21.842 6.89782 22 6.49999 22C6.10217 22 5.72064 21.842 5.43933 21.5607C5.15803 21.2794 4.99999 20.8978 4.99999 20.5V3.5C4.99999 3.10218 5.15803 2.72064 5.43933 2.43934C5.72064 2.15804 6.10217 2 6.49999 2C6.89782 2 7.27935 2.15804 7.56065 2.43934C7.84196 2.72064 7.99999 3.10218 7.99999 3.5V20.5Z"
              fill="#4B585E"
            />
            <path
              d="M17.5001 0C16.5719 0 15.6816 0.368749 15.0253 1.02513C14.3689 1.6815 14.0001 2.57174 14.0001 3.5V20.5C14.0001 21.4283 14.3689 22.3185 15.0253 22.9749C15.6816 23.6313 16.5719 24 17.5001 24C18.4284 24 19.3186 23.6313 19.975 22.9749C20.6314 22.3185 21.0001 21.4283 21.0001 20.5V3.5C21.0001 2.57174 20.6314 1.6815 19.975 1.02513C19.3186 0.368749 18.4284 0 17.5001 0ZM19.0001 20.5C19.0001 20.8978 18.8421 21.2794 18.5608 21.5607C18.2795 21.842 17.898 22 17.5001 22C17.1023 22 16.7208 21.842 16.4395 21.5607C16.1582 21.2794 16.0001 20.8978 16.0001 20.5V3.5C16.0001 3.10218 16.1582 2.72064 16.4395 2.43934C16.7208 2.15804 17.1023 2 17.5001 2C17.898 2 18.2795 2.15804 18.5608 2.43934C18.8421 2.72064 19.0001 3.10218 19.0001 3.5V20.5Z"
              fill="#4B585E"
            />
          </g>
          <defs>
            <clipPath id="clip0_744_5308">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.494 7.96762L7.954 0.967621C7.20889 0.422006 6.32722 0.0934154 5.40677 0.0182844C4.48631 -0.0568467 3.56302 0.124418 2.7393 0.541978C1.91557 0.959539 1.22358 1.59708 0.740058 2.38391C0.25654 3.17073 0.000386015 4.0761 0 4.99962V18.9996C0.000164771 19.9236 0.256333 20.8294 0.740075 21.6166C1.22382 22.4037 1.91621 23.0415 2.74042 23.459C3.56463 23.8766 4.48841 24.0576 5.40925 23.982C6.33008 23.9063 7.21196 23.577 7.957 23.0306L17.497 16.0306C18.1303 15.5662 18.6452 14.9592 19.0002 14.2587C19.3552 13.5582 19.5401 12.7839 19.5401 11.9986C19.5401 11.2133 19.3552 10.4391 19.0002 9.73856C18.6452 9.03806 18.1303 8.43103 17.497 7.96662L17.494 7.96762ZM16.31 14.4176L6.77 21.4176C6.32302 21.7442 5.79437 21.9408 5.24257 21.9856C4.69078 22.0304 4.13736 21.9216 3.64357 21.6712C3.14978 21.4209 2.73488 21.0389 2.4448 20.5674C2.15472 20.0958 2.00078 19.5532 2 18.9996V4.99962C1.99445 4.44497 2.1455 3.9 2.43578 3.42734C2.72606 2.95469 3.1438 2.57351 3.641 2.32762C4.06389 2.11251 4.53154 2.00014 5.006 1.99962C5.64189 2.00205 6.26022 2.20851 6.77 2.58862L16.31 9.58862C16.6895 9.86732 16.9981 10.2314 17.2107 10.6515C17.4234 11.0716 17.5343 11.5358 17.5343 12.0066C17.5343 12.4775 17.4234 12.9417 17.2107 13.3618C16.9981 13.7818 16.6895 14.1459 16.31 14.4246V14.4176Z"
            fill="#4B585E"
          />
        </svg>
      )}
    </button>
  );
};
