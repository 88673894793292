import { useState } from 'react';
import api from '../../api';
import logo from '../../assets/Agendoctor.svg';
import './styles.css';
import { toast } from 'react-toastify';

export function RemoveAccountRequest() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await api.post('/users/requests/remove_account', {
        email,
      });

      toast.success(
        'Solicitação enviada com sucesso! Verifique sua caixa de entrada para confirmar a remoção.',
      );

      setError('');
    } catch (err) {
      setError(
        'Erro ao enviar solicitação. Verifique seu E-mail e tente novamente.',
      );
    }
  };

  return (
    <div className="ra-container">
      <img className="ra-logo" src={logo} alt="" />
      <h1 className="ra-title">Solicitação de remoção de conta</h1>
      <div className="ra-explanation-box">
        <p className="ra-text">Digite o e-mail da conta que deseja remover.</p>
        <p className="ra-text">
          Enviaremos um link de confirmação para o e-mail informado.
        </p>
      </div>
      <form className="ra-form" onSubmit={handleSubmit}>
        <div className="ra-form-container">
          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />
          {error && <span className="ra-error">{error}</span>}
          <br />
          <br />
          <span>Os seguintes dados serão removidos:</span>
          <ul>
            <li>Nome</li>
            <li>E-mail</li>
            <li>CRM</li>
            <li>Telefone</li>
            <li>Notificações</li>
          </ul>
          <span>
            Os seguintes dados não serão removidos, mas serão desvinculados da
            conta:
          </span>
          <ul>
            <li>Agenda</li>
            <li>Grupos</li>
            <li>Turnos</li>
            <li>Profissionais</li>
          </ul>
        </div>
        <button type="submit">Remover conta</button>
      </form>
    </div>
  );
}
