import {
  MonthSelector,
  MonthSelectorTitle,
  MonthSelectorActions,
  MonthSelectorButton,
  MonthSpan,
  YearSpan,
  MonthSelectorButtonRotated,
  MonthYearDisplay,
} from '../styles';

export type MonthSelectorCProps = {
  to?: boolean;
  from?: boolean;
  month: number;
  year: number;
  onNext: () => void;
  onPrev: () => void;
} & (
  | { to: true; from?: never }
  | { from: true; to?: never }
  | { to: undefined; from: undefined }
);

export function MonthSelectorC({
  month,
  year,
  to,
  from,
  onNext,
  onPrev,
}: MonthSelectorCProps) {
  return (
    <MonthSelector>
      <MonthSelectorTitle>
        {to && 'Para'}
        {from && 'De'}
      </MonthSelectorTitle>
      <MonthSelectorActions>
        <MonthSelectorButton
          disabled={
            to
              ? new Date().getMonth() + 1 >= month &&
                year <= new Date().getFullYear()
              : false
          }
          onClick={onPrev}
        >
          <Arrow2Icon />
        </MonthSelectorButton>
        <MonthYearDisplay>
          <MonthSpan>{numberToMonthName(month)}</MonthSpan>
          <YearSpan>{year}</YearSpan>
        </MonthYearDisplay>
        <MonthSelectorButtonRotated onClick={onNext}>
          <Arrow2Icon />
        </MonthSelectorButtonRotated>
      </MonthSelectorActions>
    </MonthSelector>
  );
}

function Arrow2Icon() {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6891 16L8 12L10.6891 8"
        stroke="#212529"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function numberToMonthName(month: number, locale = 'pt-BR') {
  const monthNames = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(2000, i); // Usamos um ano comum para garantir que todos os meses estejam disponíveis
    return new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
  });

  if (month >= 1 && month <= 12) {
    return monthNames[month - 1];
  } else if (month === 0) {
    return monthNames[11];
  } else {
    return 'Mês inválido';
  }
}
