import { useEffect, useState } from 'react';
import './style.css';

function StickyButton() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.getElementById('pages')?.scrollTop;
    if (scrolled && scrolled > 300) {
      setVisible(true);
    } else if (scrolled && scrolled <= 300) {
      setVisible(false);
    }
  };

  document.getElementById('pages')?.addEventListener('scroll', toggleVisible);
  return visible ? (
    <button
      className="sticky-button"
      onClick={() => {
        document.getElementById('pages')?.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9478 18C14.6796 18 14.41 17.8962 14.205 17.6887L9.3089 12.7567C9.11092 12.5563 9 12.2848 9 12.0005C9 11.7177 9.11092 11.4462 9.3089 11.2458L14.205 6.31092C14.6164 5.89589 15.282 5.89589 15.6934 6.31376C16.1034 6.73163 16.102 7.40677 15.6906 7.8218L11.5442 12.0005L15.6906 16.1793C16.102 16.5943 16.1034 17.268 15.6934 17.6859C15.4884 17.8962 15.2174 18 14.9478 18Z"
          fill="#009FFA"
        />
      </svg>
    </button>
  ) : (
    <></>
  );
}

export default StickyButton;
