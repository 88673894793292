import addNotification from '../../assets/add-notification.svg';
import heart from '../../assets/heart.svg';
import hospital from '../../assets/hospital.svg';
import crossCircle from '../../assets/cross-circle.svg';
import './style.css';

interface NotificationProps {
  title: string;
  message: string;
  type: number;
  sendDate: string;
}
function Notification({ title, message, type, sendDate }: NotificationProps) {
  const hour = new Date(sendDate);
  const typeIcon = [
    {
      class: 'add',
      icon: addNotification,
    },
    {
      class: 'add',
      icon: addNotification,
    },
    {
      class: 'add',
      icon: addNotification,
    },
    {
      class: 'hear',
      icon: heart,
    },
    {
      class: 'hear',
      icon: heart,
    },
    {
      class: 'hear',
      icon: heart,
    },
    {
      class: 'cross-circle',
      icon: crossCircle,
    },
    {
      class: 'cross-circle',
      icon: crossCircle,
    },
    {
      class: 'add',
      icon: addNotification,
    },
    {
      class: 'hospital',
      icon: hospital,
    },
    {
      class: 'hospital',
      icon: hospital,
    },
    {
      class: 'cross-circle',
      icon: crossCircle,
    },
    {
      class: 'add',
      icon: addNotification,
    },
  ];

  return (
    <div className="notification">
      <div className="icon-div">
        <div className={`icon ${typeIcon[type - 1]?.class}`}>
          <img src={typeIcon[type - 1]?.icon} />
        </div>
      </div>
      <div className="title-message">
        <b>{title}</b>
        <p>{message}</p>
      </div>
      <div className="hour">
        {('0' + hour.getHours()).slice(-2) +
          'h' +
          ('0' + hour.getMinutes()).slice(-2)}
      </div>
    </div>
  );
}

export default Notification;
