import styled from 'styled-components';

interface FormFieldProps {
  focused: boolean;
}

export const FormField = styled.div<FormFieldProps>`
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: solid 1px
    ${({ focused }) => (focused ? 'var(--gray800)' : 'var(--gray400)')};
  overflow: hidden;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
`;

export const TextField = styled.input`
  padding: 0.6rem 0.9rem;
  flex: 1;
  border: 0;
  outline: none;
`;

export const EyeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--gray600);
  margin-right: 0.6rem;
  cursor: pointer;
  user-select: none;
`;

export const Error = styled.small`
  color: var(--red);
  margin-top: 0.2rem;
`;
