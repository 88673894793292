import { Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { HospitalService } from '../../api/hospital';
import Container from '../../components/container';
import { type IHospital } from '../../models/IHospital';
import './style.css';
import editIcon from '../../assets/edit.svg';
import pauseIcon from '../../assets/pause.svg';
import playIcon from '../../assets/play.svg';
import { formatDate2 } from '../../utils/formatDate';
import Button from '../../components/button';
import addIcon from '../../assets/add.svg';
import searchIcon from '../../assets/search.svg';
import AddHospital, { type addHospitalData } from './add';
import Modal from '../../components/modal';
import Table from '../../components/table';
import { toast } from 'react-toastify';
import { ActivateInactivateButton } from '../../components/activateInactivateButton';
import dayjs from '../../utils/dayjs';

function Hospitals() {
  const [hospitals, setHospitals] = useState<IHospital[]>();
  const [actualPage, setActualPage] = useState(1);
  const [search, setSearch] = useState<string | undefined>();
  const [isAddVisible, setIsAddVisible] = useState(false);

  const defaultEditData: addHospitalData = {
    guid: undefined,
    hospitalName: undefined,
    maxNumberOfUsers: undefined,
    isActive: true,
    admins: [],
  };

  const [editData, setEditData] = useState<addHospitalData>(defaultEditData);

  const fetchHospitals = async () => {
    setHospitals(await HospitalService.getAllHospitals());
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  const handleEditButton = (hospital: IHospital) => {
    setEditData({
      guid: hospital.guid,
      hospitalName: hospital.name,
      maxNumberOfUsers: hospital.maxNumberOfUsers,
      isActive: hospital.isActive,
      admins: hospital.admins,
      timezone: hospital.timezone,
    });

    setIsAddVisible(true);
  };

  const handleSubmit = async (guid: string, isActive: boolean) => {
    await HospitalService.createOrUpdateHospital({ guid, isActive });
    toast.dismiss();
    toast.success('Hospital atualizado com sucesso.');
    onSuccess();
  };

  const handleEnableDisable = async (guid: string, isActive: boolean) => {
    await HospitalService.enableDisableHospital({ guid, isActive });
    toast.dismiss();
    toast.success(`Hospital ${isActive ? 'Ativo' : 'Inativo'}`);
    onSuccess();
  };

  const onSuccess = () => {
    fetchHospitals();
    setIsAddVisible(false);
  };
  return (
    <>
      <Container>
        <div className="header">
          <div>
            <span>Hospitais</span> <br />
            <br />
            <span className="sub-title">
              {hospitals?.length}{' '}
              {hospitals?.length === 1 ? 'registro' : 'registros'}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className="styled-input-container input-with-icon"
              style={{ marginRight: '1rem' }}
            >
              <input
                placeholder="Pesquisar hospital"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  setActualPage(1);
                }}
              />
              <img className="input-icon" src={searchIcon} alt="" />
            </div>
            <div style={{ width: '12rem' }}>
              <Button
                onClick={() => {
                  setEditData(defaultEditData);
                  setIsAddVisible(true);
                }}
                disabled={false}
              >
                <img src={addIcon} alt="" style={{ marginRight: '1rem' }} />
                Adicionar hospital
              </Button>
            </div>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <td>NOME</td>
              <td>ADMINISTRADORES</td>
              <td>MÁXIMO DE USUÁRIOS</td>
              <td>STATUS</td>
              <td>CRIADO EM</td>
              <td style={{ width: '7rem' }}></td>
            </tr>
          </thead>
          <tbody>
            {hospitals
              ?.filter((p) =>
                search
                  ? p.name.toLowerCase().search(search.toLowerCase()) >= 0
                  : true,
              )
              .slice((actualPage - 1) * 10, actualPage * 10)
              .map((hospital, i) => {
                const admins = hospital.admins
                  .reduce((acc, adm) => {
                    return acc + adm.name + ', ';
                  }, '')
                  .slice(0, -2);

                return (
                  <tr key={i}>
                    <td>{hospital.name}</td>
                    <td>
                      {admins.length > 15
                        ? admins.slice(0, 30) + '...'
                        : admins}
                    </td>
                    <td>{hospital.maxNumberOfUsers}</td>
                    <td>{hospital.isActive ? 'Ativo' : 'Inativo'}</td>
                    <td>{dayjs(hospital.createdAt).format('DD/MM/YYYY')}</td>
                    <td className="actions">
                      <button
                        className="edit-button"
                        onClick={() => {
                          handleEditButton(hospital);
                        }}
                      >
                        <img src={editIcon} alt="" />
                      </button>
                      <ActivateInactivateButton
                        isActive={hospital.isActive}
                        onClick={async () => {
                          await handleEnableDisable(
                            hospital.guid,
                            !hospital.isActive,
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Stack alignItems="end">
          <Pagination
            style={{ float: 'right', marginTop: '1rem' }}
            count={Math.ceil(
              (hospitals?.filter((p) =>
                search
                  ? p.name.toLowerCase().search(search.toLowerCase()) === 0
                  : true,
              ).length ?? 10) / 10,
            )}
            shape="rounded"
            color="primary"
            onChange={(e, p) => {
              setActualPage(p);
            }}
          />
        </Stack>
      </Container>
      {isAddVisible && (
        <Modal
          setIsVisible={setIsAddVisible}
          isVisible={isAddVisible}
          size="xmd"
        >
          <AddHospital
            data={editData}
            setData={setEditData}
            isVisble={isAddVisible}
            setIsVisible={setIsAddVisible}
            onSuccess={onSuccess}
          />
        </Modal>
      )}
    </>
  );
}

export default Hospitals;
