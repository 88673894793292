import { z } from 'zod';

export enum GroupFields {
  Id = 'id',
  Name = 'name',
  Responsibles = 'responsibles',
  HospitalId = 'hospitalId',
}

export const groupInitialValues = {
  id: undefined as string | undefined,
  name: '',
  responsibles: [] as string[],
  hospitalId: '',
};

export const groupValidationSchema = z.object({
  id: z.string().uuid().optional(),
  name: z
    .string({
      required_error: 'Name is required',
    })
    .min(1, 'Nome muito curto')
    .max(50, 'Nome muito longo'),
  responsibles: z
    .array(z.string())
    .min(1, 'Necessário pelo menos um responsável'),
  hospitalId: z.string().uuid(),
});

export type GroupValues = z.infer<typeof groupValidationSchema>;
