import Login from './pages/login';
import { PrivateRoute } from './components/PrivateRoute';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import Shifts from './pages/shifts';
import Groups from './pages/groups';
import Professionals from './pages/professionals';
import Notifications from './pages/notifications';
import GlobalLoadingProvider from './context/GlobalLoading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Turnos from './pages/turnos';
import Hospitals from './pages/hospitals';
import LanguageProvider from './context/LanguageContext';
import LoginAdministrator from './pages/loginAdministrator';
import { RemoveAccountRequest } from './pages/requests/RemoveAccountRequest';
import { PublicRoute } from './components/PublicRoute';
import Admins from './pages/admins';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#009FFA',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LanguageProvider>
          <GlobalLoadingProvider>
            <div className="App">
              <BrowserRouter>
                <Routes>
                  <Route
                    path="shifts"
                    element={
                      <PrivateRoute allowManager allowAdmin>
                        <Shifts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="groups"
                    element={
                      <PrivateRoute allowAdmin>
                        <Groups />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="professionals"
                    element={
                      <PrivateRoute allowManager allowAdmin>
                        <Professionals />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="notifications"
                    element={
                      <PrivateRoute allowManager allowAdmin>
                        <Notifications />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="turnos"
                    element={
                      <PrivateRoute allowManager allowAdmin>
                        <Turnos />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="groups"
                    element={
                      <PrivateRoute allowAdmin>
                        <Groups />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="hospitals"
                    element={
                      <PrivateRoute allowSuperadmin>
                        <Hospitals />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="admins"
                    element={
                      <PrivateRoute allowSuperadmin>
                        <Admins />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <PublicRoute>
                        <Login />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <PublicRoute>
                        <Login />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/login/admin"
                    element={
                      <PublicRoute>
                        <LoginAdministrator />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/requests/remove-account"
                    element={
                      <PublicRoute>
                        <RemoveAccountRequest />
                      </PublicRoute>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </div>
            <ToastContainer />
          </GlobalLoadingProvider>
        </LanguageProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
