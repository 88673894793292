const text = {
  profileUpdate: {
    'pt-Br': 'Perfil atualizado com sucesso',
    'en-Us': 'Profile successfully updated',
  },
  passwordUpdate: {
    'pt-Br': 'Senha atualizada com sucesso',
    'en-Us': 'Password successfully updated',
  },
  menuNames: {
    shifts: {
      'pt-Br': 'Escalas',
      'en-Us': 'Shifts',
    },
    professionals: {
      'pt-Br': 'Profissionais',
      'en-Us': 'Professionals',
    },
    notifications: {
      'pt-Br': 'Notificações',
      'en-Us': 'Notifications',
    },
    turnos: {
      'pt-Br': 'Turnos',
      'en-Us': 'Shift Definitions',
    },
    groups: {
      'pt-Br': 'Grupos',
      'en-Us': 'Groups',
    },
    hospitals: {
      'pt-Br': 'Hospitais',
      'en-Us': 'Hospitals',
    },
    admins: {
      'pt-Br': 'Administradores',
      'en-Us': 'Administrators',
    },
  },
  myProfile: {
    'pt-Br': 'Meu perfil',
    'en-Us': 'My profile',
  },
  logOut: {
    'pt-Br': 'Sair',
    'en-Us': 'Log out',
  },
  groupAlert: {
    'pt-Br':
      'Você precisa criar um grupo para continuar utilizando o Agendoctor! Para criar',
    'en-Us':
      'You need to create a group to continue using Agendoctor! To create',
  },
  clickHere: {
    'pt-Br': 'clique aqui',
    'en-Us': 'click here',
  },
  personalData: {
    'pt-Br': '..',
    'en-Us': 'Personal data',
  },
  form: {
    name: {
      'pt-Br': 'Nome',
      'en-Us': 'Name',
    },
    email: {
      'pt-Br': 'E-mail',
      'en-Us': 'E-mail',
    },
    changePassword: {
      'pt-Br': 'Alterar senha',
      'en-Us': 'Change password',
    },
    phone: {
      'pt-Br': 'Telefone',
      'en-Us': 'Phone',
    },
    profession: {
      'pt-Br': 'Profissão',
      'en-Us': 'Profession',
    },
    crm: {
      'pt-Br': 'CRM',
      'en-Us': 'CRM',
    },
    ufCrm: {
      'pt-Br': 'UF CRM',
      'en-Us': 'UF CRM',
    },
    adress: {
      'pt-Br': 'Endereço',
      'en-Us': 'Adress',
    },
    cep: {
      'pt-Br': 'CEP',
      'en-Us': 'CEP',
    },
    street: {
      'pt-Br': 'Rua',
      'en-Us': 'Street',
    },
    number: {
      'pt-Br': 'Número',
      'en-Us': 'Number',
    },
    district: {
      'pt-Br': 'Bairro',
      'en-Us': 'District',
    },
    complement: {
      'pt-Br': 'Complemento',
      'en-Us': 'Complement',
    },
    uf: {
      'pt-Br': 'UF',
      'en-Us': 'State',
    },
    city: {
      'pt-Br': 'Cidade',
      'en-Us': 'City',
    },
    details: {
      'pt-Br': 'Detalhes',
      'en-Us': 'Details',
    },
    admissionDate: {
      'pt-Br': 'Data de admissão',
      'en-Us': 'Admission date',
    },
    code: {
      'pt-Br': 'Código',
      'en-Us': 'Code',
    },
    save: {
      'pt-Br': 'Salvar',
      'en-Us': 'Save',
    },
    createNewPassword: {
      'pt-Br': 'Crie uma nova senha',
      'en-Us': 'Create a new password',
    },
    actualPassword: {
      'pt-Br': 'Senha atual',
      'en-Us': 'Current password',
    },
    newPassword: {
      'pt-Br': 'Nova senha',
      'en-Us': 'New password',
    },
    confirmNewPassword: {
      'pt-Br': 'Confirmar nova senha',
      'en-Us': 'Confirm new password',
    },
  },
  selectYourLanguage: {
    title: {
      'pt-Br': 'Selecione sua linguagem',
      'en-Us': 'Select your language',
    },
    portuguese: {
      'pt-Br': 'Português',
      'en-Us': 'Portuguese',
    },
    english: {
      'pt-Br': 'Inglês',
      'en-Us': 'English',
    },
  },
};

export default text;
