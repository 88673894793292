import React, {
  createContext,
  type ReactElement,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from './AuthContext';
import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface LanguageContextProps {
  language: 'pt-Br' | 'en-Us';
  setLanguage: (l: 'pt-Br' | 'en-Us') => void;
}

interface LanguageProviderProps {
  children: ReactNode | ReactElement;
}
export const LanguageContext = createContext({} as LanguageContextProps);

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState<'pt-Br' | 'en-Us'>('pt-Br');
  const auth = useAuth();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(
        storedLanguage === 'pt-Br' || storedLanguage === 'en-Us'
          ? storedLanguage
          : 'pt-Br',
      );
    }
  }, []);

  useEffect(() => {
    if (auth.user && !('isSuperAdmin' in auth.user) && auth.user.language) {
      switch (auth.user.language) {
        case 1:
          setLanguage('pt-Br');
          break;
        case 2:
          setLanguage('en-Us');
          break;
      }
    }
  }, [auth.user]);
  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pt-br"
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        {children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

export const useLanguage = () => useContext(LanguageContext);
