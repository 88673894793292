import dayjs from 'dayjs';
import { z } from 'zod';

export enum ProfessionalFields {
  Email = 'email',
  Name = 'name',
  MainPhone = 'mainPhone',
  SecondaryPhone = 'secondaryPhone',
  Profession = 'profession',
  CRM = 'crm',
  UfCRM = 'ufCRM',
  CEP = 'cep',
  Street = 'street',
  Number = 'number',
  Neighborhood = 'neighborhood',
  Complement = 'complement',
  UF = 'uf',
  City = 'city',
  Bonus = 'bonus',
  AdmissionDate = 'admissionDate',
  Code = 'code',
  Details = 'details',
  HospitalId = 'hospitalId',
  MedicalGroupId = 'medicalGroupId',
}

export const professionalInitialValues = {
  email: '',
  name: '',
  mainPhone: '',
  secondaryPhone: '',
  profession: '',
  crm: '',
  ufCRM: '',
  cep: '',
  street: '',
  number: '',
  neighborhood: '',
  complement: '',
  uf: '',
  city: '',
  bonus: '',
  admissionDate: '',
  code: '',
  details: '',
  hospitalId: '',
  medicalGroupId: '',
};

export const professionalValidationSchema = z.object({
  email: z.string().trim().email('E-mail inválido'),
  name: z
    .string({
      required_error: 'Nome é obrigatório',
    })
    .trim()
    .min(2, 'Nome deve conter no mínimo 3 caracteres'),
  mainPhone: z
    .string({
      required_error: 'Telefone é obrigatório',
    })
    .trim()
    .refine((value) => {
      value = value?.replace(/\D/g, '');
      return !!value && value.length === 11;
    }, 'Deve conter 11 caracteres'),
  secondaryPhone: z
    .string()
    .trim()
    .optional()
    .refine((value) => {
      value = value?.replace(/\D/g, '');
      return !value ? true : value.length === 11;
    }, 'Deve conter 11 caracteres'),
  profession: z
    .string()
    .trim()
    .optional()
    .refine(
      (i) => (!i ? true : i.length >= 2),
      'Profissão deve conter no mínimo 2 caracteres',
    ),
  crm: z
    .string({
      required_error: 'CRM é obrigatório',
    })
    .min(1, 'CRM é obrigatório'),
  ufCRM: z
    .string()
    .trim()
    .optional()
    .refine((value) => {
      value = value?.replace(/\[A-Z]/g, '');
      return !value ? true : value.length === 2;
    }),
  cep: z
    .string()
    .trim()
    .optional()
    .refine((value) => {
      value = value?.replace(/\D/g, '');
      return !value ? true : value.length === 8;
    }, 'CEP inválido'),
  street: z
    .string()
    .trim()
    .optional()
    .refine(
      (i) => (!i ? true : i.length >= 3),
      'Rua deve conter no mínimo 3 caracteres',
    ),
  number: z.string().trim().optional(),
  neighborhood: z
    .string()
    .trim()
    .optional()
    .refine(
      (i) => (!i ? true : i.length >= 3),
      'Bairro deve conter no mínimo 3 caracteres',
    ),
  complement: z
    .string()
    .trim()
    .optional()
    .refine(
      (i) => (!i ? true : i.length >= 3),
      'Complemento deve conter no mínimo 3 caracteres',
    ),
  uf: z
    .string()
    .trim()
    .optional()
    .refine((value) => {
      value = value?.replace(/\[A-Z]/g, '');
      return !value ? true : value.length === 2;
    }),
  city: z
    .string()
    .trim()
    .optional()
    .refine(
      (i) => (!i ? true : i.length >= 3),
      'Cidade deve conter no mínimo 3 caracteres',
    ),
  bonus: z
    .string()
    .trim()
    .optional()
    .refine((value) => {
      console.log(value);
      return !value ? true : !isNaN(+value);
    }, 'Bônus inválido'),
  admissionDate: z
    .string()
    .trim()
    .refine((value) => {
      return !value ? true : dayjs(value).isValid();
    }, 'Data de admissão inválida'),
  code: z.string().trim().optional(),
  details: z.string().trim().optional().optional(),
  hospitalId: z.string().uuid(),
  medicalGroupId: z.string().uuid(),
});

export type ProfessionalFormValues = z.infer<
  typeof professionalValidationSchema
>;
