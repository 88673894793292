/* eslint-disable @typescript-eslint/no-extraneous-class */
import api from '..';
import { type CreateAlertInput } from './inputs/create-alert-input';
import { type ListAlertsByUserOutput } from './outputs/list-alerts-by-user-output';

export default class AlertsApi {
  static async create(input: CreateAlertInput) {
    return await api.post('/alert', input);
  }

  static async getAll() {
    return await api.get<ListAlertsByUserOutput>('/alert');
  }

  static async markAllAsRead() {
    return await api.post('/alert/mark-all-as-read');
  }
}
