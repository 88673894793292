/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { type IUser } from '../models/IUser';
import { AuthService } from '../api/auth';
import { type IMedicalGroup } from '../models/IMedicalGroup';
import { MedicalGroupService } from '../api/medicalGroup';
import { type IAdmin } from '../models/IAdmin';
import { HospitalService } from '../api/hospital';
import { type IHospital } from '../models/IHospital';
import userStore from '../stores/userStore';

interface IAuthProviderProps {
  children: React.ReactNode;
}

export interface LoginResponse {
  user: IUser | IAdmin;
  token: string;
}

interface IAuthContextProps {
  login: (email: string, password: string) => Promise<LoginResponse>;
  loginAdm: (email: string, password: string) => Promise<LoginResponse>;
  logout: () => void;
  user: IUser | IAdmin | null;
  setUser: (user: IUser | IAdmin) => void;
  selectedMedicalGroup: IMedicalGroup | null;
  setSelectedMedicalGroup: (medicalGroup: IMedicalGroup | null) => void;
  medicalGroups: IMedicalGroup[] | null;
  setMedicalGroups: (medicalGroups: IMedicalGroup[]) => void;
  fetchMedicalGroups: () => Promise<IMedicalGroup | null>;
  fetchHospitals: () => Promise<void>;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  getCurrentUser: () => Promise<IAdmin | IUser>;
  getHospital: () => Promise<string>;
  medicalGroupsLoaded: boolean | undefined;
  selectedHospital: IHospital | null;
  hospitals: IHospital[];
  hospitalsLoaded: boolean | undefined;
  setSelectedHospital: (hospital: IHospital | null) => void;
}

export const AuthContext = createContext({} as IAuthContextProps);

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<IUser | IAdmin | null>(
    localStorage.getItem('user')
      ? (JSON.parse(localStorage.getItem('user') ?? '') as IUser | IAdmin)
      : null,
  );
  const [selectedMedicalGroup, setSelectedMedicalGroup] =
    useState<IMedicalGroup | null>(null);
  const [medicalGroups, setMedicalGroups] = useState<IMedicalGroup[]>([]);
  const [hospitals, setHospitals] = useState<IHospital[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<IHospital | null>(
    null,
  );
  const [hospitalsLoaded, setHospitalsLoaded] = useState<boolean | undefined>();
  const [isManager, setIsManager] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [medicalGroupsLoaded, setMedicalGroupsLoaded] = useState<
    boolean | undefined
  >();
  const { setUser: storeSetUser } = userStore();

  const login = async (
    email: string,
    password: string,
  ): Promise<LoginResponse> => {
    const auth = await AuthService.login(email, password);

    localStorage.setItem('accessToken', auth.token);
    storeSetUser(auth.user);
    setUser(auth.user);

    setIsManager('isSuperAdmin' in auth.user);

    // @ts-expect-error
    setIsSuperAdmin('isSuperAdmin' in auth.user && auth.user.isSuperAdmin);

    return { user: auth.user, token: auth.token };
  };

  const loginAdm = async (
    email: string,
    password: string,
  ): Promise<LoginResponse> => {
    const auth = await AuthService.loginAdm(email, password);

    localStorage.setItem('accessToken', auth.token);
    storeSetUser(auth.user);
    setUser(auth.user);

    setIsManager('isSuperAdmin' in auth.user);

    // @ts-expect-error
    setIsSuperAdmin('isSuperAdmin' in auth.user && auth.user.isSuperAdmin);

    return { user: auth.user, token: auth.token };
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    storeSetUser(null);
    setUser(null);
    window.location.replace('/');
  };

  const getCurrentUser = async () => {
    const user = await AuthService.getCurrentUser();
    setIsManager('isSuperAdmin' in user && !user.isSuperAdmin);
    // @ts-expect-error
    setIsSuperAdmin('isSuperAdmin' in user && user.isSuperAdmin);

    if (!('isSuperAdmin' in user)) {
      setIsManager(false);
    }

    setUser(user);
    return user;
  };

  const getHospital = async () => {
    return selectedHospital?.guid || '';
  };

  const fetchMedicalGroups = useCallback(async () => {
    if (!selectedHospital) {
      return null;
    }

    const ret = await MedicalGroupService.getGroupsIManage(
      selectedHospital.guid,
    );
    setMedicalGroups(ret);
    setSelectedMedicalGroup(ret.at(0) || null);
    setMedicalGroupsLoaded(true);
    return ret.at(0) || null;
  }, [selectedHospital]);

  useEffect(() => {
    if (selectedHospital) {
      fetchMedicalGroups();
    }
  }, [fetchMedicalGroups, selectedHospital]);

  const fetchHospitals = async () => {
    const ret = await HospitalService.getAllHospitalsManagedByUser();

    setHospitals(ret);
    setSelectedHospital(ret?.[0] || null);
    setHospitalsLoaded(true);
  };

  useEffect(() => {
    console.log('selectedMedicalGroup: ', selectedMedicalGroup?.guid);
  }, [selectedMedicalGroup]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
        medicalGroups,
        setMedicalGroups,
        selectedMedicalGroup,
        setSelectedMedicalGroup,
        fetchMedicalGroups,
        fetchHospitals,
        selectedHospital,
        hospitals,
        isAdmin: isManager,
        isSuperAdmin,
        setSelectedHospital,
        getCurrentUser,
        getHospital,
        medicalGroupsLoaded,
        loginAdm,
        hospitalsLoaded,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
