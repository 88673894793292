import { z } from 'zod';

export enum HospitalFields {
  Id = 'id',
  Name = 'name',
  MaxUsers = 'maxUsers',
  Timezone = 'timezone',
}

export const hospitalInitialValues: HospitalFormValues = {
  id: undefined as string | undefined,
  name: '',
  maxUsers: '',
  timezone: '',
};

export const hospitalValidationSchema = z.object({
  id: z.string().uuid().optional(),
  name: z
    .string({
      required_error: 'Obrigatório',
    })
    .min(1, 'Muito curto')
    .max(50, 'Muito longo'),
  maxUsers: z
    .string({
      required_error: 'Obrigatório',
    })
    .min(1, 'Obrigatório'),
  timezone: z
    .string({
      required_error: 'Obrigatório',
    })
    .min(1, 'Obrigatório'),
});

export type HospitalFormValues = z.infer<typeof hospitalValidationSchema>;
