import moon from '../assets/moon.svg';
import sun from '../assets/sun.svg';
import magic from '../assets/magic.svg';
import diamond from '../assets/diamond.svg';
import userAdd from '../assets/user-add.svg';

const getIconUrl = (x: number) => {
  switch (x) {
    case 1:
      return sun;
    case 2:
      return moon;
    case 3:
      return magic;
    case 4:
      return userAdd;
    case 5:
      return diamond;
  }
};

export default getIconUrl;
