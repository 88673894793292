/* eslint-disable react-hooks/exhaustive-deps */
import { useState, type InputHTMLAttributes, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Input, Label, Wrapper } from './styles';

export default function PhoneField({
  label,
  required,
  ...props
}: PhoneFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const [value, setValue] = useState(methods.getValues(props.name) ?? '');

  useEffect(() => {
    if (!methods.getValues(props.name)) {
      setValue('');
    }
  }, [methods.getValues(props.name)]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setValue(value);
    methods.setValue(props.name, value);
  };

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <Input
        mask="(99) 99999-9999"
        type="tel"
        maskChar={null}
        {...props}
        {...methods.register(props.name)}
        onChange={onChange}
        value={value}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface PhoneFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}
