const text = {
  weekDays: {
    sun: {
      'pt-Br': 'Domingo',
      'en-Us': 'Sunday',
    },
    mon: {
      'pt-Br': 'Segunda',
      'en-Us': 'Monday',
    },
    tues: {
      'pt-Br': 'Terça',
      'en-Us': 'Tuesday',
    },
    wed: {
      'pt-Br': 'Quarta',
      'en-Us': 'Wednesday',
    },
    thurs: {
      'pt-Br': 'Quinta',
      'en-Us': 'Thursday',
    },
    fri: {
      'pt-Br': 'Sexta',
      'en-Us': 'Friday',
    },
    sat: {
      'pt-Br': 'Sábado',
      'en-Us': 'Saturday',
    },
  },
  announcement: {
    'pt-Br': 'Anúncio',
    'en-Us': 'Announcement',
  },
};

export default text;
