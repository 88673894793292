import api from '..';
import { type IHospital } from '../../models/IHospital';
import { type ITimezone } from '../../models/ITimezone';
import { type IUser } from '../../models/IUser';

export class HospitalService {
  static async getAllProfessionals(hospitalGuid: string): Promise<IUser[]> {
    const { data } = await api.get(`/hospitals/${hospitalGuid}/users`);
    return data;
  }

  static async getAllHospitals(): Promise<IHospital[]> {
    const { data } = await api.get(`/hospitals`);
    return data;
  }

  static async getAllHospitalsManagedByUser(): Promise<IHospital[]> {
    const { data } = await api.get(`/hospitals/manage`);
    return data;
  }

  static async getTimezonesList(): Promise<ITimezone[]> {
    const { data } = await api.get(`/utils/timezones`);
    return data;
  }

  static async createOrUpdateHospital({
    guid,
    hospitalName,
    maxNumberOfUsers,
    isActive,
    adminName,
    adminEmail,
    timezone,
  }: {
    guid?: string;
    hospitalName?: string;
    maxNumberOfUsers?: number;
    isActive?: boolean;
    adminName?: string;
    adminEmail?: string;
    timezone?: string;
  }): Promise<IHospital> {
    const { data } = await api.post('/hospitals/', {
      guid,
      name: hospitalName,
      maxNumberOfUsers,
      isActive,
      adminEmail,
      adminName,
      timezone,
    });

    return data;
  }

  static async enableDisableHospital({
    guid,
    isActive,
  }: {
    guid?: string;
    isActive?: boolean;
  }): Promise<IHospital> {
    const { data } = await api.put(`/hospitals/${guid}`, {
      isActive,
    });

    return data;
  }

  static async countUsers(guid: string): Promise<number> {
    const { data } = await api.get(`/hospitals/${guid}/countusers`);

    return data;
  }
}
