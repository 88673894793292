import { type ReactElement, type ReactNode } from 'react';
import './style.css';

interface ContainerProps {
  children: ReactNode | ReactElement;
}

function Container({ children }: ContainerProps) {
  return <div className="container">{children}</div>;
}

export default Container;
