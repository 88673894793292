import { useEffect, useState } from 'react';
import Button from '../../components/button';
import Container from '../../components/container';
import Table from '../../components/table';
import { type IMedicalGroup } from '../../models/IMedicalGroup';
import AddProfessional, { type addFields } from './add';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import addIcon from '../../assets/add.svg';
import './style.css';
import Modal from '../../components/modal';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { MedicalGroupService } from '../../api/medicalGroup';
import searchIcon from '../../assets/search.svg';
import { Pagination, Stack } from '@mui/material';

function Groups() {
  const [groups, setGroups] = useState<IMedicalGroup[]>();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [editData, setEditData] = useState<addFields | undefined>();
  const [actualPage, setActualPage] = useState(1);
  const [search, setSearch] = useState<string | undefined>();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [groupToRemove, setGroupToRemove] = useState<
    IMedicalGroup | undefined
  >();
  const {
    selectedMedicalGroup,
    fetchMedicalGroups,
    medicalGroups,
    medicalGroupsLoaded,
  } = useAuth();

  const fetchGroups = async () => {
    setGroups(medicalGroups ?? []);
  };

  useEffect(() => {
    setGroups(medicalGroups ?? []);
  }, [medicalGroups]);

  const formatDate = (x: string | Date) => {
    const date = new Date(x);

    return `${date.getDate()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
  };
  const removeGroup = async () => {
    if (groupToRemove && selectedMedicalGroup) {
      await MedicalGroupService.delete(groupToRemove.guid);
      onSuccess();
      toast.success('Grupo removido com sucesso.');
    }
  };

  const handleEditButton = (group: IMedicalGroup) => {
    setEditData({
      guid: group.guid,
      groupName: group.name,
      managers: group.managers,
    });

    setIsAddVisible(true);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    fetchMedicalGroups();
  }, [window.location]);

  useEffect(() => {
    fetchGroups();
  }, [medicalGroupsLoaded]);

  const onSuccess = () => {
    fetchMedicalGroups();
    setIsAddVisible(false);
    setIsDeleteVisible(false);
    fetchGroups();
    setActualPage(1);
  };

  return (
    <>
      <Container>
        {groups && groups.length > 0 ? (
          <>
            <div className="header">
              <div>
                <span>Grupos</span> <br />
                <br />
                <span className="sub-title">
                  {groups?.length}{' '}
                  {groups.length === 1 ? 'registro' : 'registros'}
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  className="styled-input-container input-with-icon"
                  style={{ marginRight: '1rem' }}
                >
                  <input
                    placeholder="Pesquisar grupo"
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                      setActualPage(1);
                    }}
                  />
                  <img className="input-icon" src={searchIcon} alt="" />
                </div>
                <div style={{ width: '12rem' }}>
                  <Button
                    onClick={() => {
                      setEditData(undefined);
                      setIsAddVisible(true);
                    }}
                    disabled={false}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: '1rem' }}
                    >
                      <path
                        d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
                        fill="white"
                      />
                    </svg>
                    Adicionar Grupo
                  </Button>
                </div>
              </div>
            </div>

            <Table>
              <thead>
                <tr>
                  <th>NOME</th>
                  <th>PROFISSIONAIS</th>
                  <th>GESTOR</th>
                  <th>CRIADO EM</th>
                  <th style={{ width: '7rem' }}></th>
                </tr>
              </thead>
              <tbody>
                {groups
                  ?.filter((p) =>
                    search
                      ? p.name.toLowerCase().search(search.toLowerCase()) >= 0
                      : true,
                  )
                  .slice((actualPage - 1) * 10, actualPage * 10)
                  .map((group, index) => (
                    <tr key={index}>
                      <td>{group.name}</td>
                      <td>{group.countUsers ?? 0}</td>
                      <td>
                        {group.managers
                          ?.map((manager) => manager.name)
                          .join(', ')}
                      </td>
                      <td>{formatDate(group.createdAt)}</td>
                      <td className="actions">
                        <button
                          className="edit-button"
                          onClick={() => {
                            handleEditButton(group);
                          }}
                        >
                          <img src={editIcon} alt="" />
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => {
                            setGroupToRemove(group);
                            setIsDeleteVisible(true);
                          }}
                        >
                          <img src={trashIcon} alt="" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Stack alignItems="end">
              <Pagination
                style={{ float: 'right', marginTop: '1rem' }}
                count={Math.ceil(
                  (groups?.filter((p) =>
                    search
                      ? p.name.toLowerCase().search(search.toLowerCase()) === 0
                      : true,
                  ).length || 10) / 10,
                )}
                shape="rounded"
                color="primary"
                onChange={(e, p) => {
                  setActualPage(p);
                }}
              />
            </Stack>
          </>
        ) : (
          <>
            <div className="header">
              <div>
                <span>Grupos</span> <br />
                <br />
                <span className="sub-title">
                  {groups?.length ?? '0'} registros
                </span>
              </div>
            </div>
            <div className="default-message">
              <h2>Solicitação de criação de grupos pendente.</h2>
              <div
                style={{
                  width: '15rem',
                  textAlign: 'center',
                  margin: 'auto',
                  marginTop: '2rem',
                }}
              >
                <Button
                  disabled={false}
                  color="primary"
                  onClick={() => {
                    setIsAddVisible(true);
                  }}
                >
                  <img src={addIcon} alt="" style={{ marginRight: '1rem' }} />
                  Criar grupo
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
      {isAddVisible && (
        <Modal
          setIsVisible={setIsAddVisible}
          isVisible={isAddVisible}
          size="xmd"
        >
          <AddProfessional
            setIsVisible={setIsAddVisible}
            editData={editData}
            setEditData={setEditData}
            onSuccess={onSuccess}
          />
        </Modal>
      )}
      <Modal
        isVisible={isDeleteVisible}
        setIsVisible={setIsDeleteVisible}
        size="md"
      >
        <h2>Excluir Grupo</h2>
        <h3 className="sub-title">
          Tem certeza que deseja excluir este grupo? Ele não aparecerá mais para
          nenhum profissional.
        </h3>
        <div className="buttons-row" style={{ paddingTop: '1rem' }}>
          <Button disabled={false} color="destructive">
            Cancelar
          </Button>
          <Button
            disabled={false}
            color="primary"
            onClick={() => {
              removeGroup();
            }}
          >
            Excluir
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Groups;
