import { z } from 'zod';

export enum AdminFields {
  Name = 'name',
  Email = 'email',
  Hospitals = 'hospitals',
}

export const adminInitialValues = {
  name: '',
  email: '',
  hospitals: [] as string[],
};

export const adminValidationSchema = z.object({
  name: z
    .string({
      required_error: 'Obrigatório',
    })
    .trim()
    .min(2, 'Nome muito curto')
    .max(100, 'Nome muito longo'),
  email: z
    .string({
      required_error: 'Obrigatório',
    })
    .trim()
    .email('Email inválido'),
  hospitals: z.array(z.string().uuid('Invalid hospital id')),
});

export type AdminFormValues = z.infer<typeof adminValidationSchema>;
