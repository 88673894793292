import { useForm } from 'react-hook-form';
import {
  type ProfessionalFormValues,
  professionalInitialValues,
  professionalValidationSchema,
  ProfessionalFields,
} from '../forms/professionalForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserService } from '../../../api/user';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CepService } from '../../../api/cep';

export default function useProfessionals() {
  const methods = useForm({
    defaultValues: professionalInitialValues,
    resolver: zodResolver(professionalValidationSchema),
  });

  const onSubmit = async (data: ProfessionalFormValues) => {
    await UserService.createUser({
      ...data,
      name: data.name,
      admissionDate: data.admissionDate.length ? data.admissionDate : undefined,
      additional:
        data.bonus !== undefined && !isNaN(+data.bonus)
          ? +data.bonus
          : ('' as any),
      district: data.neighborhood,
      hospitalGuid: data.hospitalId,
      medicalGroupGuid: data.medicalGroupId,
      phone1: data.mainPhone,
      phone2: data.secondaryPhone,
      ufCrm: data.ufCRM,
    });
  };

  const fetchCep = async (cep: string) => {
    try {
      const result = await CepService.search(cep);

      console.log(result);

      if (result && !Object.keys(result).includes('erro')) {
        methods.setValue(ProfessionalFields.Street, result.logradouro);
        methods.setValue(ProfessionalFields.Neighborhood, result.bairro);
        methods.setValue(ProfessionalFields.UF, result.uf);
        methods.setValue(ProfessionalFields.City, result.localidade);
      }
    } catch (err) {
      toast.error('CEP não encontrado');
    }
  };

  return { methods, onSubmit, fetchCep };
}
