/* eslint-disable prettier/prettier */
import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-bottom: 0.8rem;
  color: var(--gray700) !important;

  b {
    color: var(--red);
  }
`;

export const Input = styled(TextField)`
  width: 100%;
  font-size: 0.8rem;
  border: 0;
  border-radius: 0.2rem;
  outline: none;

  input {
    flex: 1;
    padding-right: 0;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    padding: 0.1rem 0.2rem !important;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border: solid 1px var(--gray800);
  }
`;

export const Error = styled.small`
  color: var(--red);
  margin-top: 0.2rem;
`;
