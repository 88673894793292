import { type ReactElement, type ReactNode } from 'react';
import './style.css';
import cross from '../../assets/cross.svg';
import hospitalImg from '../../assets/login.png';

interface ModalProps {
  children: ReactNode | ReactElement;
  isVisible: boolean;
  setIsVisible: (x: boolean) => void;
  size?: 'xxsm' | 'xsm' | 'sm' | 'md' | 'xmd' | 'lg' | 'xlg';
  imageHeader?: boolean;
  onClose?: () => void;
}

function Modal({
  children,
  isVisible,
  setIsVisible,
  size,
  imageHeader,
  onClose,
}: ModalProps) {
  return (
    <div
      className={`modal-background ${isVisible ? 'visible' : 'notVisible'} ${size || 'lg'}`}
    >
      <div className={`modal`}>
        <div
          className={`modal-header ${imageHeader ? 'image-header' : ''}`}
          style={
            imageHeader ? { backgroundImage: 'url(' + hospitalImg + ')' } : {}
          }
        >
          <span
            onClick={() => {
              setIsVisible(false);

              if (onClose) onClose();
            }}
          >
            <img src={cross} alt="" />
          </span>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
