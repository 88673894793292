import { type ReactElement, type ReactNode } from 'react';
import './style.css';

interface TableProps {
  children: ReactNode | ReactElement;
}

function Table({ children }: TableProps) {
  return <table className="styled-table">{children}</table>;
}

export default Table;
