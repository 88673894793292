import {
  type FormHTMLAttributes,
  type ReactElement,
  type ReactNode,
} from 'react';
import './style.css';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode | ReactElement;
}
function Form({ children, ...props }: FormProps) {
  return (
    <form className="styled-form" {...props}>
      {children}
    </form>
  );
}

export default Form;
