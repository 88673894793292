import { type IShift } from '../../../models/IShift';
import './style.css';
import writeXlsxFile from 'write-excel-file';
import {} from 'xlsx-js-style';
import dayjs, { type Dayjs } from 'dayjs';
import { type IMedicalGroup } from '../../../models/IMedicalGroup';

interface exportShiftsProps {
  shifts: IShift[];
  hospitalName: string;
  medicalGroup?: IMedicalGroup | null;
  date: Date;
}

function ExportShiftsConsolidated({
  shifts: shiftsParam,
  hospitalName,
  medicalGroup,
  date,
}: exportShiftsProps) {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
  );

  const header: Array<{
    value: string | number;
    width?: number;
    fontSize?: number;
    align?: 'center' | 'right' | 'left';
    color?: string;
    fontWeight?: 'bold' | undefined;
    backgroundColor?: string;
  }> = [
    {
      value: 'Profissional',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'CRM',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Nº Plantões',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Nº Horas',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Valor',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
  ];
  const formatDate = (x: Date) => {
    return (
      ('0' + x.getDate()).slice(-2) +
      '/' +
      ('0' + (x.getMonth() + 1)).slice(-2) +
      '/' +
      x.getFullYear()
    );
  };

  const formatHour = (x: number) => {
    return (
      Math.floor(x) +
      'h' +
      ((x - Math.floor(x)) * 0.6).toFixed(2).toString().slice(2, 4)
    );
  };

  const shifts = shiftsParam.filter(
    (shift) => shift.medicalGroupGuid === medicalGroup?.guid,
  );

  const professionals = shifts.map((shift) => shift.responsibleUser);
  const cleanProfessionals = professionals
    .filter(
      (item, index) =>
        professionals.findIndex((p) => p?.guid === item?.guid) === index,
    )
    .sort((a, b) => {
      const nomeA = a?.name.toUpperCase() ?? 'A';
      const nomeB = b?.name.toUpperCase() ?? 'B';

      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }
      return 0;
    });

  const formatHourAndMinutes = (x: Dayjs) => {
    return x.get('h') + x.get('m') / 60;
  };

  const generateXls = async () => {
    const data: Array<
      Array<{
        value: string | number;
        width?: number;
        fontSize?: number;
        align?: 'center' | 'right' | 'left';
        color?: string;
        fontWeight?: 'bold' | undefined;
        backgroundColor?: string;
        topBorderColor?: string;
        bottomBorderColor?: string;
      }>
    > = [
      [
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: 'Emitido em ' + formatDate(new Date()),
          align: 'right',
        },
      ],
      [],
      [
        {
          value: 'Agendoctor | Financeiro',
          color: '#009FFA',
          fontSize: 20,
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: `${formatDate(startDate)} à ${formatDate(endDate)}`,
        },
      ],
      [
        {
          value: medicalGroup?.name ?? 'Grupo medico não informado',
          fontWeight: 'bold',
        },
      ],
      header,
    ];

    let hospitalTotalHours = 0;
    let hospitalTotalValue = 0;

    cleanProfessionals
      .filter((professional) => {
        const professionalShifts = shifts
          .filter((shift) => shift.responsibleUserGuid === professional?.guid)
          .filter((shift) => {
            return shift.medicalGroupGuid === medicalGroup?.guid;
          });
        return (
          professionalShifts.filter(
            (shift) =>
              new Date(shift.startDate) >= startDate &&
              new Date(shift.startDate) <= endDate,
          ).length > 0
        );
      })
      .forEach((professional, i) => {
        const professionalShifts = shifts.filter(
          (shift) => shift.responsibleUserGuid === professional?.guid,
        );
        let totalValue: number = 0;
        let totalHours: number = 0;
        let totalShifts: number = 0;
        professionalShifts
          .filter(
            (shift) =>
              new Date(shift.startDate) >= startDate &&
              new Date(shift.startDate) <= endDate,
          )
          .forEach((shift, i) => {
            totalValue += parseFloat('' + shift.value);
            const shiftStart = dayjs(
              new Date(shift.startDate).getHours() +
                ':' +
                new Date(shift.startDate).getMinutes(),
              'hh:mm',
            );
            const shiftEnd = dayjs(
              new Date(shift.endDate).getHours() +
                ':' +
                new Date(shift.endDate).getMinutes(),
              'hh:mm',
            );
            const shiftHours =
              shiftStart > shiftEnd
                ? 24 -
                  formatHourAndMinutes(shiftStart) +
                  formatHourAndMinutes(shiftEnd)
                : formatHourAndMinutes(shiftEnd) -
                  formatHourAndMinutes(shiftStart);

            totalHours += shiftHours || 0;
            totalShifts++;
          });

        hospitalTotalValue += totalValue;
        hospitalTotalHours += totalHours;
        data.push([
          {
            value: professional?.name || '',
            fontWeight: 'bold',
            backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
            align: 'left',
          },
          {
            value: professional?.crm || '',
            backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
          },
          {
            value: totalShifts,
            backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
            align: 'center',
          },
          {
            value: formatHour(totalHours),
            backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
            align: 'center',
          },
          {
            value: new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalValue),
            backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
            align: 'right',
          },
        ]);
      });
    data.push([
      {
        value: 'Total',
        fontWeight: 'bold',
        align: 'left',
        backgroundColor: '#9EDCFF',
      },
      {
        value: '',
        fontWeight: 'bold',
        align: 'center',
        backgroundColor: '#9EDCFF',
      },
      {
        value: '',
        fontWeight: 'bold',
        align: 'center',
        backgroundColor: '#9EDCFF',
      },
      {
        value: formatHour(hospitalTotalHours),
        fontWeight: 'bold',
        align: 'center',
        backgroundColor: '#9EDCFF',
      },
      {
        value: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(hospitalTotalValue),
        fontWeight: 'bold',
        align: 'right',
        backgroundColor: '#9EDCFF',
      },
    ]);
    data.push();
    await writeXlsxFile(data, {
      fileName:
        date.toLocaleString('default', { month: 'long' }) +
        `-${
          medicalGroup?.name.toLowerCase().trim().replace(' ', '-') ??
          'sem-grupo-medico'
        }` +
        '-consolidado.xlsx',
      columns: [
        { width: 30 },
        { width: 16 },
        { width: 14 },
        { width: 14 },
        { width: 22 },
      ],
    });
  };

  return (
    <>
      <span
        onClick={async () => {
          await generateXls();
        }}
      >
        Gerar Relatório Consolidado
      </span>
    </>
  );
}

export default ExportShiftsConsolidated;
