/* eslint-disable @typescript-eslint/dot-notation */
import { z } from 'zod';

export enum TurnoFields {
  WorkTime = 'workTime',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Icon = 'icon',
  Unique = 'unique',
  UniqueValue = 'uniqueValue',
  MondayValue = 'mondayValue',
  TuesdayValue = 'tuesdayValue',
  WednesdayValue = 'wednesdayValue',
  ThursdayValue = 'thursdayValue',
  FridayValue = 'fridayValue',
  SaturdayValue = 'saturdayValue',
  SundayValue = 'sundayValue',
  NumberOfShifts = 'numberOfShifts',
  TurnoId = 'turnoId',
  MedicalGroupId = 'medicalGroupId',
}

export const initialTurnoValues = {
  workTime: '',
  startDate: '',
  endDate: '',
  icon: 1,
  unique: false,
  mondayValue: '',
  tuesdayValue: '',
  wednesdayValue: '',
  thursdayValue: '',
  fridayValue: '',
  saturdayValue: '',
  sundayValue: '',
  numberOfShifts: '0',
  turnoId: '',
  medicalGroupId: '',
};

export const turnoValidationSchema = z
  .object({
    workTime: z
      .string({
        required_error: 'Obrigatório',
      })
      .trim()
      .min(1, 'Nome muito curto')
      .max(50, 'Nome muito longo'),
    startDate: z
      .string({
        required_error: 'Obrigatório',
      })
      .trim()
      .min(5, 'Hora inválida')
      .refine((i) => {
        const today = new Date().toISOString().split('T')[0];
        const date = new Date(`${today}T${i}:00.000Z`);

        return !isNaN(date.getTime());
      }, 'Hora inválida'),
    endDate: z
      .string({
        required_error: 'Obrigatório',
      })
      .trim()
      .refine((i) => {
        const today = new Date().toISOString().split('T')[0];
        const date = new Date(`${today}T${i}:00.000Z`);

        return !isNaN(date.getTime());
      }, 'Hora inválida'),
    icon: z.number(),
    unique: z.boolean(),
    uniqueValue: z.string().trim().optional(),
    mondayValue: z.string().trim().optional(),
    tuesdayValue: z.string().trim().optional(),
    wednesdayValue: z.string().trim().optional(),
    thursdayValue: z.string().trim().optional(),
    fridayValue: z.string().trim().optional(),
    saturdayValue: z.string().trim().optional(),
    sundayValue: z.string().trim().optional(),
    numberOfShifts: z.string().trim(),
    turnoId: z.string().optional(),
    medicalGroupId: z.string(),
  })
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.mondayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['mondayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.tuesdayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['tuesdayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.wednesdayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['wednesdayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.thursdayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['thursdayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.fridayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['fridayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.saturdayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['saturdayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (isUnique) return true;

      return i.sundayValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['sundayValue'],
    },
  )
  .refine(
    (i) => {
      const isUnique = i.unique;

      if (!isUnique) return true;

      return i.uniqueValue?.length;
    },
    {
      message: 'Obrigatório',
      path: ['uniqueValue'],
    },
  );

export type TurnoValues = z.infer<typeof turnoValidationSchema>;
