import { type ReactElement, type ReactNode } from 'react';
import userStore, { UserRoles } from '../../stores/userStore';
import AppRoutes from '../../enums/appRoutes';

export const PublicRoute = ({ children }: PublicRouteProps) => {
  const { user } = userStore();

  const forbidenRoutes = [
    AppRoutes.Home,
    AppRoutes.Login,
    AppRoutes.LoginAdmin,
  ];

  if (user && forbidenRoutes.includes(window.location.pathname as AppRoutes)) {
    window.location.replace(
      user.role === UserRoles.SuperAdmin
        ? AppRoutes.Hospitals
        : AppRoutes.Shifts,
    );
    return;
  }

  return children;
};

interface PublicRouteProps {
  children: ReactNode | ReactElement;
}
