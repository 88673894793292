import api from '..';
import { type IShiftDefinition } from '../../models/IShiftDefinition';
import { type ICreateShiftDefinition } from '../types';

export class ShiftDefinitionService {
  static async deleteShiftDefinition(
    shiftDefinitionGuid: string,
  ): Promise<IShiftDefinition> {
    const { data } = await api.post(
      `/hospitals/shiftdefinition/${shiftDefinitionGuid}/remove`,
    );

    return data;
  }

  static async listShiftDefinition(
    medicalGroupGuid: string,
  ): Promise<IShiftDefinition[]> {
    const { data } = await api.get(
      `/hospitals/${medicalGroupGuid}/shiftdefinition`,
    );
    return data;
  }

  static async createShiftDefinition(
    params: ICreateShiftDefinition,
    medicalGroupGuid: string,
  ) {
    const { data } = await api.post(
      `/hospitals/${medicalGroupGuid}/shiftdefinition`,
      params,
    );

    return data;
  }

  static async updateShiftDefinition(
    params: ICreateShiftDefinition,
    guid: string,
  ) {
    const { data } = await api.put(
      `/hospitals/shiftdefinition/${guid}`,
      params,
    );

    return data;
  }
}
