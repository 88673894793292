/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Input, Label, Wrapper } from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { type Dayjs } from 'dayjs';

export default function DateField({
  label,
  required,
  ...props
}: DateFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const [value, setValue] = useState<Dayjs | null>(
    methods.getValues(props.name)
      ? dayjs(methods.getValues(props.name) as string)
      : null,
  );

  useEffect(() => {
    if (methods.getValues(props.name) === null) {
      setValue(null);
    }
  }, [methods.getValues(props.name)]);

  const onChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    methods.setValue(props.name, newValue?.toDate() ?? null);
  };

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <DatePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <Input {...methods.register(props.name)} {...props} {...params} />
        )}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface DateFieldProps {
  name: string;
  label?: string;
  required?: boolean;
}
