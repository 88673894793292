import { AlertTriangle } from 'lucide-react';
import {
  ActionArea,
  AlertRow,
  Content,
  Description,
  IconWrapper,
  Modal,
  ModalBody,
  Title,
  Wrapper,
} from './styles';
import Button from '../button';
import { useEffect, useState } from 'react';
import AlertsApi from '../../api/alerts';

export default function AlertModal() {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const fetchAlerts = async () => {
    try {
      const { data } = await AlertsApi.getAll();

      setAlerts(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const onAccept = async () => {
    await AlertsApi.markAllAsRead();
    setAlerts([]);
  };

  return alerts.length ? (
    <Wrapper>
      <Modal>
        <ModalBody>
          {alerts.map((alert, index) => (
            <AlertRow key={index}>
              <IconWrapper>
                <AlertTriangle color="red" size={20} />
              </IconWrapper>
              <Content>
                <Title>{alert.title}</Title>
                <Description>{alert.message}</Description>
              </Content>
            </AlertRow>
          ))}
        </ModalBody>
        <ActionArea>
          <Button disabled={false} onClick={onAccept}>
            Concluir
          </Button>
        </ActionArea>
      </Modal>
    </Wrapper>
  ) : null;
}

interface Alert {
  title: string;
  message: string;
  id: string;
  createdAt: string;
  updatedAt: string;
}
