/* eslint-disable react-hooks/exhaustive-deps */
import { Error, Input, Label, Wrapper } from './styles';
import { useFormContext } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { type SyntheticEvent } from 'react';

export default function AutocompleteField({
  options,
  label,
  required,
  ...props
}: AutocompleteFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const selected = methods.watch(props.name);

  const onChange = (
    e: SyntheticEvent<Element, Event>,
    value: Option | null,
  ) => {
    methods.setValue(props.name, value?.id);

    if (methods.formState.isSubmitted) methods.trigger(props.name);
  };

  return (
    <Wrapper>
      {label && (
        <Label>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <Autocomplete
        options={options}
        onChange={onChange}
        popupIcon={<ChevronDown />}
        renderInput={(params) => <Input {...props} {...params} />}
        value={{
          id: selected,
          label: options.find((option) => option.id === selected)?.label ?? '',
        }}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface AutocompleteFieldProps {
  name: string;
  label?: string;
  required?: boolean;
  options: Option[];
}

type Option = {
  label: string;
  id: number | string;
};
