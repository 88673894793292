import { type SelectHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Label, Select, Wrapper } from './styles';

export default function SelectField({
  label,
  options,
  ...props
}: SelectFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {props.required && <b>*</b>}
        </Label>
      )}
      <Select {...props} {...methods.register(props.name)}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options: Option[];
}

interface Option {
  value: string;
  label: string;
}
