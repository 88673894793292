import loginImg from '../../assets/login.png';
import Input from '../../components/input';
import './style.css';
import Button from '../../components/button';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../api/user';
import { toast } from 'react-toastify';
import validatePassword from '../../utils/validatePassword';
import { BackButton, ForgotPasswordHeader } from './styles';
import { ArrowLeftIcon } from 'lucide-react';

function LoginAdministrator() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [shouldDisable, setShouldDisable] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const auth = useAuth();

  const sendRecoveryCode = async () => {
    setIsLoading(true);
    try {
      await UserService.sendAdminRecoveryCode(email);
      toast.success('Código enviado para o seu email');
      setPage(3);
    } catch (e) {
      setShouldDisable(true);
    } finally {
      setIsLoading(false);
    }
  };

  const validateRecoveryCode = async () => {
    setIsLoading(true);
    try {
      await UserService.validateAdminRecoveryCode(email, code);
      toast.success('Código validado!');
      setPage(4);
      setShouldDisable(false);
    } catch (e) {
      setShouldDisable(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePassword = async () => {
    if (validatePassword(newPassword)) {
      setIsLoading(true);
      try {
        await UserService.setNewAdminPassword(newPassword, confirmNewPassword);
        toast.success('Senha atualizada com sucesso');
        localStorage.removeItem('accessToken');
        setPage(1);
      } catch (e) {
        setShouldDisable(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error('Senha precisa conter letras e números');
    }
  };

  const handleBackButton = () => {
    if (page > 1) {
      setShouldDisable(false);
      setPage(page - 1);
    }
  };

  const validateEmail = (emailString: string) =>
    String(emailString)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  const disableSubmit = () => !(validateEmail(email) && password);

  const handleLoginSubmit = async () => {
    setIsLoading(true);
    setShowError(false);

    try {
      await auth.loginAdm(email, password);

      if (auth.isSuperAdmin) {
        navigate('/hospitals');
      } else {
        navigate('/shifts');
      }
    } catch (error) {
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      if (auth.isSuperAdmin) {
        navigate('/hospitals');
      } else {
        navigate('/shifts');
      }
    }
  }, [auth.isSuperAdmin, navigate]);

  return (
    <div className="login">
      <div
        className="login-background-div"
        style={{ backgroundImage: `url(${loginImg})` }}
      ></div>
      <div className="login-form">
        <div className="login-form-container">
          {(() => {
            switch (page) {
              case 1:
                return (
                  <>
                    <div id="adm-logo-container">
                      <h1 id="login-logo-adm">Agendoctor</h1>

                      <div id="adm-badge-logo">
                        <span
                          style={{
                            fontSize: 26,
                            color: '#3C9AFF7F',
                          }}
                        >
                          ADM
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        position: 'fixed',
                        bottom: '10px',
                        right: '20px',
                        padding: '20px',
                      }}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      <span className="button" style={{ fontSize: '14px' }}>
                        Entrar como gestor
                      </span>
                    </div>

                    <form>
                      <Input
                        label="E-mail"
                        placeholder="seu@email.com"
                        name="email"
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value.replaceAll(' ', ''));
                        }}
                      />
                      <Input
                        label="Senha"
                        placeholder="Senha"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value.replaceAll(' ', ''));
                        }}
                      />
                      {showError && (
                        <p className="error-msg">Email ou senha incorretos</p>
                      )}
                      <p
                        className="button"
                        onClick={() => {
                          setPage(2);
                        }}
                      >
                        Esqueci minha senha
                      </p>
                      <Button
                        disabled={disableSubmit() || isLoading}
                        onClick={handleLoginSubmit}
                      >
                        Entrar
                      </Button>
                    </form>
                  </>
                );
              case 2:
                return (
                  <>
                    <ForgotPasswordHeader>
                      <BackButton onClick={handleBackButton}>
                        <ArrowLeftIcon />
                      </BackButton>
                      <h1>Esqueci minha senha</h1>
                    </ForgotPasswordHeader>
                    <p>
                      Digite o e-mail utilizado no cadastro para enviarmos um
                      código de recuperação.
                    </p>
                    <Input
                      label="E-mail"
                      placeholder="seu@email.com"
                      name="email"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setShouldDisable(false);
                        setEmail(e.target.value.replaceAll(' ', ''));
                      }}
                    />
                    <Button
                      disabled={!email.length || isLoading || shouldDisable}
                      onClick={() => {
                        sendRecoveryCode();
                      }}
                    >
                      Enviar
                    </Button>
                  </>
                );
              case 3:
                return (
                  <>
                    <h1>Digite o código</h1>
                    <p>Não recebeu? Aguarde um momento e envie novamente.</p>
                    <Input
                      label=""
                      placeholder="0000"
                      name="code"
                      type="text"
                      value={code}
                      onChange={(e) => {
                        setShouldDisable(false);
                        setCode(e.target.value.replaceAll(' ', ''));
                      }}
                    />
                    <p
                      className="resend-code"
                      onClick={() => {
                        sendRecoveryCode();
                      }}
                    >
                      Reenviar código.
                    </p>
                    <Button
                      disabled={!code || isLoading || shouldDisable}
                      onClick={() => {
                        validateRecoveryCode();
                      }}
                    >
                      Salvar
                    </Button>
                  </>
                );
              case 4:
                return (
                  <>
                    <h1>Nova senha</h1>
                    <p>Sua senha deve ser diferente da senha anterior</p>
                    <Input
                      label="Senha"
                      placeholder="Senha"
                      name="password"
                      type="password"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value.replaceAll(' ', ''));
                      }}
                    />

                    <Input
                      label="Confirmar senha"
                      placeholder="Senha"
                      name="confirmPassword"
                      type="password"
                      onChange={(e) => {
                        setConfirmNewPassword(
                          e.target.value.replaceAll(' ', ''),
                        );
                      }}
                      value={confirmNewPassword}
                    />
                    <p className="password-text">
                      Sua senha deve conter: No mínimo 8 caracteres, letras e
                      números
                    </p>
                    <Button
                      disabled={
                        !(
                          confirmNewPassword &&
                          newPassword &&
                          confirmNewPassword === newPassword
                        ) || isLoading
                      }
                      onClick={async () => {
                        await handleUpdatePassword();
                      }}
                    >
                      Salvar
                    </Button>
                  </>
                );
            }
          })()}

          {/* <h1>Cadastre-se</h1>
                    <p>Dados pessoais</p>
                    <Input label='Nome' placeholder='seu@email.com' name='name'/>
                    <Input label='E-mail' placeholder='seu@email.com' name='email' type='email'/>
                    <Input label='Telefone' placeholder='(__) _____-____' name='phone' type='phone'/>
                    <p>Crie sua senha</p>
                    <Input label='Senha' placeholder='Senha' name='password' type='password'/>
                    <Input label='Confirmar senha' placeholder='Senha' name='confirmPassword' type='password'/>
                    <p className='password-text'>Sua senha deve conter: No mínimo 8 caracteres, letras e números</p>
                    <Button disabled={true} text='Salvar' /> */}
        </div>
      </div>
    </div>
  );
}

export default LoginAdministrator;
