import api from '..';
import { type IGetAllParams, type IPaginationResponse } from '../types';
import { type INotification } from '../../models/INotification';

export class NotificationService {
  static async getAll({
    page,
    hospitalGuid,
  }: IGetAllParams & {
    hospitalGuid: string;
  }): Promise<IPaginationResponse<INotification>> {
    const { data } = await api.get(
      `/notifications?p=${page}&l=12&orderBy=sendDate&orderMode=desc&hospitalGuid=${hospitalGuid}`,
    );

    return data;
  }
}
