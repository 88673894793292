import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { type IAdmin } from '../models/IAdmin';
import { type IUser } from '../models/IUser';

const userStore = create(
  persist<UserStore>(
    (set) => ({
      user: null,
      setUser: (user: IUser | IAdmin | null) => {
        if (!user) {
          set({ user: null });
          return;
        }

        set({
          user: {
            guid: user.guid,
            name: user.name,
            email: user.email,
            role: getRole(user),
          },
        });
      },
    }),
    {
      name: '@agendoctor-user',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

const getRole = (user: IUser | IAdmin): UserRoles => {
  if (typeof user.isSuperAdmin === 'boolean' && user.isSuperAdmin)
    return UserRoles.SuperAdmin;
  else if (typeof user.isSuperAdmin === 'boolean' && !user.isSuperAdmin)
    return UserRoles.Admin;
  else return UserRoles.Manager;
};

type UserStore = {
  user: User | null;
  setUser: (user: IUser | IAdmin | null) => void;
};

type User = {
  guid: string;
  name: string;
  email: string;
  role: UserRoles;
};

export enum UserRoles {
  Manager = 'manager',
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
}

export default userStore;
