/* eslint-disable react-hooks/exhaustive-deps */
import { type DetailedHTMLProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Input, Label, Wrapper } from './styles';

export default function TextAreaField({
  label,
  required,
  ...props
}: TextAreaFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const value = methods.watch(props.name);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    methods.setValue(props.name, e.target.value);

    if (methods.formState.isSubmitted) methods.trigger(props.name);
  };

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <Input
        {...props}
        {...methods.register(props.name)}
        value={value}
        onChange={onChange}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface TextAreaFieldProps
  extends DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  label?: string;
}
