import { type IShift } from '../../../models/IShift';
import './style.css';
import writeXlsxFile from 'write-excel-file';
import {} from 'xlsx-js-style';
import { type IShiftDefinition } from '../../../models/IShiftDefinition';
import { toast } from 'react-toastify';
import {
  startOfMonth,
  endOfMonth,
  endOfDay,
  startOfDay,
  getMonth,
  getDate,
} from 'date-fns';

interface MedicalGroup {
  name: string;
  guid: string;
}

interface exportShiftsProps {
  shifts: IShift[];
  hospitalName: string;
  medicalGroup?: MedicalGroup | null;
  date: Date;
  shiftDefinitions: IShiftDefinition[];
}

interface ShiftDataPerDay {
  day: number;
  weekday: string;
  month: number;
  data: Array<{
    startDate: Date;
    endDate: Date;
    docName: string | undefined;
    crm: string | null | undefined;
    ufCrf: string | null | undefined;
  }>;
}

type DataType = {
  value: string | number;
  width?: number;
  fontSize?: number;
  align?: 'center' | 'right' | 'left';
  color?: string;
  fontWeight?: 'bold' | undefined;
  backgroundColor?: string;
  topBorderColor?: string;
  bottomBorderColor?: string;
  span?: number;
  rowSpan?: number;
} | null;

function ExportSchedule({
  shifts,
  hospitalName,
  medicalGroup,
  date,
  shiftDefinitions,
}: exportShiftsProps) {
  const medicalGroupName = medicalGroup?.name ?? '';

  const startDate = startOfDay(startOfMonth(date));
  const endDate = endOfDay(endOfMonth(date));

  const daysOfWeek = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO',
  ];
  const numberOfDays = endDate.getDate() - startDate.getDate() + 1;

  const formatDate = (x: Date) => {
    return (
      ('0' + x.getDate()).slice(-2) +
      '/' +
      ('0' + (x.getMonth() + 1)).slice(-2) +
      '/' +
      x.getFullYear()
    );
  };

  const generateXls = async () => {
    const data: DataType[][] = [
      [
        {
          value: 'gerado em ' + formatDate(new Date()),
          color: '#009FFA',
          align: 'right',
          span: 35,
        },
      ],
      [
        {
          value: 'ESCALAS',
          color: '#009FFA',
          fontSize: 20,
          align: 'center',
          span: 35,
        },
      ],
      [
        {
          value: `HOSPITAL: ${hospitalName}`,
          fontWeight: 'bold',
          color: '#009FFA',
          align: 'center',
          span: 35,
        },
      ],
      [
        {
          value: `GRUPO MÉDICO: ${medicalGroupName}`,
          color: '#009FFA',
          align: 'center',
          span: 35,
        },
      ],
      [
        {
          value: `${formatDate(startDate)} à ${formatDate(endDate)}`,
          color: '#009FFA',
          align: 'center',
          span: 35,
        },
      ],
    ];

    const dataStartsAt = data.length + 1;

    const shiftsData = shifts
      .map((shift) => ({
        startDate: new Date(shift.startDate),
        endDate: new Date(shift.endDate),
        docName: shift.responsibleUser?.name,
        crm: shift.responsibleUser?.crm,
        ufCrf: shift.responsibleUser?.ufCrm,
        medicalGroup: shift.medicalGroup,
      }))
      .filter(
        (shift) =>
          (new Date(shift.startDate) >= startDate &&
            new Date(shift.startDate) <= endDate) ||
          (new Date(shift.endDate) >= startDate &&
            new Date(shift.endDate) <= endDate),
      )
      .filter((shift) => {
        if (!shift.medicalGroup) return false;
        return shift.medicalGroup.guid === medicalGroup!.guid;
      });

    if (shiftsData.length === 0) {
      toast.error(
        'Não há dados para gerar o relatório. Verifique se a data está correta.',
      );
      return;
    }

    let shiftDataPerDay: ShiftDataPerDay[] = [];

    for (let i = 1; i < numberOfDays + 1; i++) {
      const dateTemp = new Date(date.getFullYear(), date.getMonth(), i);
      const weekday = daysOfWeek[dateTemp.getDay()];

      shiftDataPerDay.push({
        weekday,
        day: i,
        month: getMonth(startDate),
        data: [],
      });
    }
    for (const dataDay of shiftDataPerDay) {
      for (const shift of shiftsData) {
        if (
          getDate(shift.startDate) === dataDay.day &&
          getMonth(shift.startDate) === dataDay.month
        ) {
          dataDay.data.push(shift);
        }
      }
    }

    shiftDataPerDay = addShiftsUntilPreviousSunday(shiftDataPerDay);

    const maxLengthArr = Array(Math.ceil(shiftDataPerDay.length / 7)).fill(0);
    for (
      let i = 0, j = 0;
      i < shiftDataPerDay.length;
      i++, i % 7 === 0 && j++
    ) {
      const dataLength = shiftDataPerDay[i].data.length;

      if (dataLength > maxLengthArr[j]) {
        maxLengthArr[j] = dataLength;
      }
    }

    const greaterMaxLineLength = maxLengthArr.reduce(
      (pv, cv) => (pv < cv ? cv : pv),
      0,
    );

    const maxXlsxLines = 7 * greaterMaxLineLength * 5;

    for (let i = 0; i < maxXlsxLines; i++) {
      data.push([]);
    }

    let shiftDataIndex = 0;
    let month = 0;
    for (const shiftData of shiftDataPerDay) {
      const jump =
        month !== 0
          ? // eslint-disable-next-line no-loop-func
            maxLengthArr.reduce((pv, cv, i) => {
              if (i < month) {
                return pv + cv * 2 + 5;
              }
              return pv;
            }, 0)
          : 0;

      if (!data[dataStartsAt + jump]) {
        data[dataStartsAt + jump] = [];
      }

      if (shiftData.day < 0) {
        data[dataStartsAt + jump].push({
          value: '',
          align: 'center',
          span: 1,
        });
      } else {
        const dateController = new Date(
          date.getFullYear(),
          date.getMonth(),
          shiftData.day,
        );

        data[dataStartsAt + jump].push({
          value: `${daysOfWeek[dateController.getDay()]} - ${formatDate(
            dateController,
          )}`,
          backgroundColor: '#00D9FF',
          fontWeight: 'bold',
          align: 'center',
          span: 5,
        });
      }
      data[dataStartsAt + jump].push(null);
      data[dataStartsAt + jump].push(null);
      data[dataStartsAt + jump].push(null);
      data[dataStartsAt + jump].push(null);

      for (let i = 0, index = 0; i < maxLengthArr[month]; i++, index += 2) {
        const shift = shiftData.data[i];
        const isAnnouncement = shift && !shift.docName && !shift.crm;
        console.log('isAnnouncement', isAnnouncement);
        if (!data[dataStartsAt + 1 + jump + index]) {
          data[dataStartsAt + 1 + jump + index] = [];
        }
        if (!data[dataStartsAt + 2 + jump + index]) {
          data[dataStartsAt + 2 + jump + index] = [];
        }

        if (shiftData.day < 0) {
          data[dataStartsAt + 1 + jump + index].push({
            value: '',
            span: 1,
            align: 'center',
            fontWeight: 'bold',
          });
        } else {
          data[dataStartsAt + 1 + jump + index].push({
            value: shift
              ? `${getFormattedTimeByDate(
                  shift.startDate,
                )} ~ ${getFormattedTimeByDate(shift.endDate)}`
              : '',
            span: 5,
            align: 'center',
            backgroundColor: '#C7F5FD',
            fontWeight: 'bold',
          });
        }
        data[dataStartsAt + 1 + jump + index].push(null);
        data[dataStartsAt + 1 + jump + index].push(null);
        data[dataStartsAt + 1 + jump + index].push(null);
        data[dataStartsAt + 1 + jump + index].push(null);

        if (shiftData.day < 0) {
          data[dataStartsAt + 2 + jump + index].push({
            value: '',
            span: 1,
            align: 'center',
          });
        } else {
          data[dataStartsAt + 2 + jump + index].push({
            value: shift
              ? isAnnouncement
                ? 'Anúncio'
                : `${shift.docName} (crm: ${shift.crm}${
                    shift.ufCrf ? `/${shift.ufCrf}` : ''
                  })`
              : '',
            span: 5,
            align: 'center',
          });
        }
        data[dataStartsAt + 2 + jump + index].push(null);
        data[dataStartsAt + 2 + jump + index].push(null);
        data[dataStartsAt + 2 + jump + index].push(null);
        data[dataStartsAt + 2 + jump + index].push(null);
      }

      shiftDataIndex++;
      if (shiftDataIndex % 7 === 0) {
        month++;
      }
    }

    await writeXlsxFile(data, {
      fileName:
        date.toLocaleString('default', { month: 'long' }) + '-espelho.xlsx',
    });
  };

  const addShiftsUntilPreviousSunday = (
    shiftsDataPerDay: ShiftDataPerDay[],
  ) => {
    const weekAddDayMap = {
      DOMINGO: 0,
      SEGUNDA: 1,
      TERÇA: 2,
      QUARTA: 3,
      QUINTA: 4,
      SEXTA: 5,
      SÁBADO: 6,
    };

    const firstElement = shiftsDataPerDay[0];
    const numElementsToAdd: number =
      weekAddDayMap[firstElement.weekday as keyof typeof weekAddDayMap];

    // Add empty elements to shiftsDataPerDay
    for (let i = 1; i < numElementsToAdd + 1; i++) {
      const emptyElement: ShiftDataPerDay = {
        day: -1 * i,
        month: getMonth(startDate),
        weekday: daysOfWeek[numElementsToAdd - i],
        data: [],
      };
      shiftsDataPerDay.unshift(emptyElement);
    }

    return shiftsDataPerDay;
  };

  return (
    <>
      <span
        onClick={async () => {
          await generateXls();
        }}
      >
        Gerar Relatório espelho
      </span>
    </>
  );
}

const getFormattedTimeByDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}`;

  return formattedTime;
};

export default ExportSchedule;
