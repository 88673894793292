import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-bottom: 0.66rem;
  color: var(--gray700) !important;

  b {
    color: var(--red);
  }
`;

export const Input = styled(InputMask)`
  padding: 0.7rem 1rem;
  border: var(--gray400) solid 1px;
  border-radius: 0.2rem;
  font-size: 0.8rem;

  &:focus {
    border: solid 1px var(--gray800);
    outline: none;
  }
`;

export const Error = styled.small`
  color: var(--red);
  margin-top: 0.2rem;
`;
