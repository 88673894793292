/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useForm } from 'react-hook-form';
import {
  type TurnoValues,
  initialTurnoValues,
  turnoValidationSchema,
} from '../forms/turnoForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { ShiftDefinitionService } from '../../../api/shiftDefinition';
import { type ICreateShiftDefinition } from '../../../api/types';
import { toast } from 'react-toastify';

export default function useTurno() {
  const methods = useForm({
    defaultValues: initialTurnoValues,
    resolver: zodResolver(turnoValidationSchema),
  });

  const onSubmit = async (data: TurnoValues) => {
    const input: ICreateShiftDefinition = {
      end: data.endDate,
      icon: data.icon,
      name: data.workTime,
      numberOfShifts: +data.numberOfShifts,
      start: data.startDate,
      ...(data.unique
        ? {
            domValue: +data.uniqueValue!,
            quaValue: +data.uniqueValue!,
            quiValue: +data.uniqueValue!,
            sabValue: +data.uniqueValue!,
            segValue: +data.uniqueValue!,
            sexValue: +data.uniqueValue!,
            terValue: +data.uniqueValue!,
          }
        : {
            domValue: +data.sundayValue!,
            quaValue: +data.wednesdayValue!,
            quiValue: +data.thursdayValue!,
            sabValue: +data.saturdayValue!,
            segValue: +data.mondayValue!,
            sexValue: +data.fridayValue!,
            terValue: +data.tuesdayValue!,
          }),
    };

    if (data.turnoId) {
      await ShiftDefinitionService.updateShiftDefinition(input, data.turnoId);
      toast.success('Turno atualizado com sucesso');
    } else {
      await ShiftDefinitionService.createShiftDefinition(
        input,
        data.medicalGroupId,
      );
      toast.success('Turno criado com sucesso');
    }
  };

  const onError = (error: any) => {
    console.log(error);
  };

  const reset = () => {
    methods.reset();
  };

  return { methods, onSubmit, onError, reset };
}
