const text = {
  result: {
    'pt-Br': 'Resultado',
    'en-Us': 'Result',
  },
  weekDays: {
    sun: {
      'pt-Br': 'Dom',
      'en-Us': 'Sun',
    },
    mon: {
      'pt-Br': 'Seg',
      'en-Us': 'Mon',
    },
    tues: {
      'pt-Br': 'Ter',
      'en-Us': 'Tues',
    },
    wed: {
      'pt-Br': 'Qua',
      'en-Us': 'Wed',
    },
    thurs: {
      'pt-Br': 'Qui',
      'en-Us': 'Thurs',
    },
    fri: {
      'pt-Br': 'Sex',
      'en-Us': 'Fri',
    },
    sat: {
      'pt-Br': 'Sab',
      'en-Us': 'Sat',
    },
  },
  legend: {
    'pt-Br': 'Para alterar a data clique no dia desejado',
    'en-Us': 'To change the date click in the desired day',
  },
};

export default text;
