/* eslint-disable no-loop-func */
import './style.css';
import moon from '../../assets/moon.svg';
import sun from '../../assets/sun.svg';
import magic from '../../assets/magic.svg';
import dayjs from '../../utils/dayjs';
import { type IShift } from '../../models/IShift';
import { type IShiftDefinition } from '../../models/IShiftDefinition';
import { type IUser } from '../../models/IUser';
import { useAuth } from '../../context/AuthContext';
import getIconUrl from '../../utils/icons';
import text from './text';
import { useLanguage } from '../../context/LanguageContext';
import { formatHour } from '../../utils/formatDate';
import { type ISwap } from '../../models/ISwap';

interface ScheduleProps {
  shifts: IShift[];
  date: Date;
  definitions: IShiftDefinition[];
  setIsAddVisible: (x: boolean) => void;
  setPublishVisible: (x: boolean) => void;
  handleAddFunction: (
    day: number,
    month: number,
    turno: string,
    startHour: string,
    endHour: string,
    year: number,
  ) => void;
  handleEditShift: (
    professional: IUser | undefined,
    shiftGuid: string,
    turno: IShiftDefinition,
    date: Date,
    observation: string,
    value: string,
    startDate: Date,
    endDate: Date,
    swap: ISwap | undefined,
  ) => void;
}
function Schedule({
  shifts,
  date,
  definitions,
  setIsAddVisible,
  handleAddFunction,
  setPublishVisible,
  handleEditShift,
}: ScheduleProps) {
  const numberOfDays = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).getDate();
  const lasMonthNumberOfDays = new Date(
    date.getFullYear(),
    date.getMonth(),
    0,
  ).getDate();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  const today = new Date().getDay();
  const numberOfWeeks = Math.ceil((numberOfDays + firstDay) / 7);
  const { isAdmin: isManager } = useAuth();
  const turnos = definitions.sort((a, b) =>
    parseInt(a.start) > parseInt(b.start)
      ? 1
      : parseInt(b.start) > parseInt(a.start)
        ? -1
        : 0,
  );
  const language = useLanguage();

  return (
    <>
      <div className="table-div">
        <table className="schedule-table">
          <thead className="name-of-days">
            <th className="schedule-detail"></th>
            <th className={today === 0 ? 'today' : ''}>
              {text.weekDays.sun[language.language]?.toUpperCase()}
            </th>
            <th className={today === 1 ? 'today' : ''}>
              {text.weekDays.mon[language.language]?.toUpperCase()}
            </th>
            <th className={today === 2 ? 'today' : ''}>
              {text.weekDays.tues[language.language]?.toUpperCase()}
            </th>
            <th className={today === 3 ? 'today' : ''}>
              {text.weekDays.wed[language.language]?.toUpperCase()}
            </th>
            <th className={today === 4 ? 'today' : ''}>
              {text.weekDays.thurs[language.language]?.toUpperCase()}
            </th>
            <th className={today === 5 ? 'today' : ''}>
              {text.weekDays.fri[language.language]?.toUpperCase()}
            </th>
            <th className={today === 6 ? 'today' : ''}>
              {text.weekDays.sat[language.language]?.toUpperCase()}
            </th>
          </thead>

          <tbody>
            {(() => {
              const trs: JSX.Element[] = [];
              let currentDay = firstDay * -1;

              if (shifts.filter((shift) => !shift.published).length > 0) {
                setPublishVisible(true);
              }

              for (let i = 1; i <= numberOfWeeks; i++) {
                const days = [<td></td>];

                for (let x = 1; x <= 7; x++) {
                  if (currentDay + x - 1 < 0) {
                    days.push(
                      <td className="out-of-month days-of-month">
                        {lasMonthNumberOfDays + currentDay + x}
                      </td>,
                    );
                  } else if (currentDay + x > numberOfDays) {
                    days.push(
                      <td className="out-of-month days-of-month">
                        {currentDay + x - numberOfDays}
                      </td>,
                    );
                  } else {
                    days.push(
                      <td
                        className={
                          currentDay + x === date.getDate()
                            ? 'today days-of-month'
                            : 'days-of-month'
                        }
                      >
                        <span>{currentDay + x}</span>
                      </td>,
                    );
                  }
                }

                trs.push(
                  <tr>
                    <td colSpan={8}>
                      <div
                        className={`week-division ${
                          i === 1 ? '' : 'not-first-division'
                        }`}
                      ></div>
                    </td>
                  </tr>,
                );
                trs.push(<tr>{days}</tr>);

                turnos.forEach((turno, i) => {
                  const shiftsOfTurno = [
                    <td
                      key={i}
                      className={`schedule-time ${
                        i === turnos.length - 1 ? 'last-turno' : ''
                      }`}
                    >
                      <div>
                        <span>{formatHour(turno.start)}</span>
                        <span>
                          <img
                            src={getIconUrl(turno.icon)}
                            alt={turno.name}
                            title={turno.name}
                          />
                        </span>
                        <span>{formatHour(turno.end)}</span>
                      </div>
                    </td>,
                  ];

                  for (let x = 1; x <= 7; x++) {
                    const shiftsFilled = [];

                    shifts
                      .filter((shift, i) => {
                        const day = dayjs(shift.startDate).date();
                        const month = dayjs(shift.startDate).month();

                        // Condição para o mês atual
                        const isInCurrentMonth =
                          day === currentDay + x &&
                          month === date.getMonth() &&
                          currentDay + x > 0;

                        // Condição para o mês anterior
                        const isInLastMonth =
                          day === lasMonthNumberOfDays + currentDay + x &&
                          month === date.getMonth() - 1 &&
                          currentDay + x <= 0;

                        // Condição para o próximo mês
                        const isInNextMonth =
                          day === currentDay + x - numberOfDays &&
                          month === date.getMonth() + 1 &&
                          currentDay + x > numberOfDays;

                        return (
                          (isInCurrentMonth ||
                            isInLastMonth ||
                            isInNextMonth) &&
                          shift.shiftDefinitionGuid === turno.guid
                        );
                      })
                      .forEach((shift) =>
                        shiftsFilled.push(
                          <div
                            className={`shift ${
                              currentDay + x <= 0 ||
                              currentDay + x > numberOfDays
                                ? 'out-month'
                                : ''
                            } ${shift.published ? '' : 'not-published'} ${
                              shift.swap
                                ? shift.swap.requestedUser
                                  ? 'exchanged'
                                  : 'announced'
                                : ''
                            }`}
                            onClick={() => {
                              handleEditShift(
                                shift.responsibleUser,
                                shift.guid,
                                turno,
                                dayjs(shift.startDate).toDate(),
                                shift.observation ?? '',
                                shift.value.toString(),
                                shift.startDate,
                                shift.endDate,
                                shift.swap,
                              );
                            }}
                          >
                            <span>
                              {!shift.responsibleUser?.name
                                ? text.announcement[language.language]
                                : shift.responsibleUser?.name}
                            </span>
                          </div>,
                        ),
                      );

                    for (
                      let z = 0;
                      z < turno.numberOfShifts - shiftsFilled.length + z;
                      z++
                    ) {
                      const h = `${currentDay + x}`;
                      shiftsFilled.push(
                        <div
                          className={`add-shift ${
                            parseInt(h) <= 0 || parseInt(h) > numberOfDays
                              ? 'add-selectable'
                              : ''
                          }${isManager ? ' manager' : ' not-manager'}`}
                          onClick={() => {
                            if (!isManager)
                              handleAddFunction(
                                parseInt(h),
                                date.getMonth(),
                                turno.guid,
                                turno.start,
                                turno.end,
                                date.getFullYear(),
                              );
                          }}
                        >
                          {isManager ? <></> : <span>+</span>}
                        </div>,
                      );
                    }
                    shiftsOfTurno.push(<td>{shiftsFilled}</td>);
                  }
                  trs.push(<tr>{shiftsOfTurno}</tr>);
                });
                currentDay = currentDay + 7;
              }
              return trs;
            })()}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Schedule;
