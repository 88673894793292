import { type ReactElement, type ReactNode } from 'react';
import MainLayout from '../mainLayout';
import AlertModal from '../AlertModal';
import userStore, { UserRoles } from '../../stores/userStore';
import AppRoutes from '../../enums/appRoutes';

interface PrivateRouteProps {
  children: ReactNode | ReactElement;
  allowManager?: boolean;
  allowAdmin?: boolean;
  allowSuperadmin?: boolean;
}
export const PrivateRoute = ({
  children,
  allowManager,
  allowAdmin,
  allowSuperadmin,
}: PrivateRouteProps) => {
  const { user } = userStore();

  if (!user) {
    window.location.replace(AppRoutes.Login);
    return;
  }

  const canAccess =
    !!(allowManager && user.role === UserRoles.Manager) ||
    !!(allowAdmin && user.role === UserRoles.Admin) ||
    !!(allowSuperadmin && user.role === UserRoles.SuperAdmin);

  if (!canAccess) {
    window.location.replace(AppRoutes.Login);
    return;
  }

  return (
    <>
      {user.role === UserRoles.Manager && <AlertModal />}
      <MainLayout>{children}</MainLayout>
    </>
  );
};
