import Form from '../../../components/form';
import Grid from '@mui/material/Grid';
import './style.css';
import Button from '../../../components/button';
import { useEffect, useState } from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { ufsList } from '../../../utils/ufs';
import { UserService } from '../../../api/user';
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import { type IUser } from '../../../models/IUser';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { type Dayjs } from '../../../utils/dayjs';
import useProfessionals from '../hooks/useProfessionals';
import { ChevronLeft } from 'lucide-react';
import { FormProvider } from 'react-hook-form';
import TextField from '../../../components/TextField';
import {
  ProfessionalFields,
  type ProfessionalFormValues,
} from '../forms/professionalForm';
import MoneyField from '../../../components/MoneyField';
import SelectField from '../../../components/SelectField';

export interface addFields {
  guid?: string;
  name?: string;
  email?: string;
  phone1?: string;
  phone2?: string;
  profession?: string;
  crm?: string;
  ufCrm?: string;
  // adress
  cep?: string;
  street?: string;
  number?: string;
  district?: string;
  complement?: string;
  uf?: string;
  city?: string;
  // details
  admissionDate?: Dayjs | null;
  code?: string;
  details?: string;
  additional?: number;
}

interface AddProfessionalProps {
  data?: addFields;
  professionals?: IUser[];
  setData: (x: addFields) => void;
  setIsVisible: (x: boolean) => void;
  onSuccess: () => void;
}
function AddProfessional({
  data,
  setData,
  setIsVisible,
  onSuccess,
  professionals,
}: AddProfessionalProps) {
  const [startData] = useState(data);
  const [cepPrev, setCepPrev] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [admissionDate, setAdmissionDate] = useState<Dayjs | null>(
    data?.admissionDate ?? null,
  );
  const { methods, onSubmit, fetchCep } = useProfessionals();
  const { selectedMedicalGroup } = useAuth();

  useEffect(() => {
    methods.setValue(
      ProfessionalFields.MedicalGroupId,
      selectedMedicalGroup?.guid ?? '',
    );
    methods.setValue(
      ProfessionalFields.HospitalId,
      selectedMedicalGroup?.hospitalGuid ?? '',
    );
  }, [selectedMedicalGroup]);

  useEffect(() => {
    if (!data) return;

    methods.setValue(ProfessionalFields.Email, data.email ?? '');
    methods.setValue(ProfessionalFields.Name, data.name ?? '');
    methods.setValue(ProfessionalFields.MainPhone, data.phone1 ?? '');
    methods.setValue(ProfessionalFields.SecondaryPhone, data.phone2 ?? '');
    methods.setValue(ProfessionalFields.Profession, data.profession ?? '');
    methods.setValue(ProfessionalFields.CRM, data.crm ?? '');
    methods.setValue(ProfessionalFields.UfCRM, data.ufCrm ?? '');
    methods.setValue(ProfessionalFields.CEP, data.cep ?? '');
    methods.setValue(ProfessionalFields.Street, data.street ?? '');
    methods.setValue(ProfessionalFields.Number, data.number ?? '');
    methods.setValue(ProfessionalFields.Neighborhood, data.district ?? '');
    methods.setValue(ProfessionalFields.Complement, data.complement ?? '');
    methods.setValue(ProfessionalFields.UF, data.uf ?? '');
    methods.setValue(ProfessionalFields.City, data.city ?? '');
    methods.setValue(
      ProfessionalFields.Bonus,
      data.additional?.toString() ?? '0',
    );
    methods.setValue(
      ProfessionalFields.AdmissionDate,
      data.admissionDate?.toISOString() ?? '',
    );
    methods.setValue(ProfessionalFields.Code, data.code ?? '');
    methods.setValue(ProfessionalFields.Details, data.details ?? '');
  }, [data]);

  const email = methods.watch(ProfessionalFields.Email);

  useEffect(() => {
    if (email === data?.email) return;

    if (
      email &&
      professionals &&
      professionals.filter((x) => x.email === email).length > 0
    ) {
      toast.error('Usuário já faz parte do grupo');
      fetchUserData(email);
    }
  }, [email]);

  const fetchUserData = async (email: string) => {
    try {
      const data = await UserService.findByEmail({
        email,
        hospitalGuid: selectedMedicalGroup?.hospitalGuid ?? '',
      });

      methods.setValue(ProfessionalFields.Email, data.email ?? '');
      methods.setValue(ProfessionalFields.Name, data.name ?? '');
      methods.setValue(ProfessionalFields.MainPhone, data.phone1 ?? '');
      methods.setValue(ProfessionalFields.SecondaryPhone, data.phone2 ?? '');
      methods.setValue(ProfessionalFields.Profession, data.profession ?? '');
      methods.setValue(ProfessionalFields.CRM, data.crm ?? '');
      methods.setValue(ProfessionalFields.UfCRM, data.ufCrm ?? '');
      methods.setValue(ProfessionalFields.CEP, data.cep ?? '');
      methods.setValue(ProfessionalFields.Street, data.street ?? '');
      methods.setValue(ProfessionalFields.Number, data.number ?? '');
      methods.setValue(ProfessionalFields.Neighborhood, data.district ?? '');
      methods.setValue(ProfessionalFields.Complement, data.complement ?? '');
      methods.setValue(ProfessionalFields.UF, data.uf ?? '');
      methods.setValue(ProfessionalFields.City, data.city ?? '');
      methods.setValue(
        ProfessionalFields.Bonus,
        data.additional?.toString() ?? '',
      );
      methods.setValue(
        ProfessionalFields.AdmissionDate,
        (data.admissionDate as unknown as string) ?? '',
      );
      methods.setValue(ProfessionalFields.Code, data.code ?? '');
      methods.setValue(ProfessionalFields.Details, data.details ?? '');
    } catch (error) {
      toast.error('Não foi possível buscar o usuário');
    }
  };

  const handleSubmit = async (input: ProfessionalFormValues) => {
    await onSubmit(input);
    toast.success(
      !data
        ? 'Profissional adicionado com sucesso.'
        : 'Profissional atualizado com sucesso.',
    );
    onSuccess();
  };

  useEffect(() => {
    const cep = methods.getValues('cep')?.replace(/\D/g, '');
    setCepPrev(cep);
    if (cepPrev.length === 7 && cep.length === 8) {
      fetchCep(cep);
    }
  }, [methods.watch('cep')]);

  const validateFormIsComplete = () => {
    const name = methods.getValues('name');
    const email = methods.getValues('email');
    const crm = methods.getValues('crm');
    const mainPhone = methods.getValues('mainPhone');

    if (name && email && crm && mainPhone) {
      if (
        name.length > 0 &&
        email.length > 0 &&
        crm.length > 0 &&
        mainPhone.length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setIsSaveEnabled(validateFormIsComplete());
  }, [
    data,
    methods.watch('name'),
    methods.watch('email'),
    methods.watch('mainPhone'),
    methods.watch('crm'),
  ]);

  return (
    <>
      <div
        className="add-professional-back"
        onClick={() => {
          setIsVisible(false);
        }}
      >
        <ChevronLeft />

        <span>
          {!startData ? 'Adicionar profissional' : 'Atualizar profissional'}
        </span>
      </div>
      <div className="header-form">
        <span className="selected">Informações</span>
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <h2>Dados pessoais</h2>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={4}>
              <TextField
                disabled={!!startData}
                name={ProfessionalFields.Email}
                type="email"
                label="E-mail"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField name={ProfessionalFields.Name} label="Nome" required />
            </Grid>
            <Grid item xs={12} style={{ padding: '0px' }}></Grid>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.MainPhone}
                label="Telefone(1)"
                required
                mask="(99) 99999-9999"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.SecondaryPhone}
                label="Telefone(2)"
                mask="(99) 99999-9999"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.Profession}
                label="Profissão"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.CRM}
                label="CRM"
                required
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <SelectField
                name={ProfessionalFields.UfCRM}
                options={ufsList}
                label="UF CRM"
              />
            </Grid>
          </Grid>

          <h2>Endereço</h2>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.CEP}
                label="CEP"
                mask="99999-999"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField name={ProfessionalFields.Street} label="Rua" />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={ProfessionalFields.Number}
                label="Número"
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name={ProfessionalFields.Neighborhood}
                label="Bairro"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name={ProfessionalFields.Complement}
                label="Complemento"
              />
            </Grid>
            <Grid item xs={2}>
              <SelectField
                name={ProfessionalFields.UF}
                options={ufsList}
                label="UF"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField name={ProfessionalFields.City} label="Cidade" />
            </Grid>
          </Grid>

          <h2>Bonificação do profissional</h2>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={2}>
              <MoneyField name={ProfessionalFields.Bonus} />
            </Grid>
          </Grid>

          <h2>Detalhes</h2>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={2.3}>
              <div className="styled-input-container">
                <label htmlFor="name">Data de admissão</label>
                <DatePicker
                  onChange={(d) => {
                    setAdmissionDate(d);
                    methods.setValue(
                      'admissionDate',
                      (d?.isValid() ? d.toISOString() : d ?? '') as string,
                    );
                  }}
                  value={admissionDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      name="admissionDate"
                      id="admissionDate"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <TextField name={ProfessionalFields.Code} label="Código" />
            </Grid>
            <Grid item xs={4}>
              <TextField name={ProfessionalFields.Details} label="Detalhes" />
            </Grid>
          </Grid>
          <div
            style={{
              width: '8rem',
              marginLeft: 'auto',
              marginTop: '1rem',
              paddingBottom: '2rem',
            }}
          >
            <Button disabled={!isSaveEnabled} type="submit">
              Salvar
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
}

export default AddProfessional;
