import { type InputHTMLAttributes, useState, useEffect } from 'react';
import './style.css';
import { EyeWrapper, FormField, TextField, Error } from './styles';
import { Eye, EyeOff } from 'lucide-react';
import { createId } from '@paralleldrive/cuid2';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: any;
}

function Input({ label, type, id, ...props }: InputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);

    type = isPasswordVisible ? 'text' : 'password';
  };

  const inputId = id ?? createId();

  return (
    <div>
      <label htmlFor={inputId}>{label}</label>
      <FormField focused={focused}>
        <TextField
          {...props}
          onFocus={onFocus}
          onBlur={onBlur}
          value={props.value}
          type={isPasswordVisible ? 'text' : type}
          id={inputId}
        />
        {type === 'password' && (
          <EyeWrapper onClick={togglePasswordVisibility}>
            {!isPasswordVisible ? <EyeOff size={18} /> : <Eye size={18} />}
          </EyeWrapper>
        )}
        {props.error && typeof props.error.message === 'string' && (
          <Error>{props.error.message}</Error>
        )}
      </FormField>
    </div>
  );
}

export default Input;
