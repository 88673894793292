import { useLanguage } from '../../context/LanguageContext';
import './style.css';
import text from './text';

interface ChalendarResultProps {
  month: number;
  year: number;
  days: number[];
  data?: {
    day: number;
    month: number;
    turno: string;
    startHour: string;
    endHour: string;
    year: number;
  };
  setData?: (params: {
    day: number;
    month: number;
    turno: string;
    startHour: string;
    endHour: string;
    year: number;
  }) => void;
  setDays?: (x: number[]) => void;
}

function ChalendarResult({
  month,
  year,
  days,
  data,
  setData,
  setDays,
}: ChalendarResultProps) {
  const language = useLanguage();
  const firstDay = new Date(year, month - 1, 1).getDay();
  const numberOfDays = new Date(year, month, 0).getDate();
  const lastMonthNumberOfDays = new Date(year, month - 2, 0).getDate();
  const numberOfWeeks = Math.ceil((numberOfDays + firstDay) / 7);
  const monthName = new Date(year, month - 1, 1).toLocaleString('pt-BR', {
    month: 'long',
  });

  return (
    <div className="chalendar-result">
      <h3>{text.result['pt-Br']}:</h3>
      <div className="month">
        {monthName.substring(0, 1).toUpperCase() + monthName.substring(1)}
      </div>
      <table>
        {(() => {
          const trs = [
            <tr className="days-of-week">
              <td>{text.weekDays.sun[language.language]}</td>
              <td>{text.weekDays.mon[language.language]}</td>
              <td>{text.weekDays.tues[language.language]}</td>
              <td>{text.weekDays.wed[language.language]}</td>
              <td>{text.weekDays.thurs[language.language]}</td>
              <td>{text.weekDays.fri[language.language]}</td>
              <td>{text.weekDays.sat[language.language]}</td>
            </tr>,
          ];

          for (let i = 0; i < numberOfWeeks; i++) {
            const week = [];
            for (let j = 0; j < 7; j++) {
              if (i * 7 + j < firstDay) {
                week.push(
                  <td>
                    <div className="day other-month">
                      {lastMonthNumberOfDays + (i * 7 + j) - firstDay + 1}
                    </div>
                  </td>,
                );
              } else if (i * 7 + j - firstDay + 1 > numberOfDays) {
                week.push(
                  <td>
                    <div className="day other-month">
                      {i * 7 + j - firstDay + 1 - numberOfDays}
                    </div>
                  </td>,
                );
              } else {
                week.push(
                  <td
                    className={days.length === 1 ? 'current-month ' : ''}
                    onClick={() => {
                      if (setData && data && setDays) {
                        setDays([i * 7 + j - firstDay + 1]);
                        setData({ ...data, day: i * 7 + j - firstDay + 1 });
                      }
                    }}
                  >
                    <div
                      className={`day ${
                        days.find((value) => value === i * 7 + j - firstDay + 1)
                          ? 'selected'
                          : ''
                      }`}
                    >
                      {i * 7 + j - firstDay + 1}
                    </div>
                  </td>,
                );
              }
            }
            trs.push(<tr>{week}</tr>);
          }
          return trs;
        })()}
      </table>
      {days.length === 1 && (
        <p className="select-message">{text.legend[language.language]}.</p>
      )}
    </div>
  );
}

export default ChalendarResult;
