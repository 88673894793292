import { useForm } from 'react-hook-form';
import {
  type HospitalFormValues,
  hospitalInitialValues,
  hospitalValidationSchema,
} from '../forms/hospitalForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { HospitalService } from '../../../api/hospital';

export default function useHospital() {
  const methods = useForm({
    defaultValues: hospitalInitialValues,
    resolver: zodResolver(hospitalValidationSchema),
  });

  const onSubmit = async (data: HospitalFormValues) => {
    await HospitalService.createOrUpdateHospital({
      hospitalName: data.name,
      maxNumberOfUsers: +data.maxUsers,
      timezone: data.timezone,
      guid: data.id,
      isActive: true,
    });
  };

  return { methods, onSubmit };
}
