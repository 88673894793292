import styled from 'styled-components';

export const ForgotPasswordHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary400);

  &:hover > svg {
    transition: all 300ms;
    filter: brightness(40%);
  }
`;
