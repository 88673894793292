import { useState, type FocusEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Input, Label, Wrapper } from './styles';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs, { type Dayjs } from 'dayjs';

export default function TimerPickerField({
  label,
  required,
  ...props
}: TimerPickerFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const [value, setValue] = useState<Dayjs | null | undefined>();

  useEffect(() => {
    if (methods.getValues(props.name)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setValue(dayjs(methods.getValues(props.name), 'HH:mm'));
    }
  }, [methods.watch(props.name)]);

  const onChange = (e: Dayjs | null | undefined) => {
    setValue(e);

    if (!e || !e.isValid()) {
      methods.setValue(props.name, '');
      return;
    }

    methods.setValue(props.name, e.format('HH:mm'));
    if (methods.formState.isSubmitted) methods.trigger(props.name);
  };

  const onBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    let { value } = e.target;

    value = e.target.value.replace(/\D/g, '');

    if (!value) {
      onChange(null);
      return;
    }

    value = value.padEnd(4, '0');

    if (+value > 2359) {
      onChange(null);
      return;
    }

    const time = dayjs(value, 'HHmm');

    if (!time.isValid) {
      onChange(null);
      return;
    }

    onChange(time);
  };

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <TimePicker
        onChange={onChange}
        value={value}
        ampm={false}
        renderInput={(params) => (
          <Input {...props} {...params} onBlur={onBlur} />
        )}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface TimerPickerFieldProps {
  name: string;
  label?: string;
  required?: boolean;
}
