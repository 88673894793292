import { z } from 'zod';

export enum ResponsibleFields {
  Name = 'name',
  Email = 'email',
  MainPhone = 'mainPhone',
  SecondaryPhone = 'secondaryPhone',
  Profession = 'profession',
  CRM = 'crm',
  CRMState = 'crmState',
  CEP = 'cep',
  Street = 'street',
  Number = 'number',
  Neighborhood = 'neighborhood',
  Complement = 'complement',
  UF = 'uf',
  City = 'city',
  AdmissionDate = 'admissionDate',
  Code = 'code',
  Details = 'details',
  HospitalId = 'hospitalId',
  MedicalGroupId = 'medicalGroupId',
}

export const initialResponsibleValues = {
  name: '',
  email: '',
  mainPhone: '',
  secondaryPhone: '',
  profession: '',
  crm: '',
  crmState: '',
  cep: '',
  street: '',
  number: '',
  neighborhood: '',
  complement: '',
  uf: '',
  city: '',
  admissionDate: null,
  code: '',
  details: '',
  hospitalId: '',
  medicalGroupId: '',
};

export const responsibleValidationSchema = z.object({
  name: z
    .string()
    .trim()
    .min(2, 'Nome muito curto')
    .max(255, 'Nome muito longo'),
  email: z.string().trim().email('E-mail inválido').optional(),
  mainPhone: z
    .string()
    .trim()
    .transform((i) => i.replace(/[^0-9]/g, ''))
    .refine(
      (i) => {
        if (!i.length) return true;

        return i.length === 10 || i.length === 11;
      },
      {
        message: 'Telefone inválido',
      },
    ),
  secondaryPhone: z
    .string()
    .trim()
    .transform((i) => i.replace(/[^0-9]/g, ''))
    .refine(
      (i) => {
        if (!i.length) return true;

        return i.length === 10 || i.length === 11;
      },
      {
        message: 'Telefone inválido',
      },
    ),
  profession: z.string().trim().max(50, 'Máximo de 50 caracteres').optional(),
  crm: z
    .string()
    .trim()
    .min(1, 'Campo obrigatório')
    .max(10, 'Máximo de 10 caracteres')
    .optional(),
  crmState: z.string().trim().optional(),
  cep: z
    .string()
    .trim()
    .transform((i) => i.replace(/[^0-9]/g, ''))
    .refine(
      (i) => {
        if (!i.length) return true;

        return i.length === 8;
      },
      {
        message: 'CEP inválido',
      },
    )
    .optional(),
  street: z.string().trim().max(255, 'Máximo de 255 caracteres').optional(),
  number: z
    .string()
    .trim()
    .refine(
      (value) => value === '' || (value.length >= 1 && value.length <= 10),
      {
        message: 'Número inválido',
      },
    )
    .optional(),
  neighborhood: z
    .string()
    .trim()
    .max(255, 'Máximo de 255 caracteres')
    .optional(),
  complement: z.string().trim().max(255, 'Máximo de 255 caracteres').optional(),
  uf: z
    .string()
    .trim()
    .refine(
      (i) => {
        if (!i.length) return true;

        return i.length === 2;
      },
      { message: 'UF inválido' },
    )
    .optional(),
  city: z.string().trim().max(50, 'Máximo de 50 caracteres').optional(),
  admissionDate: z
    .date({
      errorMap: () => {
        return {
          message: 'Data inválida',
        };
      },
    })
    .nullable(),
  code: z.string().trim().max(50, 'Máximo de 50 caracteres').optional(),
  details: z.string().trim().max(255, 'Máximo de 255 caracteres').optional(),
  hospitalId: z.string().uuid(),
  medicalGroupId: z.string().uuid().optional(),
});

export type ResponsibleValues = z.infer<typeof responsibleValidationSchema>;
