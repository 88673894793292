import { type IUser } from '../../models/IUser';
import api from '..';
import { type IAdmin } from '../../models/IAdmin';

interface LoginResponse {
  user: IUser | IAdmin;
  token: string;
}

export class AuthService {
  static async login(email: string, password: string): Promise<LoginResponse> {
    const { data } = await api.post('/users/login', { email, password });
    localStorage.setItem('accessToken', data.token);
    return data as unknown as LoginResponse;
  }

  static async loginAdm(
    email: string,
    password: string,
  ): Promise<LoginResponse> {
    const { data } = await api.post('/users/login/administrator', {
      email,
      password,
    });
    localStorage.setItem('accessToken', data.token);
    return data as unknown as LoginResponse;
  }

  static async getCurrentUser(): Promise<IUser | IAdmin> {
    const { data } = await api.get('/users/current');

    return data;
  }
}
