/* eslint-disable react-hooks/exhaustive-deps */
import { type KeyboardEvent, type InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { Error, Input, Label, Wrapper } from './styles';

export default function TextField({
  label,
  required,
  ...props
}: TextFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const value = methods.watch(props.name);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(props.name, e.target.value);

    if (methods.formState.isSubmitted) methods.trigger(props.name);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (e.target.type !== 'number') return;
    if (
      e.metaKey ||
      e.ctrlKey ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown'
    ) {
      return;
    }

    // Permitir números
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={props.name}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <Input
        mask={props.mask ?? ''}
        maskChar={null}
        {...props}
        {...methods.register(props.name)}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Wrapper>
  );
}

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  mask?: string;
}
