import styled from 'styled-components';

interface WrapperProps {
  focused: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-bottom: 0.66rem;
  color: var(--gray700) !important;

  b {
    color: var(--red);
  }
`;

export const Wrapper = styled.label<WrapperProps>`
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ focused }) => (focused ? 'var(--gray600)' : 'var(--gray400)')};
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  cursor: text;

  span {
    color: var(--gray500);
  }
`;

export const Input = styled.input`
  width: 100%;
  text-align: right;
  border: 0;
  outline: none;
  padding: 0.7rem 0;
  font-size: 0.8rem;
`;

export const Error = styled.small`
  color: var(--red);
  margin-top: 0.2rem;
`;
