import axios from 'axios';

export class CepService {
  static async search(cep: string): Promise<{
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
  }> {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return data;
  }
}
