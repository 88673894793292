import { useForm } from 'react-hook-form';
import {
  type AdminFormValues,
  adminInitialValues,
  adminValidationSchema,
} from '../forms/adminsForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { AdminService } from '../../../api/admins';

export default function useAdmin() {
  const methods = useForm({
    defaultValues: adminInitialValues,
    resolver: zodResolver(adminValidationSchema),
  });

  const onSubmit = async (data: AdminFormValues, adminId?: string) => {
    if (adminId) {
      await AdminService.updateAdmin(adminId, {
        name: data.name,
        hospitals: data.hospitals,
      });

      methods.reset();

      return;
    }

    await AdminService.createAdmin({
      name: data.name,
      email: data.email,
      hospitals: data.hospitals,
    });

    methods.reset();
  };

  return { methods, onSubmit };
}
