import Modal from '../../../components/modal';
import Button from '../../../components/button';
import {
  Container,
  DuplicateWrapper,
  DuplicationCalendarWrapper,
  HeadWrapper,
  ModalActionsWrapper,
  Subtitle,
  Title,
  WeekCounter,
} from './styles';
import { DuplicationCalendar } from './components/duplication-calendar';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { SelectionWeek } from '../../../entities/object-values/selection-week';
import { ShiftService } from '../../../api/shifts';
import { toast } from 'react-toastify';
import { MonthSelectorC } from './components/month-selector';

export type DuplicateWeekModalProps = {
  monthOrigin: number;
  monthDestination: number;
  yearOrigin: number;
  yearDestination: number;
  isModalVisible: boolean;
  setVisibility: (visibility: boolean) => void;
  medicalGroupGuid?: string;
};

type MonthSelectorState = {
  originMonth: number;
  destinationMonth: number;
  originYear: number;
  destinationYear: number;
};

export function DuplicateWeekModal({
  monthOrigin,
  monthDestination,
  isModalVisible,
  setVisibility,
  yearDestination,
  yearOrigin,
  medicalGroupGuid,
}: DuplicateWeekModalProps) {
  const [monthSelectorState, setMonthSelectorState] =
    useState<MonthSelectorState>({
      originMonth: 0,
      originYear: dayjs().get('year'),
      destinationMonth: 1,
      destinationYear: dayjs().get('year'),
    });
  const [selectionWeek, setSelectionWeek] = useState<{
    source: SelectionWeek;
    destination: SelectionWeek;
  }>({
    source: new SelectionWeek(),
    destination: new SelectionWeek(),
  });
  const [isLoading, setIsLoading] = useState(false);

  const convertSelectionWeeksToObject = (
    selectionWeek1: SelectionWeek,
    selectionWeek2: SelectionWeek,
  ): Record<string, string> => {
    const weeksSelected1 = selectionWeek1.weeks;
    const weeksSelected2 = selectionWeek2.weeks;

    const result: Record<string, string> = {};
    for (let i = 0; i < weeksSelected1.length; i++) {
      result[String(weeksSelected1.at(i))] = String(weeksSelected2.at(i));
    }

    return result;
  };

  const handleDuplicateWeeks = async () => {
    if (medicalGroupGuid) {
      const weeks = convertSelectionWeeksToObject(
        selectionWeek.source,
        selectionWeek.destination,
      );

      try {
        setIsLoading(true);
        await ShiftService.duplicateWeeks({
          medicalGroupGuid,
          monthSource: monthSelectorState.originMonth - 1,
          yearSource: monthSelectorState.originYear,
          monthDestination: monthSelectorState.destinationMonth - 1,
          yearDestination: monthSelectorState.destinationYear,
          weeks,
        });
        toast.success('Duplicação realizada com sucesso.');
        setVisibility(false);
      } catch (e) {
        toast.error('Houve um erro ao duplicar.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let destinationMonth = monthDestination;
    const destinationYear = yearDestination;

    if (
      destinationMonth < dayjs().get('month') + 1 &&
      destinationYear <= dayjs().get('year')
    ) {
      destinationMonth = dayjs().get('month') + 1;
    }

    setMonthSelectorState({
      originMonth: monthOrigin,
      originYear: yearOrigin,
      destinationMonth,
      destinationYear,
    });
  }, [monthOrigin, monthDestination, yearDestination, yearOrigin]);

  const minNumberOfWeekSelection = useMemo<number>(() => {
    const minSelectionSource = selectionWeek.source.length;
    const minSelectionDestination = selectionWeek.destination.length;

    const minSelectionGlobal =
      minSelectionSource > minSelectionDestination
        ? minSelectionSource
        : minSelectionDestination;

    return minSelectionGlobal;
  }, [selectionWeek]);

  const isDuplicateButtonDisabled = useMemo<boolean>(() => {
    return (
      selectionWeek.source.length !== selectionWeek.destination.length ||
      selectionWeek.source.length === 0 ||
      selectionWeek.destination.length === 0 ||
      (selectionWeek.source.weeks[0] === selectionWeek.destination.weeks[0] &&
        monthSelectorState.originMonth ===
          monthSelectorState.destinationMonth &&
        monthSelectorState.originYear === monthSelectorState.destinationYear)
    );
  }, [selectionWeek]);

  useEffect(() => {
    selectionWeek.source.clean();
    setSelectionWeek({
      ...selectionWeek,
      source: selectionWeek.source,
    });
  }, [monthSelectorState.originMonth]);

  useEffect(() => {
    selectionWeek.destination.clean();
    setSelectionWeek({
      ...selectionWeek,
      destination: selectionWeek.destination,
    });
  }, [monthSelectorState.destinationMonth]);

  return (
    <Modal
      setIsVisible={setVisibility}
      isVisible={isModalVisible}
      onClose={() => {
        setSelectionWeek({
          source: new SelectionWeek(),
          destination: new SelectionWeek(),
        });
      }}
      size={'lg'}
    >
      <Container>
        <HeadWrapper>
          <Title>Duplicar semanas</Title>
          <Subtitle>
            Selecione a semana do primeiro mês para duplicar para o próximo
          </Subtitle>
        </HeadWrapper>
        <DuplicateWrapper>
          <DuplicationCalendarWrapper>
            <MonthSelectorC
              from
              month={monthSelectorState.originMonth}
              year={monthSelectorState.originYear}
              onNext={() => {
                const nextMonth = monthSelectorState.originMonth + 1;
                setMonthSelectorState({
                  ...monthSelectorState,
                  originMonth: nextMonth > 12 ? 1 : nextMonth,
                  originYear:
                    nextMonth > 12
                      ? monthSelectorState.originYear + 1
                      : monthSelectorState.originYear,
                });
              }}
              onPrev={() => {
                const prevMonth = monthSelectorState.originMonth - 1;
                setMonthSelectorState({
                  ...monthSelectorState,
                  originMonth: prevMonth < 1 ? 12 : prevMonth,
                  originYear:
                    prevMonth < 1
                      ? monthSelectorState.originYear - 1
                      : monthSelectorState.originYear,
                });
              }}
            />
            <DuplicationCalendar
              source
              selectionWeek={selectionWeek.source}
              setSelectionWeek={(s) => {
                setSelectionWeek({
                  ...selectionWeek,
                  source: s,
                });
              }}
              onWeekSelected={(selectionWeek: SelectionWeek) => {
                console.log(selectionWeek);
              }}
              month={monthSelectorState.originMonth}
              year={monthSelectorState.originYear}
            />
            <WeekCounter>
              {selectionWeek.source.length}/{minNumberOfWeekSelection}
            </WeekCounter>
          </DuplicationCalendarWrapper>
          <ArrowIcon />
          <DuplicationCalendarWrapper>
            <MonthSelectorC
              to
              month={monthSelectorState.destinationMonth ?? 0}
              year={monthSelectorState.destinationYear ?? 2024}
              onNext={() => {
                const nextMonth = monthSelectorState.destinationMonth + 1;
                setMonthSelectorState({
                  ...monthSelectorState,
                  destinationMonth: nextMonth > 12 ? 1 : nextMonth,
                  destinationYear:
                    nextMonth > 12
                      ? monthSelectorState.destinationYear + 1
                      : monthSelectorState.destinationYear,
                });
              }}
              onPrev={() => {
                const prevMonth = monthSelectorState.destinationMonth - 1;
                setMonthSelectorState({
                  ...monthSelectorState,
                  destinationMonth: prevMonth < 1 ? 12 : prevMonth,
                  destinationYear:
                    prevMonth < 1
                      ? monthSelectorState.destinationYear - 1
                      : monthSelectorState.destinationYear,
                });
              }}
            />
            <DuplicationCalendar
              destination
              selectionWeek={selectionWeek.destination}
              setSelectionWeek={(d) => {
                setSelectionWeek({
                  ...selectionWeek,
                  destination: d,
                });
              }}
              onWeekSelected={(selectionWeek: SelectionWeek) => {
                console.log(selectionWeek);
              }}
              month={monthSelectorState.destinationMonth ?? 0}
              year={monthSelectorState.destinationYear ?? 2024}
            />
            <WeekCounter>
              {selectionWeek.destination.length}/{minNumberOfWeekSelection}
            </WeekCounter>
          </DuplicationCalendarWrapper>
        </DuplicateWrapper>
        <ModalActionsWrapper>
          <Button
            disabled={false}
            color={'default-danger'}
            onClick={() => {
              setVisibility(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            disabled={isDuplicateButtonDisabled}
            color={'primary'}
            onClick={handleDuplicateWeeks}
          >
            Duplicar
          </Button>
        </ModalActionsWrapper>
      </Container>
    </Modal>
  );
}

function ArrowIcon() {
  return (
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.12 4.91002L19.25 1.00002C19.157 0.906294 19.0464 0.831899 18.9246 0.78113C18.8027 0.730362 18.672 0.704224 18.54 0.704224C18.408 0.704224 18.2773 0.730362 18.1554 0.78113C18.0336 0.831899 17.923 0.906294 17.83 1.00002C17.6437 1.18738 17.5392 1.44084 17.5392 1.70502C17.5392 1.96921 17.6437 2.22266 17.83 2.41002L21.39 6.00002H1C0.734784 6.00002 0.48043 6.10538 0.292893 6.29292C0.105357 6.48045 0 6.73481 0 7.00002H0C0 7.26524 0.105357 7.51959 0.292893 7.70713C0.48043 7.89466 0.734784 8.00002 1 8.00002H21.45L17.83 11.61C17.7363 11.703 17.6619 11.8136 17.6111 11.9354C17.5603 12.0573 17.5342 12.188 17.5342 12.32C17.5342 12.452 17.5603 12.5827 17.6111 12.7046C17.6619 12.8265 17.7363 12.9371 17.83 13.03C17.923 13.1238 18.0336 13.1981 18.1554 13.2489C18.2773 13.2997 18.408 13.3258 18.54 13.3258C18.672 13.3258 18.8027 13.2997 18.9246 13.2489C19.0464 13.1981 19.157 13.1238 19.25 13.03L23.12 9.15002C23.6818 8.58752 23.9974 7.82502 23.9974 7.03002C23.9974 6.23502 23.6818 5.47252 23.12 4.91002Z"
        fill="#4B585E"
      />
    </svg>
  );
}
