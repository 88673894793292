import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 700px;
`;

export const DuplicateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 2em;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: #49575e;
  margin: 0 0 8px 0;
`;

export const Subtitle = styled.h4`
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #737b80;
  margin: 0 0 20px 0;
`;

export const HeadWrapper = styled.div`
  margin-bottom: 8px;
`;

export const DuplicationCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MonthSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MonthYearDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 150px;
`;

export const MonthSelectorTitle = styled.h3`
  color: #b5c3c8;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 22px;
`;

export const MonthSpan = styled.span`
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--gray800);
`;

export const YearSpan = styled.span`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--gray800);
`;

export const MonthSelectorButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 60px;

  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    filter: opacity(0.3);
    cursor: not-allowed;
  }
`;

export const MonthSelectorButtonRotated = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 60px;

  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    transform: rotate(180deg);
  }
`;

export const MonthSelectorActions = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarConsecutiveSelection = styled.div`
  display: flex;
  padding: 1.8rem;
  min-height: 280px;

  & .month {
    color: var(--gray800);
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
  }

  & .days-of-week {
    color: var(--gray500) !important;
    text-align: center;
  }

  & td:first-child {
    height: 40px;
    padding-right: 25px;
  }
`;

interface DayProps {
  selected?: number;
  otherMonth?: boolean;
}

export const Day = styled.div<DayProps>`
  text-align: center !important;
  border-radius: 100%;
  padding: 0.6rem;
  margin: 0.1rem;

  color: #737b80;

  ${(props) => (props.otherMonth ? 'color: var(--gray500);' : '')}
`;

type SelectionIndexProps = {
  selectionIndex: number;
};

export const SelectionIndex = styled.div<SelectionIndexProps>`
  width: 44px;
  height: 44px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selectionIndex }) =>
    selectionIndex
      ? `
    background-color: var(--primary);
    transition: 0.2s ease-in-out;
  `
      : ''}

  & span {
    font-size: 12;
    color: white;
  }
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 45px;

  & button {
    width: 95px;
  }
`;

type CalendarRowProps = {
  isSelected: boolean;
};

export const CalendarRow = styled.tr<CalendarRowProps>`
  border-radius: 20px;
  height: 40px;

  & td {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: var(--gray600);
    width: 40px;
    height: 40px;
  }

  & td:nth-child(2) {
    border-radius: 8px 0 0 8px;
  }

  ${({ isSelected }) =>
    isSelected
      ? `
    & td:not(:first-child) {
      background-color: #DFF3FF;
      color: #fff;
      transition: 0.2s ease-in-out;
    }

    & td:last-child {
      border-radius: 0 30px 30px 0;
    }
  `
      : ''}

  ${({ isSelected }) =>
    !isSelected
      ? `
    &:hover td:not(:first-child):not(:last-child) {
      background: var(--gray100);
      transition: 0.2s ease-in-out;
    }

    & td:nth-last-child(2) {
      border-radius: 0 8px 8px 0;
    }
  `
      : ''}
      
  cursor: pointer;
`;

export const WeekCounter = styled.span`
  display: block;
  align-self: flex-end;
  font-size: 20px;
  font-weight: 400;
  color: var(--gray400);
`;
