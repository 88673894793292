import React, { useEffect, useState } from 'react';
import './style.css';
import { Pagination, Stack } from '@mui/material';
import Container from '../../components/container';
import Button from '../../components/button';
import editIcon from '../../assets/edit.svg';
import addIcon from '../../assets/add.svg';
import searchIcon from '../../assets/search.svg';
import { type IAdmin } from '../../models/IAdmin';
import { AdminService } from '../../api/admins';
import Table from '../../components/table';
import Modal from '../../components/modal';
import { AddAdmin, type addAdminData } from './add';
import { HospitalService } from '../../api/hospital';
import { type IHospital } from '../../models/IHospital';
import { toast } from 'react-toastify';
import { ActivateInactivateButton } from '../../components/activateInactivateButton';

export default function Admins() {
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [actualPage, setActualPage] = useState(1);
  const [search, setSearch] = useState<string | undefined>();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [editOrAddData, setEditOrAddData] = useState<addAdminData>({
    guid: undefined,
    adminName: '',
    adminEmail: '',
    hospitals: [],
    isSuperAdmin: false,
  });

  const [hospitals, setHospitals] = useState<IHospital[]>([]);

  const fetchAdmins = async () => {
    const response = await AdminService.listAdmins();
    setAdmins(response.admins);
  };

  const fetchHospitals = async () => {
    setHospitals(await HospitalService.getAllHospitals());
  };

  const handleEditButton = (admin: IAdmin) => {
    setIsAddVisible(true);
    setEditOrAddData({
      guid: admin.guid,
      adminName: admin.name,
      adminEmail: admin.email,
      hospitals: admin.hospitals,
      isSuperAdmin: admin.isSuperAdmin,
    });
  };

  const handleEnableDisable = async (guid: string, isActive: boolean) => {
    await AdminService.updateAdmin(guid, {
      isActive,
    });

    toast.dismiss();
    toast.success(`Administrador ${isActive ? 'Ativo' : 'Inativo'}`);

    fetchAdmins();
  };

  const onSuccess = () => {
    toast.success(
      editOrAddData.guid
        ? 'Administrador atualizado'
        : 'Administrador adicionado',
    );
    fetchAdmins();
  };

  useEffect(() => {
    fetchAdmins();
    fetchHospitals();
  }, []);

  return (
    <>
      <Container>
        <div className="header" style={{ marginBottom: '3rem' }}>
          <div>
            <span>Administradores</span> <br />
            <br />
            <span className="sub-title">
              {admins.length} {admins.length === 1 ? 'registro' : 'registros'}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className="styled-input-container input-with-icon"
              style={{ marginRight: '1rem' }}
            >
              <input
                placeholder="Pesquisar administrador"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  setActualPage(1);
                }}
              />
              <img className="input-icon" src={searchIcon} alt="" />
            </div>
            <div style={{ width: '14rem' }}>
              <Button
                onClick={() => {
                  setEditOrAddData({
                    adminName: '',
                    adminEmail: '',
                    hospitals: [],
                  });
                  setIsAddVisible(true);
                }}
                disabled={false}
              >
                <img src={addIcon} alt="" style={{ marginRight: '1rem' }} />
                Adicionar administrador
              </Button>
            </div>
          </div>
        </div>

        <Table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>E-mail</td>
              <td>Status</td>
              <td>Super administrador</td>
              <td style={{ width: '7rem' }}></td>
            </tr>
          </thead>
          <tbody>
            {admins
              .filter((p) =>
                search
                  ? p.name.toLowerCase().search(search.toLowerCase()) >= 0
                  : true,
              )
              .slice((actualPage - 1) * 10, actualPage * 10)
              .map((p) => (
                <tr key={p.guid}>
                  <td>{p.name}</td>
                  <td>{p.email}</td>
                  <td>{p.isActive ? 'Ativo' : 'Inativo'}</td>
                  <td>{p.isSuperAdmin ? 'Sim' : 'Não'}</td>
                  <td className="actions">
                    <button
                      className="edit-button"
                      onClick={() => {
                        handleEditButton(p);
                      }}
                    >
                      <img src={editIcon} alt="" />
                    </button>
                    <ActivateInactivateButton
                      isActive={p.isActive}
                      onClick={async () => {
                        await handleEnableDisable(p.guid, !p.isActive);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <Stack alignItems="end">
          <Pagination
            style={{ float: 'right', marginTop: '1rem' }}
            count={Math.ceil(
              (admins.filter((p) =>
                search
                  ? p.name.toLowerCase().search(search.toLowerCase()) === 0
                  : true,
              ).length || 10) / 10,
            )}
            shape="rounded"
            color="primary"
            onChange={(e, p) => {
              setActualPage(p);
            }}
          />
        </Stack>

        <Modal
          setIsVisible={setIsAddVisible}
          isVisible={isAddVisible}
          size="xmd"
        >
          <AddAdmin
            data={editOrAddData}
            isVisble={isAddVisible}
            setIsVisible={setIsAddVisible}
            onSuccess={onSuccess}
            hospitals={hospitals}
          />
        </Modal>
      </Container>
    </>
  );
}
