import api from '..';
import {
  type ICreateManyShifts,
  type IGetAllShifts,
  type IShiftsResponse,
  type IPublish,
  type IUpdateShift,
  type IDuplicate,
} from '../types';
import { type IShift } from '../../models/IShift';
import { toast } from 'react-toastify';
import { type IUser } from '../../models/IUser';

type IDuplicateWeeks = {
  medicalGroupGuid: string;
  monthSource: number;
  yearSource: number;
  monthDestination: number;
  yearDestination: number;
  weeks: Record<string, string>;
};

export class ShiftService {
  static async getAll({
    startDate,
    endDate,
    medicalGroupGuid,
  }: IGetAllShifts): Promise<IShiftsResponse<IShift>> {
    const { data } = await api.get(
      `/shifts?startDate=${startDate}&endDate=${endDate}&medicalGroupGuid=${medicalGroupGuid}`,
    );

    return data;
  }

  static async createMany({
    dates,
    shiftDefinitionGuid,
    medicalGroupGuid,
    responsibleUserGuid,
    year,
    month,
  }: ICreateManyShifts): Promise<IShiftsResponse<IShift>> {
    const { data } = await api.post(`/shifts/many`, {
      dates,
      shiftDefinitionGuid,
      medicalGroupGuid,
      responsibleUserGuid,
      year,
      month,
    });

    toast.success('Plantão criado com sucesso!');
    return data;
  }

  static async update({
    shiftGuid,
    ...input
  }: IUpdateShift): Promise<IShiftsResponse<IShift>> {
    const { data } = await api.put(`/shifts`, {
      ...input,
      guid: shiftGuid,
    });

    toast.success(
      input.isActive === false
        ? 'Plantão excluído com sucesso!'
        : 'Plantão atualizado com sucesso!',
    );
    return data;
  }

  static async listCoworkers(shiftGuid: string): Promise<IUser[]> {
    const { data } = await api.get(`/shifts/${shiftGuid}/coworkers`);

    return data.users;
  }

  static async publish({
    startDate,
    endDate,
    medicalGroupGuid,
    timezoneOffset,
  }: IPublish): Promise<number> {
    const { data } = await api.post('/shifts/publish', {
      startDate,
      endDate,
      medicalGroupGuid,
      timezoneOffset,
    });

    return data;
  }

  static async duplicate({
    medicalGroupGuid,
    currentMonth,
    currentYear,
  }: IDuplicate): Promise<number> {
    const { data } = await api.post('/shifts/duplicate', {
      medicalGroupGuid,
      currentMonth,
      currentYear,
    });

    return data;
  }

  static async duplicateWeeks({
    medicalGroupGuid,
    monthDestination,
    monthSource,
    weeks,
    yearDestination,
    yearSource,
  }: IDuplicateWeeks) {
    await api.post('/shifts/duplicate-weeks', {
      medicalGroupGuid,
      monthDestination,
      monthSource,
      weeks,
      yearDestination,
      yearSource,
    });
  }
}
