/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HospitalService } from '../../../api/hospital';
import Button from '../../../components/button';
import Form from '../../../components/form';
import { type ITimezone } from '../../../models/ITimezone';
import { type IAdmin } from '../../../models/IAdmin';
import useHospital from '../hooks/useHospital';
import { FormProvider } from 'react-hook-form';
import TextField from '../../../components/TextField';
import { HospitalFields, type HospitalFormValues } from '../forms/hospitalForm';

export interface addHospitalData {
  guid?: string;
  hospitalName?: string;
  maxNumberOfUsers?: number;
  isActive?: boolean;
  admins?: IAdmin[];
  timezone?: string;
}

interface AddHospitalProps {
  data?: addHospitalData;
  setData: (x: addHospitalData) => void;
  setIsVisible: (x: boolean) => void;
  onSuccess: () => void;
  isVisble: boolean;
}

function AddHospital({
  data,
  setData,
  setIsVisible,
  onSuccess,
  isVisble,
}: AddHospitalProps) {
  const [countUsers, setCountUsers] = useState<number | undefined>();
  const [timezonesList, setTimezonesList] = useState<ITimezone[] | undefined>(
    [],
  );
  const { methods, onSubmit } = useHospital();

  const fetchTimezonesList = async () => {
    const ret = await HospitalService.getTimezonesList();
    setTimezonesList(ret);
  };

  const fetchCountUsers = async (guid: string) => {
    const ret = await HospitalService.countUsers(guid);
    setCountUsers(ret);
  };

  const handleSubmit = async (input: HospitalFormValues) => {
    try {
      await onSubmit(input);
      toast.success(
        'Hospital ' + (data?.guid ? 'atualizado ' : 'criado ') + 'com sucesso.',
      );
      onSuccess();
    } catch (err) {
      toast.error('Erro ao salvar o hospital');
    }
  };

  useEffect(() => {
    const tz = methods.getValues(HospitalFields.Timezone);
    if (!tz) {
      methods.setValue(
        HospitalFields.Timezone,
        timezonesList?.at(34)?.displayName ?? '',
      );
    }
  }, [timezonesList]);

  useEffect(() => {
    fetchTimezonesList();

    if (!data) return;

    fetchCountUsers(data.guid!);

    methods.setValue(HospitalFields.Id, data.guid);
    methods.setValue(HospitalFields.Name, data.hospitalName!);
    methods.setValue(
      HospitalFields.MaxUsers,
      data.maxNumberOfUsers?.toString() ?? '',
    );
    methods.setValue(HospitalFields.Timezone, data.timezone!);
  }, [data]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit, console.log)}>
          <h2 style={{ marginBottom: '0.3rem' }}>Hospital</h2>
          <span style={{ color: 'var(--gray600)' }}>
            {data?.guid ? countUsers + ' usuário(s) ativo(s)' : ''}
          </span>
          <br />
          <br />
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={5}>
              <TextField name={HospitalFields.Name} label="Nome" required />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name={HospitalFields.MaxUsers}
                type="number"
                label="Nº de usuários"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <div className="styled-input-container">
                <label id="timezone">
                  Fuso horário <b>*</b>
                </label>
                <Select
                  disabled
                  labelId="timezone"
                  id="demo-simple-select"
                  value={methods.watch(HospitalFields.Timezone) ?? ''}
                  label="Age"
                  style={{ width: '100%', height: '42px' }}
                  onChange={(e) => {
                    methods.setValue(HospitalFields.Timezone, e.target.value);

                    if (methods.formState.isSubmitted)
                      methods.trigger(HospitalFields.Timezone);
                  }}
                >
                  {timezonesList?.map((timezone, key) => {
                    return (
                      <MenuItem
                        key={key}
                        selected={data?.timezone === timezone.displayName}
                        id={timezone.displayName}
                        value={timezone.displayName}
                        color="black"
                        style={{
                          backgroundColor:
                            data?.timezone === timezone.displayName
                              ? 'var(--gray100)'
                              : 'white',
                          color: '#000000',
                        }}
                      >
                        {timezone.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
                {methods.formState.errors.timezone && (
                  <small
                    style={{
                      color: 'var(--red)',
                      marginTop: '0.2rem',
                    }}
                  >
                    {methods.formState.errors.timezone.message}
                  </small>
                )}
              </div>
            </Grid>
          </Grid>
          <div className="buttons-row">
            <Button
              onClick={() => {
                setIsVisible(false);
              }}
              color="destructive"
            >
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
}

export default AddHospital;
