import api from '..';
import { type ICreateUser } from '../types';
import { type IMedicalGroup } from '../../models/IMedicalGroup';
import { type IUser } from '../../models/IUser';

export class MedicalGroupService {
  static async getGroupsIManage(
    hospitalGuid: string,
  ): Promise<IMedicalGroup[]> {
    const { data } = await api.get(`/groups/hospitals/${hospitalGuid}/manage`);
    return data.medicalGroups;
  }

  static async getGroupProfessionals(
    groupGuid: string,
    hospitalGuid: string,
  ): Promise<IUser[]> {
    const { data } = await api.get(
      `/groups/${groupGuid}/hospitals/${hospitalGuid}/professionals`,
    );

    return data.users;
  }

  static async createOrUpdate(params: {
    guid?: string;
    name: string;
    hospitalGuid: string;
    managersGuid?: string[];
    manager?: Omit<ICreateUser, 'medicalGroupGuid'>;
  }): Promise<IMedicalGroup> {
    const { data } = await api.post('/groups', params);

    return data;
  }

  static async delete(guid: string): Promise<IMedicalGroup> {
    const { data } = await api.post(`/groups/${guid}/remove`);

    return data;
  }
}
