/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useForm } from 'react-hook-form';
import {
  type GroupValues,
  groupInitialValues,
  groupValidationSchema,
} from '../forms/groupForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { MedicalGroupService } from '../../../api/medicalGroup';
import { type addProfessionalFields } from '../add';

export default function useGroup() {
  const methods = useForm({
    defaultValues: groupInitialValues,
    resolver: zodResolver(groupValidationSchema),
  });

  const onSubmit = async (
    data: GroupValues & { manager: addProfessionalFields | undefined },
  ) => {
    await MedicalGroupService.createOrUpdate({
      guid: data.id,
      name: data.name,
      managersGuid: data.responsibles,
      manager: data.manager,
      hospitalGuid: data.hospitalId,
    });
  };

  return { methods, onSubmit };
}
