import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type ResponsibleValues,
  initialResponsibleValues,
  responsibleValidationSchema,
} from '../forms/responsibleForm';
import { UserService } from '../../../api/user';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CepService } from '../../../api/cep';

export default function useResponsible() {
  const methods = useForm({
    defaultValues: initialResponsibleValues as ResponsibleValues,
    resolver: zodResolver(responsibleValidationSchema),
  });
  const [canSubmit, setCanSubmit] = useState(false);

  const onSubmit = async (data: ResponsibleValues) => {
    const {
      admissionDate,
      neighborhood,
      hospitalId,
      medicalGroupId,
      mainPhone,
      secondaryPhone,
      crmState,
      ...input
    } = data;

    await UserService.addUserToHospital(hospitalId, {
      ...input,
      admissionDate: admissionDate ? admissionDate.toISOString() : '',
      district: neighborhood,
      hospitalGuid: hospitalId,
      medicalGroupGuid: medicalGroupId,
      phone1: mainPhone,
      phone2: secondaryPhone,
      ufCrm: crmState,
    });
    methods.reset();
  };

  const onError = (error: any) => {
    console.log(error);
  };

  useEffect(() => {
    setCanSubmit(
      responsibleValidationSchema.safeParse(methods.getValues()).success,
    );
  }, [methods.watch()]);

  useEffect(() => {
    const cep = methods.getValues('cep')?.replace(/\D/g, '');

    if (cep?.length !== 8) return;

    searchCEP(cep);
  }, [methods.watch('cep')]);

  const searchCEP = async (cep: string) => {
    try {
      const result = await CepService.search(cep);

      methods.setValue('neighborhood', result.bairro);
      methods.setValue('city', result.localidade);
      methods.setValue('uf', result.uf);
      methods.setValue('street', result.logradouro);
    } catch (err) {
      toast.error('CEP não encontrado');
    }
  };

  return { methods, onSubmit, onError, canSubmit };
}
