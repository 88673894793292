import { useState } from 'react';
import text from '../../../../components/chalendarResult/text';
import { useLanguage } from '../../../../context/LanguageContext';
import {
  CalendarConsecutiveSelection,
  CalendarRow,
  Day,
  SelectionIndex,
} from '../styles';
import { type SelectionWeek } from '../../../../entities/object-values/selection-week';

type DuplicationCalendarProps = {
  month: number;
  year: number;
  selectionWeek: SelectionWeek;
  setSelectionWeek: (s: SelectionWeek) => void;
  onWeekSelected: (selectionWeek: SelectionWeek) => void;
  source?: boolean;
  destination?: boolean;
} & (
  | { source: true; destination?: never }
  | { destination: true; source?: never }
  | { source: undefined; destination: undefined }
);

export function DuplicationCalendar({
  month,
  year,
  onWeekSelected,
  selectionWeek,
  setSelectionWeek,
  source,
}: DuplicationCalendarProps) {
  const language = useLanguage();
  const firstDay = new Date(year, month - 1, 1).getDay();
  const numberOfDays = new Date(year, month, 0).getDate();
  const lastMonthNumberOfDays = new Date(year, month - 2, 0).getDate();
  const numberOfWeeks = Math.ceil((numberOfDays + firstDay) / 7);

  const handleCheckBoxStateChange = (week: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectionWeek(selectionWeek.add(week));
    } else {
      setSelectionWeek(selectionWeek.remove(week));
    }
    onWeekSelected(selectionWeek);
  };

  return (
    <CalendarConsecutiveSelection>
      <table cellSpacing={0}>
        {(() => {
          const unselectAll = () => {
            for (let w = 0; w < numberOfWeeks; w++) {
              setSelectionWeek(selectionWeek.remove(w + 1));
            }
          };

          const selectAll = () => {
            for (let w = 0; w < numberOfWeeks; w++) {
              setSelectionWeek(selectionWeek.add(w + 1));
            }
          };

          const trs = [
            <tr key="week-days" className="days-of-week">
              <td>
                <input
                  type="checkbox"
                  id={`full-weeks-selection-${month}-${year}-${source ? 's' : 'd'}`}
                  checked={selectionWeek.length > 0}
                  onChange={() => {
                    const shouldSelectAll = selectionWeek.length !== 0;
                    unselectAll();
                    if (shouldSelectAll) {
                      unselectAll();
                    } else {
                      selectAll();
                    }
                  }}
                />
                <label
                  htmlFor={`full-weeks-selection-${month}-${year}-${source ? 's' : 'd'}`}
                />
              </td>
              <td>{text.weekDays.sun[language.language]}</td>
              <td>{text.weekDays.mon[language.language]}</td>
              <td>{text.weekDays.tues[language.language]}</td>
              <td>{text.weekDays.wed[language.language]}</td>
              <td>{text.weekDays.thurs[language.language]}</td>
              <td>{text.weekDays.fri[language.language]}</td>
              <td>{text.weekDays.sat[language.language]}</td>
              <td></td>
            </tr>,
          ];

          for (let i = 0; i < numberOfWeeks; i++) {
            const week = [];
            week.push(
              <td>
                <input
                  type="checkbox"
                  name={`week-${i}`}
                  id={`select-week-${i}`}
                  checked={!!selectionWeek.isSelected(i + 1)}
                />
                <label />
              </td>,
            );
            for (let j = 0; j < 7; j++) {
              if (i * 7 + j < firstDay) {
                week.push(
                  <td>
                    <Day
                      className="m-day"
                      otherMonth
                      selected={selectionWeek.isSelected(i + 1)}
                    >
                      {lastMonthNumberOfDays + (i * 7 + j) - firstDay + 1}
                    </Day>
                  </td>,
                );
              } else if (i * 7 + j - firstDay + 1 > numberOfDays) {
                week.push(
                  <td>
                    <Day
                      className="m-day"
                      otherMonth
                      selected={selectionWeek.isSelected(i + 1)}
                    >
                      {i * 7 + j - firstDay + 1 - numberOfDays}
                    </Day>
                  </td>,
                );
              } else {
                week.push(
                  <td>
                    <Day
                      className="m-day"
                      selected={selectionWeek.isSelected(i + 1)}
                    >
                      {i * 7 + j - firstDay + 1}
                    </Day>
                  </td>,
                );
              }
            }
            week.push(
              <td>
                <SelectionIndex
                  selectionIndex={selectionWeek.isSelected(i + 1) ?? 0}
                >
                  <span>{selectionWeek.isSelected(i + 1)}º</span>
                </SelectionIndex>
              </td>,
            );
            trs.push(
              <CalendarRow
                key={i}
                isSelected={!!selectionWeek.isSelected(i + 1)}
                onClick={() => {
                  const isChecked = selectionWeek.isSelected(i + 1);
                  handleCheckBoxStateChange(i + 1, !isChecked);
                }}
              >
                {week}
              </CalendarRow>,
            );
          }
          return trs;
        })()}
      </table>
    </CalendarConsecutiveSelection>
  );
}
