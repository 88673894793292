import { useForm } from 'react-hook-form';
import {
  type NewPasswordFormValues,
  newPasswordInitialValues,
  newPasswordValidationSchema,
} from '../forms/newPasswordForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserService } from '../../../api/user';

export default function useNewPassword() {
  const methods = useForm({
    defaultValues: newPasswordInitialValues,
    resolver: zodResolver(newPasswordValidationSchema),
  });

  const onSubmit = async (
    data: NewPasswordFormValues & { isAdmin: boolean },
  ) => {
    await UserService.updatePassword({
      newPassword: data.newPassword,
      currentPassword: data.oldPassword,
      isAdmin: data.isAdmin,
    });
  };

  return { methods, onSubmit };
}
