import api from '..';
import {
  type IGetAllParams,
  type IPaginationResponse,
  type ICreateUser,
} from '../types';
import { type IUser } from '../../models/IUser';
import { type IAdmin } from '../../models/IAdmin';
import { type addFields } from '../../components/mainLayout';
import { useAuth } from '../../context/AuthContext';

export class UserService {
  static async getAll({
    page,
    search,
    orderBy,
    orderMode,
  }: IGetAllParams): Promise<IPaginationResponse<IUser>> {
    const { data } = await api.get(
      `/users?q=${
        search || ''
      }&p=${page}&l=12&orderBy=${orderBy}&orderMode=${orderMode}`,
    );

    return data;
  }

  static async createUser({
    name,
    email,
    phone1,
    phone2,
    profession,
    crm,
    ufCrm,
    // adress
    cep,
    street,
    number,
    district,
    complement,
    uf,
    city,
    // details
    admissionDate,
    code,
    details,
    additional,
    medicalGroupGuid,
  }: ICreateUser): Promise<IUser> {
    const { data } = await api.post('/users/group', {
      name,
      email,
      phone1,
      phone2,
      profession,
      crm,
      ufCrm,
      // adress
      cep,
      street,
      number,
      district,
      complement,
      uf,
      city,
      // details
      admissionDate,
      code,
      details,
      additional,
      medicalGroupGuid,
    });

    return data;
  }

  static async addUserToHospital(
    hospitalGuid: string,
    input: ICreateUser,
  ): Promise<IUser> {
    const { data } = await api.post(`/users/hospitals/${hospitalGuid}`, input);

    return data;
  }

  static async removeFromGroup(
    userGuid: string[],
    medicalGroupGuid: string,
  ): Promise<IUser> {
    const { data } = await api.post('/users/group/remove', {
      userGuid,
      medicalGroupGuid,
    });

    return data;
  }

  static async updateAdmin(name: string): Promise<IAdmin> {
    const { data } = await api.put('/admins', { name });

    return data;
  }

  static async sendRecoveryCode(email: string): Promise<{
    recoveryCodeSent: boolean;
    recoveryTime: Date | null;
  }> {
    const { data } = await api.post(
      '/users/recoveryPassword/sendRecoveryCode',
      { email },
    );

    return data;
  }

  static async sendAdminRecoveryCode(email: string): Promise<{}> {
    const { data } = await api.post(
      '/users/recoveryPassword/sendAdminRecoveryCode',
      { email },
    );

    return data;
  }

  static async validateRecoveryCode(
    email: string,
    recoveryCode: string,
  ): Promise<{
    token: string;
    user: IUser;
  }> {
    const { data } = await api.post(
      '/users/recoveryPassword/validateRecoveryCode',
      { email, recoveryCode },
    );
    localStorage.setItem('accessToken', data.token);

    return data;
  }

  static async validateAdminRecoveryCode(
    email: string,
    recoveryCode: string,
  ): Promise<{
    token: string;
    user: IUser;
  }> {
    const { data } = await api.post(
      '/users/recoveryPassword/validateAdminRecoveryCode',
      { email, recoveryCode },
    );
    localStorage.setItem('accessToken', data.token);

    return data;
  }

  static async setNewPassword(
    newPassword: string,
    confirmNewPassword: string,
  ): Promise<{
    user: IUser;
  }> {
    const { data } = await api.put('/users/recoveryPassword', {
      newPassword,
      confirmNewPassword,
    });

    return data;
  }

  static async setNewAdminPassword(
    newPassword: string,
    confirmNewPassword: string,
  ): Promise<{
    user: IUser;
  }> {
    const { data } = await api.put('/users/recoveryAdminPassword', {
      newPassword,
      confirmNewPassword,
    });

    return data;
  }

  static async updateUser(params: addFields): Promise<IUser> {
    const { data } = await api.put('/users', {
      name: params.name || undefined,
      email: params.email || undefined,
      phone1: params.phone1 || undefined,
      phone2: params.phone2 || undefined,
      profession: params.profession || undefined,
      crm: params.crm || undefined,
      ufCrm: params.ufCrm || undefined,
      // adress
      cep: params.cep || undefined,
      street: params.street || undefined,
      number: params.number || undefined,
      district: params.district || undefined,
      complement: params.complement || undefined,
      uf: params.uf || undefined,
      city: params.city || undefined,
      // details
      admissionDate: params.admissionDate || undefined,
      code: params.code || undefined,
      details: params.details || undefined,
    });

    return data;
  }

  static async updatePassword({
    currentPassword,
    newPassword,
    isAdmin,
  }: {
    currentPassword?: string;
    newPassword?: string;
    isAdmin: boolean;
  }): Promise<IUser> {
    const { data } = await api.put(isAdmin ? '/admins' : '/users', {
      currentPassword,
      newPassword,
    });

    return data;
  }

  static async findByEmail({
    email,
    hospitalGuid,
  }: {
    email: string;
    hospitalGuid: string;
  }): Promise<IUser> {
    const { data } = await api.post('/users/email', {
      email,
      hospitalGuid,
    });

    return data;
  }

  static async getAllAdmins(): Promise<IUser[]> {
    const { data } = await api.get(`/users/admins`);
    return data;
  }
}
