/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_BASE_URL
      : process.env.REACT_APP_ENV === 'development'
        ? process.env.REACT_APP_BASE_URL_DEV
        : process.env.REACT_APP_BASE_URL_HOMOLOG,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      return await Promise.reject('Servidor indisponível');
    }

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      window.location.pathname !== '/login'
    ) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('@agendoctor-user');
      if (error.response.status === 401) {
        window.location.replace('/login');
      }
      if (error.response.status === 403) {
        toast.dismiss();
        toast.error(error.response.data.message);
      }
      return await Promise.reject(error.response.data.message);
    }

    toast.dismiss();
    toast.error(
      error.response.data.message ===
        'Conflito de horários com um ou mais plantões que você possui.'
        ? 'Erro! plantão não adicionado, há um conflito de horários'
        : error.response.data.message,
    );

    return await Promise.reject(error.response.data.message);
  },
);

export default api;
