import api from '..';
import { type IAdmin } from '../../models/IAdmin';

interface IListAdminsResponse {
  admins: IAdmin[];
  count: number;
}

interface ICreateAdminRequest {
  email: string;
  name: string;
  hospitals: string[];
}

interface IUpdateAdminRequest {
  name?: string;
  hospitals?: string[];
  isActive?: boolean;
}

export class AdminService {
  static async listAdmins(): Promise<IListAdminsResponse> {
    const { data } = await api.get<IListAdminsResponse>(`/admins`);
    return data;
  }

  static async createAdmin(adminDTO: ICreateAdminRequest): Promise<IAdmin> {
    const { data } = await api.post<IAdmin>(`/admins`, adminDTO);
    return data;
  }

  static async updateAdmin(
    adminId: string,
    adminDTO: IUpdateAdminRequest,
  ): Promise<IAdmin> {
    const { data } = await api.put<IAdmin>(`/admins/${adminId}`, adminDTO);
    return data;
  }
}
