import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const Modal = styled.div`
  max-width: 39rem;
  height: fit-content;
  background-color: white;
  padding: 2.5rem 4rem;
  border-radius: 0.5rem;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c8c8c8;
  }
`;

export const AlertRow = styled.div`
  &:not(:last-child) {
    border-bottom: 0.5px solid #e0e0e0;
    padding-bottom: 1rem;
  }
`;

export const IconWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(255, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Title = styled.b`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  display: block;
  margin-top: 0.5rem;
`;

export const Description = styled.p`
  color: var(--gray700);
`;

export const ActionArea = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: end;

  button {
    width: fit-content;
  }
`;
