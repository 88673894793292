function formatDate(dateToFormat: string | undefined) {
  if (!dateToFormat) {
    return '00/00/0000 00:00';
  }
  const date = new Date(dateToFormat);

  return `${date.getDate()} de ${date.toLocaleString('default', { month: 'long' })}, ${date.getFullYear()}`;
}

function formatDate2(dateToFormat: string | undefined) {
  if (!dateToFormat) {
    return '00/00/0000 00:00';
  }
  const date = new Date(dateToFormat);

  return `${date.getDate()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
}

function formatHour(hourToFormat: string | undefined) {
  if (!hourToFormat) return '--h';
  if (hourToFormat.length <= 2) return ('0' + hourToFormat).slice(-2) + ':00';
  else return hourToFormat;
}
export { formatDate, formatDate2, formatHour };
