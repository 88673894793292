import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { ShiftService } from '../../../api/shifts';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import { useAuth } from '../../../context/AuthContext';
import { useLoading } from '../../../context/GlobalLoading';
import './style.css';
import 'dayjs/locale/pt-br';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

interface publishShiftsProps {
  isVisible: boolean;
  setIsVisible: (x: boolean) => void;
  month: number;
  year: number;
  fetchShifts: () => void;
}

function PublishShifts({
  isVisible,
  setIsVisible,
  month,
  year,
  fetchShifts,
}: publishShiftsProps) {
  const [confirmation, setConfirmation] = useState(false);
  const [start, setStart] = useState(dayjs(new Date(year, month, 1)));
  const [end, setEnd] = useState(dayjs(new Date(year, month + 1, 0)));
  const { selectedMedicalGroup } = useAuth();
  const { setIsGlobalLoading } = useLoading();

  const handlePublish = async () => {
    setIsGlobalLoading(true);

    if (start > end) {
      setIsGlobalLoading(false);
      return;
    }

    await ShiftService.publish({
      startDate: start.toDate(),
      endDate: end.toDate(),
      medicalGroupGuid: selectedMedicalGroup?.guid,
      timezoneOffset: new Date().getTimezoneOffset() / 60,
    })
      .then(() => {
        fetchShifts();
        setIsGlobalLoading(false);
        setIsVisible(false);
      })
      .catch(() => {
        setIsGlobalLoading(false);
      });

    toast.success('Escala publicada com sucesso.');
  };

  useEffect(() => {
    setConfirmation(false);
  }, [isVisible]);

  return (
    <>
      {!confirmation && (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} size={'md'}>
          <h3 className="publish-h3">
            Selecione o período da sua escala modelo para publicar
          </h3>
          <div className="row publish-row">
            <span className="publish-span">de</span>
            <DatePicker
              onChange={(newValue) => {
                setStart(newValue ?? dayjs(''));
              }}
              value={start}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  sx={{ width: '10rem' }}
                  error={
                    start > end ||
                    dayjs(start).get('month') !== dayjs(end).get('month')
                  }
                />
              )}
            />
            <span className="publish-span">até</span>
            <DatePicker
              onChange={(newValue) => {
                setEnd(newValue ?? dayjs(''));
              }}
              value={end}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  sx={{ width: '10rem' }}
                  error={
                    start > end ||
                    dayjs(start).get('month') !== dayjs(end).get('month')
                  }
                />
              )}
            />
          </div>
          <div className="row publish-buttons">
            <Button
              disabled={false}
              color={'destructive'}
              onClick={() => {
                setIsVisible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                start > end ||
                !start.toDate().getTime() ||
                !end.toDate().getTime() ||
                dayjs(start).get('month') !== dayjs(end).get('month')
              }
              color={'primary'}
              onClick={() => {
                setConfirmation(true);
              }}
            >
              Próximo
            </Button>
          </div>
        </Modal>
      )}
      {confirmation && (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} size={'md'}>
          <h3 className="publish-h3">Atenção</h3>
          <div className="row publish-row">
            <p className="publish-p">
              Você está publicando uma escala do dia{' '}
              <b className="publish-highlight">
                {start.toDate().toLocaleDateString('pt-BR')} até{' '}
                {end.toDate().toLocaleDateString('pt-BR')}.
              </b>
              <br />
              <br />
              <br />
              <br />
              Todos os plantões sem responsável serão anunciados
              automaticamente.
            </p>
          </div>
          <div className="row publish-buttons">
            <Button
              disabled={false}
              color={'destructive'}
              onClick={() => {
                setIsVisible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={false}
              color={'primary'}
              onClick={async () => {
                await handlePublish();
              }}
            >
              Próximo
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default PublishShifts;
