/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import './style.css';
import logo from '../../assets/Agendoctor.svg';
import InputMask from 'react-input-mask';
import { ufsList } from '../../utils/ufs';

import calendarIconWhite from '../../assets/calendarWhite.svg';
import groupIconWhite from '../../assets/groupWhite.svg';
import notificationIconWhite from '../../assets/notificationWhite.svg';
import userIconWhite from '../../assets/userWhite.svg';
import swapIconWhite from '../../assets/whiteSwap.svg';
import licensesWhite from '../../assets/licensesWhite.svg';

import calendarIconGray from '../../assets/calendarGray.svg';
import groupIconGray from '../../assets/groupGray.svg';
import hospitalIcon from '../../assets/hospital.svg';
import notificationIconGray from '../../assets/notificationGray.svg';
import userIconGray from '../../assets/userGray.svg';
import arrowIcon from '../../assets/grayArrow.svg';
import swapIconGray from '../../assets/graySwap.svg';
import licensesGray from '../../assets/licensesGray.svg';

import calendarIconBlue from '../../assets/calendarBlue.svg';
import groupIconBlue from '../../assets/groupBlue.svg';
import notificationIconBlue from '../../assets/notificationBlue.svg';
import userIconBlue from '../../assets/userBlue.svg';
import swapBlueIcon from '../../assets/blueSwap.svg';

import { NavLink } from 'react-router-dom';
import React, {
  type ReactElement,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
// import { AuthService } from '../../api/auth';
import { useAuth } from '../../context/AuthContext';
import { Grid, MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import Modal from '../modal';
import Form from '../form';
import Button from '../button';
import { UserService } from '../../api/user';
import { toast } from 'react-toastify';
import { CepService } from '../../api/cep';
import StickyButton from '../stickyButton';
import { useLanguage } from '../../context/LanguageContext';
import text from './text';
import useNewPassword from './hooks/useNewPassword';
import { FormProvider } from 'react-hook-form';
import TextField from '../TextField';
import {
  NewPasswordFields,
  type NewPasswordFormValues,
} from './forms/newPasswordForm';
import { NewPasswordActionsBox } from './styles';
import dayjs from '../../utils/dayjs';

interface MainLayoutProps {
  children: ReactNode | ReactElement;
}

interface menuOption {
  name: string;
  path: string;
  gray: string;
  blue: string;
  white: string;
  text: string;
  isActive: boolean;
  isHover: boolean;
  showTo: string[];
}

export interface addFields {
  guid?: string | null;
  name?: string | null;
  email?: string | null;
  phone1?: string | null;
  phone2?: string | null;
  profession?: string | null;
  crm?: string | null;
  ufCrm?: string | null;
  // adress
  cep?: string | null;
  street?: string | null;
  number?: string | null;
  district?: string | null;
  complement?: string | null;
  uf?: string | null;
  city?: string | null;
  // details
  admissionDate?: string | null;
  code?: string | null;
  details?: string | null;
}

export interface addAdminFields {
  guid?: string;
  name?: string;
  email?: string;
}

interface ChangePassword {
  actualPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

function MainLayout({ children }: MainLayoutProps) {
  const auth = useAuth();
  const language = useLanguage();

  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [professionalData, setProfessionalData] = useState<
    addFields | undefined
  >(undefined);
  const [adminData, setAdminData] = useState<addAdminFields | undefined>(
    undefined,
  );
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [isUfOpen, setIsUfOpen] = useState(false);
  const [isUfCrmOpen, setIsUfCrmOpen] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isAddGroupMsgVisible, setIsAddGroupMsgVisible] = useState(false);
  const [isSelectLanguageVisible, setIsSelectLanguageVisible] = useState(false); //! localStorage.getItem('language'));
  const { methods, onSubmit } = useNewPassword();

  useEffect(() => {
    setAdminData(
      auth.user && 'isSuperAdmin' in auth.user ? auth.user : undefined,
    );
    setProfessionalData(
      auth.user && !('isSuperAdmin' in auth.user)
        ? { ...auth.user, admissionDate: auth.user.admissionDate?.toString() }
        : undefined,
    );
    const showPasswordIfGenerates = async () => {
      let user = auth.user;
      if (!user) {
        user = await auth.getCurrentUser();
      }
      if (user && user.generatedPassword) {
        setIsChangePasswordVisible(true);
        setIsNewPassword(true);
      }
    };
    showPasswordIfGenerates();
  }, []);

  useEffect(() => {
    setAdminData(
      auth.user && 'isSuperAdmin' in auth.user ? auth.user : undefined,
    );
    setProfessionalData(
      auth.user && !('isSuperAdmin' in auth.user)
        ? { ...auth.user, admissionDate: auth.user.admissionDate?.toString() }
        : undefined,
    );
  }, [auth.user]);
  const handleLogOut = () => {
    auth.logout();
  };

  const handleSubmit = async () => {
    if (isAdmin && adminData?.name) {
      await UserService.updateAdmin(adminData?.name);
    } else if (!isAdmin && professionalData) {
      await UserService.updateUser(professionalData);
    }

    toast.success(text.profileUpdate[language.language]);
    auth.getCurrentUser();
  };
  useEffect(() => {
    if (!auth.selectedMedicalGroup) {
      auth.fetchHospitals();
    }

    if (
      !auth.medicalGroups ||
      (!(auth.medicalGroups && auth.medicalGroups.length > 0) &&
        auth.isAdmin &&
        !auth.isSuperAdmin)
    ) {
      window.location.replace('/groups');
    }
  }, []);

  useEffect(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (auth.medicalGroups && auth.medicalGroups?.length > 0) ||
      auth.isSuperAdmin
    ) {
      setIsAddGroupMsgVisible(false);
    } else if (auth.isAdmin) {
      setIsAddGroupMsgVisible(true);
    }
  }, [auth.medicalGroups]);
  useEffect(() => {
    async function searchCep() {
      if (
        professionalData?.cep &&
        professionalData.cep.length === 9 &&
        !professionalData.cep.includes('_')
      ) {
        const cepResult = await CepService.search(professionalData.cep);
        setProfessionalData({
          ...professionalData,
          street: cepResult.logradouro,
          district: cepResult.bairro,
          uf: cepResult.uf,
          city: cepResult.localidade,
        });
      }
    }
    searchCep();
  }, [professionalData?.cep]);

  useEffect(() => {
    setIsAdmin((auth.user && 'isSuperAdmin' in auth.user) ?? false);
    setIsSaveEnabled(validateFormIsComplete());
  }, [auth.user, adminData]);

  const handleAdminChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  const handleProfessionalChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProfessionalData({
      ...professionalData,
      [e.target.name]: e.target.value,
    });
  };

  const handleProfessionalChangeSelect = (e: SelectChangeEvent) => {
    setProfessionalData({
      ...professionalData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormIsComplete = () => {
    if (
      !isAdmin &&
      professionalData?.name &&
      professionalData.email &&
      professionalData.phone1 &&
      professionalData.crm
    ) {
      return true;
    }

    if (isAdmin && adminData?.name) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setIsSaveEnabled(validateFormIsComplete());
  }, [professionalData, adminData]);

  const handlePasswordSubmit = async (data: NewPasswordFormValues) => {
    try {
      await onSubmit({
        ...data,
        isAdmin: auth.isSuperAdmin || auth.isAdmin,
      });
      setIsChangePasswordVisible(false);
      toast.success(text.passwordUpdate[language.language]);
    } catch (err) {
      toast.error('Erro ao alterar senha');
    }
  };

  const menu = [
    {
      name: 'shifts',
      path: '/shifts',
      gray: calendarIconGray,
      blue: calendarIconBlue,
      white: calendarIconWhite,
      text: text.menuNames.shifts[language.language],
      isActive: false,
      isHover: false,
      showTo: ['admin', 'manager'],
    },
    {
      name: 'professionals',
      path: '/professionals',
      gray: userIconGray,
      blue: userIconBlue,
      white: userIconWhite,
      text: text.menuNames.professionals[language.language],
      isActive: false,
      isHover: false,
      showTo: ['admin', 'manager'],
    },
    {
      name: 'notifications',
      path: '/notifications',
      gray: notificationIconGray,
      blue: notificationIconBlue,
      white: notificationIconWhite,
      text: text.menuNames.notifications[language.language],
      isActive: false,
      isHover: false,
      showTo: ['admin', 'manager'],
    },
    {
      name: 'turnos',
      path: '/turnos',
      gray: swapIconGray,
      blue: swapBlueIcon,
      white: swapIconWhite,
      text: text.menuNames.turnos[language.language],
      isActive: false,
      isHover: false,
      showTo: ['admin', 'manager'],
    },
    {
      name: 'groups',
      path: '/groups',
      gray: groupIconGray,
      blue: groupIconBlue,
      white: groupIconWhite,
      text: text.menuNames.groups[language.language],
      isActive: false,
      isHover: false,
      showTo: ['admin'],
    },
    {
      name: 'hospitals',
      path: '/hospitals',
      gray: licensesGray,
      blue: groupIconBlue,
      white: licensesWhite,
      text: text.menuNames.hospitals[language.language],
      isActive: false,
      isHover: false,
      showTo: ['super'],
    },
    {
      name: 'admins',
      path: '/admins',
      gray: userIconGray,
      blue: userIconBlue,
      white: userIconWhite,
      text: text.menuNames.admins[language.language],
      isActive: false,
      isHover: false,
      showTo: ['super'],
    },
  ];

  menu.map((item) => {
    if (location.pathname.includes(item.path)) return (item.isActive = true);
    return null;
  });

  location.pathname.includes('/example-details');
  const [menuState, setMenu] = useState<menuOption[]>(menu);

  return (
    <div className="main-layout">
      <div className="sidebar">
        <img src={logo} key="logo" alt="" className="logo" />
        <ul>
          {menuState &&
            menuState.length > 0 &&
            menuState
              .filter((item) => {
                if (auth.isAdmin && !auth.isSuperAdmin) {
                  return item.showTo.includes('admin');
                } else if (auth.isSuperAdmin) {
                  return item.showTo.includes('super');
                } else {
                  return item.showTo.includes('manager');
                }
              })
              .map((item, key) => {
                return (
                  <>
                    {item.name === 'turnos' && (
                      <div key={item.name} className="turnos-division"></div>
                    )}
                    <SideMenuItem
                      item={item}
                      key={item.name}
                      isActive={item.isActive}
                      isHover={item.isHover}
                      onClick={() => {
                        const tempMenu = menuState;
                        tempMenu.forEach((a) => (a.isActive = false));
                        tempMenu[key].isActive = true;
                        setMenu(
                          (tempMenuInsideState) =>
                            (tempMenuInsideState = tempMenu),
                        );
                      }}
                      onMouseOver={() => {
                        const tempMenu = [...menuState];
                        tempMenu[key].isHover = true;
                        setMenu(tempMenu);
                      }}
                      onMouseOut={() => {
                        const tempMenu = [...menuState];
                        tempMenu[key].isHover = false;
                        setMenu(tempMenu);
                      }}
                    />
                  </>
                );
              })}
        </ul>
      </div>

      <div className="topmenu">
        {!auth.isSuperAdmin && auth.hospitals && auth.hospitals.length > 0 && (
          <div className="medical-group" style={{ marginRight: '10px' }}>
            <div>
              <span
                className={`${
                  auth.hospitals && auth.hospitals.length > 1
                    ? 'selectable'
                    : ''
                } selected-group`}
              >
                <img
                  src={hospitalIcon}
                  alt=""
                  style={{ marginRight: '1rem' }}
                />
                {auth.selectedHospital?.name}
                {auth.hospitals && auth.hospitals.length > 1 && (
                  <img src={arrowIcon} alt="" style={{ marginLeft: '1rem' }} />
                )}
              </span>
              {auth.hospitals && auth.hospitals.length > 1 && (
                <div className="medicalgroup-submenu">
                  {auth.hospitals?.map((hospital) => (
                    <span
                      onClick={() => {
                        auth.setSelectedHospital(null);
                        auth.setSelectedHospital(hospital);
                      }}
                      key={hospital.guid}
                    >
                      {hospital.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {!auth.isSuperAdmin &&
          auth.medicalGroups &&
          auth.medicalGroups.length > 0 && (
            <div className="medical-group">
              <div>
                <span
                  className={`${
                    auth.medicalGroups && auth.medicalGroups.length > 1
                      ? 'selectable'
                      : ''
                  } selected-group`}
                >
                  <img
                    src={groupIconGray}
                    alt=""
                    style={{ marginRight: '1rem' }}
                  />
                  {auth.selectedMedicalGroup?.name}
                  {auth.medicalGroups && auth.medicalGroups.length > 1 && (
                    <img
                      src={arrowIcon}
                      alt=""
                      style={{ marginLeft: '1rem' }}
                    />
                  )}
                </span>
                {auth.medicalGroups && auth.medicalGroups.length > 1 && (
                  <div className="medicalgroup-submenu">
                    {auth.medicalGroups?.map((medicalGroup, i) => (
                      <span
                        key={i}
                        onClick={() => {
                          auth.setSelectedMedicalGroup(null);
                          auth.setSelectedMedicalGroup(medicalGroup);
                        }}
                      >
                        {medicalGroup.name}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        <div className="user">
          <div className="info">
            <b>{auth.user?.name}</b>
            <br />
            {auth.isSuperAdmin
              ? 'Super administrador'
              : auth.isAdmin
                ? 'Admin'
                : 'Gestor'}
          </div>
          <div className="user-image">
            <b>{auth.user?.name.slice(0, 1)}</b>
          </div>
          <div className="user-submenu">
            <span
              onClick={() => {
                setIsProfileVisible(true);
              }}
            >
              {text.myProfile[language.language]}
            </span>
            <span
              className="log-out"
              onClick={() => {
                handleLogOut();
              }}
            >
              {text.logOut[language.language]}
            </span>
          </div>
        </div>
      </div>

      <div id="pages">
        {isAddGroupMsgVisible && (
          <div className="group-alert">
            {text.groupAlert[language.language]}{' '}
            <NavLink
              to={'/groups'}
              onClick={() => {
                const tempMenu = menuState;
                tempMenu.forEach((a) => (a.isActive = false));
                tempMenu[4].isActive = true;
                setMenu(
                  (tempMenuInsideState) => (tempMenuInsideState = tempMenu),
                );
              }}
            >
              {' '}
              {text.clickHere[language.language]}
            </NavLink>
            .
          </div>
        )}
        {children}
        <StickyButton />
        <Modal
          isVisible={isProfileVisible}
          setIsVisible={setIsProfileVisible}
          onClose={() => {
            setAdminData({
              name: auth.user?.name,
            });
          }}
          imageHeader
        >
          {!isAdmin ? (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={5}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={6}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.name[language.language]} <b>*</b>
                    </label>
                    <InputMask
                      value={professionalData?.name ?? undefined}
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="name"
                      id="name"
                      mask={''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.email[language.language]} <b>*</b>
                    </label>
                    <InputMask
                      disabled
                      value={professionalData?.email ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="email"
                      name="email"
                      id="email"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <span
                    className="change-password"
                    onClick={() => {
                      setIsChangePasswordVisible(true);
                    }}
                  >
                    Alterar senha
                  </span>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={4}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.phone[language.language]}(1) <b>*</b>
                    </label>
                    <InputMask
                      value={professionalData?.phone1 ?? undefined}
                      mask="(99) 99999-9999"
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="tel"
                      name="phone1"
                      id="phone1"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.phone[language.language]}(2)
                    </label>
                    <InputMask
                      value={professionalData?.phone2 ?? undefined}
                      mask="(99) 99999-9999"
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="tel"
                      name="phone2"
                      id="phone2"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.profession[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.profession ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="profession"
                      id="profession"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="styled-input-container">
                    <label htmlFor="crm">
                      {text.form.crm[language.language]} <b>*</b>
                    </label>
                    <InputMask
                      value={professionalData?.crm ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="crm"
                      id="crm"
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="styled-input-container">
                    <label htmlFor="ufCrm">
                      {text.form.ufCrm[language.language]}
                    </label>
                    <Select
                      value={professionalData?.ufCrm ?? ''}
                      onChange={handleProfessionalChangeSelect}
                      size="small"
                      name="ufCrm"
                      id="ufCrm"
                      open={isUfCrmOpen}
                      onOpen={() => {
                        setIsUfCrmOpen(true);
                      }}
                      onClose={() => {
                        setIsUfCrmOpen(false);
                      }}
                      IconComponent={() => (
                        <svg
                          onClick={() => {
                            setIsUfCrmOpen(true);
                          }}
                          className="select-styled-icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={
                            isUfCrmOpen ? { transform: 'rotate(180deg)' } : {}
                          }
                        >
                          <path
                            d="M18.7098 8.20998C18.6169 8.11625 18.5063 8.04186 18.3844 7.99109C18.2625 7.94032 18.1318 7.91418 17.9998 7.91418C17.8678 7.91418 17.7371 7.94032 17.6152 7.99109C17.4934 8.04186 17.3828 8.11625 17.2898 8.20998L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.20998C6.61685 8.11625 6.50625 8.04186 6.38439 7.99109C6.26253 7.94032 6.13183 7.91418 5.99982 7.91418C5.8678 7.91418 5.7371 7.94032 5.61524 7.99109C5.49338 8.04186 5.38278 8.11625 5.28982 8.20998C5.10356 8.39734 4.99902 8.6508 4.99902 8.91498C4.99902 9.17917 5.10356 9.43262 5.28982 9.61998L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.61998C18.8961 9.43262 19.0006 9.17917 19.0006 8.91498C19.0006 8.6508 18.8961 8.39734 18.7098 8.20998Z"
                            fill="#9FABAF"
                          />
                        </svg>
                      )}
                    >
                      {ufsList?.map(
                        (
                          uf: {
                            value: string | undefined;
                            label: string | undefined;
                          },
                          i,
                        ) => (
                          <MenuItem key={i} value={uf.value}>
                            {uf.label}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </div>
                </Grid>
              </Grid>

              <h2>{text.form.adress[language.language]}</h2>
              <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid item xs={2.5}>
                  <div className="styled-input-container">
                    <label htmlFor="cep">
                      {text.form.cep[language.language]}{' '}
                    </label>
                    <InputMask
                      value={professionalData?.cep ?? undefined}
                      mask="99999-999"
                      disabled={false}
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="tel"
                      name="cep"
                      id="cep"
                    />
                  </div>
                </Grid>
                <Grid item xs={5.5}>
                  <div className="styled-input-container">
                    <label htmlFor="street">
                      {text.form.street[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.street ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="street"
                      id="street"
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="styled-input-container">
                    <label htmlFor="number">
                      {text.form.number[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.number ?? undefined}
                      mask=""
                      disabled={false}
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="number"
                      id="number"
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="styled-input-container">
                    <label htmlFor="district">
                      {text.form.district[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.district ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="district"
                      id="district"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="styled-input-container">
                    <label htmlFor="complement">
                      {text.form.complement[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.complement ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="complement"
                      id="complement"
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="styled-input-container">
                    <label htmlFor="uf">
                      {text.form.uf[language.language]}
                    </label>
                    <Select
                      value={professionalData?.uf ?? ''}
                      onChange={handleProfessionalChangeSelect}
                      size="small"
                      name="uf"
                      id="uf"
                      open={isUfOpen}
                      onOpen={() => {
                        setIsUfOpen(true);
                      }}
                      onClose={() => {
                        setIsUfOpen(false);
                      }}
                      IconComponent={() => (
                        <svg
                          onClick={() => {
                            setIsUfOpen(true);
                          }}
                          className="select-styled-icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={
                            isUfOpen ? { transform: 'rotate(180deg)' } : {}
                          }
                        >
                          <path
                            d="M18.7098 8.20998C18.6169 8.11625 18.5063 8.04186 18.3844 7.99109C18.2625 7.94032 18.1318 7.91418 17.9998 7.91418C17.8678 7.91418 17.7371 7.94032 17.6152 7.99109C17.4934 8.04186 17.3828 8.11625 17.2898 8.20998L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.20998C6.61685 8.11625 6.50625 8.04186 6.38439 7.99109C6.26253 7.94032 6.13183 7.91418 5.99982 7.91418C5.8678 7.91418 5.7371 7.94032 5.61524 7.99109C5.49338 8.04186 5.38278 8.11625 5.28982 8.20998C5.10356 8.39734 4.99902 8.6508 4.99902 8.91498C4.99902 9.17917 5.10356 9.43262 5.28982 9.61998L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.61998C18.8961 9.43262 19.0006 9.17917 19.0006 8.91498C19.0006 8.6508 18.8961 8.39734 18.7098 8.20998Z"
                            fill="#9FABAF"
                          />
                        </svg>
                      )}
                    >
                      {ufsList?.map(
                        (
                          uf: {
                            value: string | undefined;
                            label: string | undefined;
                          },
                          i,
                        ) => (
                          <MenuItem key={i} value={uf.value}>
                            {uf.label}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="styled-input-container">
                    <label htmlFor="city">
                      {text.form.city[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.city ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="city"
                      id="city"
                    />
                  </div>
                </Grid>
              </Grid>
              <h2>{text.form.details[language.language]}</h2>
              <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid item xs={3}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.admissionDate[language.language]}
                    </label>
                    <input
                      value={
                        professionalData?.admissionDate
                          ? dayjs(professionalData.admissionDate)
                              .add(3, 'hour')
                              .format('YYYY-MM-DD')
                          : undefined
                      }
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="date"
                      name="admissionDate"
                      id="admissionDate"
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="styled-input-container">
                    <label htmlFor="code">
                      {text.form.code[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.code ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="code"
                      id="code"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="styled-input-container">
                    <label htmlFor="details">
                      {text.form.details[language.language]}
                    </label>
                    <InputMask
                      value={professionalData?.details ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleProfessionalChangeInput(e);
                      }}
                      type="text"
                      name="details"
                      id="details"
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          ) : (
            <Form>
              <h2
                style={{
                  marginTop: '20px',
                }}
              >
                Dados pessoais
              </h2>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={5}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={6}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.name[language.language]} <b>*</b>
                    </label>
                    <InputMask
                      value={adminData?.name ?? undefined}
                      onChange={(e) => {
                        handleAdminChangeInput(e);
                      }}
                      type="text"
                      name="name"
                      id="name"
                      mask={''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="styled-input-container">
                    <label htmlFor="name">
                      {text.form.email[language.language]} <b>*</b>
                    </label>
                    <InputMask
                      disabled
                      value={adminData?.email ?? undefined}
                      mask=""
                      onChange={(e) => {
                        handleAdminChangeInput(e);
                      }}
                      type="email"
                      name="email"
                      id="email"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <span
                    className="change-password"
                    onClick={() => {
                      setIsChangePasswordVisible(true);
                    }}
                  >
                    Alterar senha
                  </span>
                </Grid>
              </Grid>
            </Form>
          )}
          <div
            style={{
              width: '5rem',
              float: 'right',
              marginTop: '1rem',
              paddingBottom: '2rem',
            }}
          >
            <Button
              disabled={!isSaveEnabled}
              onClick={async () => {
                await handleSubmit();
              }}
            >
              {text.form.save[language.language]}
            </Button>
          </div>
        </Modal>

        <Modal
          setIsVisible={setIsChangePasswordVisible}
          isVisible={isChangePasswordVisible}
          size="xxsm"
        >
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handlePasswordSubmit)}>
              <h2 style={{ marginTop: '0rem' }}>
                {text.form.changePassword[language.language]}
              </h2>
              {isNewPassword && (
                <div className="new-password">
                  {text.form.createNewPassword[language.language]}!
                </div>
              )}
              <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="Senha atual"
                    required
                    name={NewPasswordFields.OldPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="Nova senha"
                    required
                    name={NewPasswordFields.NewPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="Confirmar nova senha"
                    required
                    name={NewPasswordFields.ConfirmPassword}
                  />
                </Grid>
              </Grid>
              <NewPasswordActionsBox>
                <Button
                  disabled={false}
                  onClick={() => {
                    setIsChangePasswordVisible(false);
                  }}
                  color="destructive"
                >
                  Cancelar
                </Button>
                <Button type="submit">
                  {text.form.save[language.language]}
                </Button>
              </NewPasswordActionsBox>
            </Form>
          </FormProvider>
        </Modal>

        <Modal
          isVisible={isSelectLanguageVisible}
          setIsVisible={setIsSelectLanguageVisible}
        >
          <h2>{text.selectYourLanguage.title[language.language]}</h2>
          <div className="buttons-row">
            <Button
              disabled={false}
              onClick={() => {
                language.setLanguage('en-Us');
              }}
              color="destructive"
            >
              {text.selectYourLanguage.english[language.language]}
            </Button>
            <Button
              disabled={false}
              onClick={() => {
                language.setLanguage('pt-Br');
              }}
            >
              {text.selectYourLanguage.portuguese[language.language]}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default MainLayout;

interface MenuItemProps {
  item: menuOption;
  isActive: boolean;
  isHover: boolean;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

const SideMenuItem: React.FC<MenuItemProps> = ({
  item,
  isActive,
  isHover,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <NavLink to={item.path}>
      <li
        className="menu-item"
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <a href={item.path}>
          <img
            src={isActive ? item.white : isHover ? item.blue : item.gray}
            alt=""
          />
          <span>{item.text}</span>
        </a>
      </li>
    </NavLink>
  );
};
