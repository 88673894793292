import { useEffect, useState } from 'react';
import { NotificationService } from '../../api/notification';
import Button from '../../components/button';
import Container from '../../components/container';
import Notification from '../../components/notification';
import { type INotification } from '../../models/INotification';
import { formatDate } from '../../utils/formatDate';
import './style.css';
import { useAuth } from '../../context/AuthContext';

function Notifications() {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { selectedHospital } = useAuth();

  let lastDate = formatDate(undefined);

  const fetchNotifications = async (page: number) => {
    if (!selectedHospital) return;
    const ret = await NotificationService.getAll({
      page,
      hospitalGuid: selectedHospital.guid,
    });
    if (ret.result !== notifications) {
      const ns = [...notifications, ...ret.result];
      setNotifications(ns);
      if (ret.totalPages !== totalPages) setTotalPages(ret.totalPages);
    }
  };

  useEffect(() => {
    fetchNotifications(0);
  }, []);

  return (
    <Container>
      <div className="header">
        <span>Notificações</span>
      </div>
      {notifications.map((n, i) => {
        const diff = formatDate(n.sendDate) !== lastDate;
        lastDate = formatDate(n.sendDate);
        return (
          <>
            {diff ? (
              <div className="notification-day">
                <span>{lastDate}</span>
              </div>
            ) : (
              <></>
            )}
            <Notification
              title={n.title}
              message={n.message}
              type={n.type}
              sendDate={n.sendDate}
              key={i}
            />
          </>
        );
      })}
      {!(notifications.length > 0) && (
        <div className="default-message">
          <h2>Você não possui notificações.</h2>
        </div>
      )}
      {currentPage < totalPages - 1 && (
        <div
          className="load-more"
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: '2rem',
          }}
        >
          <div style={{ width: '10rem' }}>
            <Button
              disabled={false}
              onClick={() => {
                if (currentPage < totalPages - 1) {
                  fetchNotifications(currentPage + 1);
                  setCurrentPage(currentPage + 1);
                }
              }}
            >
              Carregar mais
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Notifications;
