import { z } from 'zod';

export enum NewPasswordFields {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
  ConfirmPassword = 'confirmPassword',
}

export const newPasswordInitialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const newPasswordValidationSchema = z
  .object({
    oldPassword: z
      .string({
        required_error: 'Obrigatório',
      })
      .min(1, 'Obrigatório')
      .refine((s) => !s.includes(' '), 'Espaços não são permitidos'),
    newPassword: z
      .string({
        required_error: 'Obrigatório',
      })
      .min(6, 'Mínimo de 6 caracteres')
      .max(255, 'Máximo de 255 caracteres')
      .refine((s) => !s.includes(' '), 'Espaços não são permitidos'),
    confirmPassword: z
      .string({
        required_error: 'Obrigatório',
      })
      .min(6, 'Mínimo de 6 caracteres')
      .max(255, 'Máximo de 255 caracteres')
      .refine((s) => !s.includes(' '), 'Espaços não são permitidos'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'As senhas não coincidem',
    path: ['confirmPassword'],
  });

export type NewPasswordFormValues = z.infer<typeof newPasswordValidationSchema>;
